const TIPOS_PARTICIPACOES = [
    {
        id: 1,
        sigla: 'MAIS MÉDICOS',
        acronimo: 'MM'
    },
    {
        id: 2,
        sigla: 'PROVAB',
        acronimo: 'P'

    },
    {
        id: 3,
        sigla: 'RESIDÊNCIA',
        acronimo: 'R'  
    }
];

export default TIPOS_PARTICIPACOES;