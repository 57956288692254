const CADASTRO_FUNCIONARIO = {
    REDUCER_TYPE: {
        SETAR_PARAMETROS_PESQUISA_INSTITUICAO: 'SETAR_PARAMETROS_PESQUISA_INSTITUICAO',
        LIMPAR_PARAMETROS_PESQUISA_INSTITUICAO: 'LIMPAR_PARAMETROS_PESQUISA_INSTITUICAO',
        PESQUISAR_INSTITUICAO: 'PESQUISAR_INSTITUICAO',
        SETAR_ID_FUNCIONARIO_ALTERACAO: 'SETAR_ID_FUNCIONARIO_ALTERACAO',
        SETAR_INSTITUICOES_ADMIN_LOCAL: 'SETAR_INSTITUICOES_ADMIN_LOCAL'
    }
};

export default CADASTRO_FUNCIONARIO;