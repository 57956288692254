import ConstantesPesquisaInstituicao from '@constantes/pesquisa/instituicao';

const { REDUCER_TYPE } = ConstantesPesquisaInstituicao

const INITIAL_STATE = {
    parametrosPesquisa: {
        cnes: '',
        nome: '',
        uf: null,
        cidade: null,
        consultorioVirtual: null
    }
};

function pesquisaInstituicaoReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if (type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }
    return state;
};

export default pesquisaInstituicaoReducer;