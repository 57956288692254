import TYPES from '@redux/types/cadastro/paciente/index';

export const setPacienteAlteracao = (paciente) => {
    return {
        type: TYPES.SETAR_DADOS_PACIENTE_ALTERACAO,
        payload: paciente
    };
}
export const setExames = (exames) => {
    return {
        type: TYPES.SET_EXAMES,
        payload: exames
    };
}