import TYPES from '@redux/types/cadastro/pacs/index';

const INITIAL_STATE = {
    pacsAlteracao: null,
};

const cadastroPacsReducer = (state, action) => {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === TYPES.SETAR_DADOS_PACS_ALTERACAO) {
        return {
            ...state,
            pacsAlteracao: payload,
        };
    }

    return state;
};

export default cadastroPacsReducer;
