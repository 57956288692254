import * as yup from 'yup';
import validarCpf from 'validar-cpf';
import {
    CPF,
    DATA_INICIAL,
    DATA_FINAL
} from './fieldNames';

const hoje = new Date();
hoje.setHours(23, 59, 0, 0);

// eslint-disable-next-line import/no-anonymous-default-export
export default (strings) => {
    return yup.object().shape({
        [CPF]: yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validarCpf(cpf);
            }),
        [DATA_INICIAL]: yup
            .date()
            .typeError(strings.dataInvalida)
            .max(hoje, strings.erroDataFutura)
            .nullable(),
        [DATA_FINAL]: yup
            .date()
            .typeError(strings.dataInvalida)
            .min(yup.ref(DATA_INICIAL), strings.dataFinalAnteriorDataInicial)
            .max(new Date(), strings.erroDataFutura)
            .nullable(),
    });
}
