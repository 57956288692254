import TYPES from '@redux/types/cadastro/triagem';

export const setParametrosPesquisaTriagem = (parametros) => {
    return {
        type: TYPES.SETAR_PARAMETROS_PESQUISA_TRIAGEM,
        payload: { parametros }
    };
}

export const limparParametrosPesquisaTriagem = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA_TRIAGEM
    };
}