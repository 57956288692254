import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
    SttModal,
    SttText,
    SttButton,
    SttLoading,
    SttAlerta
} from '@stt-componentes/core';
import translate from "@componentes/translate";
import { Util } from '@stt-utilitarios/core';
import axios from 'axios';
import { useQuery } from '../../../../hooks';
import { Cache } from '@stt-componentes/cache';

const TermoConsentimento = ({ strings, cpf, nome, layout, accessToken }) => {
    const [termos, setTermos] = useState([
        {
            concordou: false,
            generico: true,
            termo: strings.textoTermo(Util.util.adicionarMascara(cpf, '000.000.000-00'), nome)
        }
    ]);
    const [termoAtual, setTermoAtual] = useState(0);
    const [salvando, setSalvando] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const query = useQuery();
    const protocolo = query.get('protocolo') || null;
    const cpfPaciente = query.get('cpf') || null;
    const cnes = query.get('teleatendimento_cnes') || null;

    useEffect(() => {
        // Caso haver algum outro termo para adição, adicionar aqui
    }, [layout]);

    useEffect(() => {
        if (cnes) {
            setTermos(t => [...t, {
                concordou: false,
                generico: false,
                termo: strings.textoTermoTeleatendimento(Util.util.adicionarMascara(cpf, '000.000.000-00'), nome)
            }]);
        }
    }, [cnes])

    const proximoTermo = () => {
        if (termoAtual === (termos.length - 1)) {
            if (cnes) {
                Cache.setAccessToken(accessToken);
                // Redirecionar para o teleatendimento
                window.location.href = `${global.gConfig.url_redirect_teleatendimento}/atendimento/${cnes}&token=${accessToken}`;
            } else if (protocolo && cpfPaciente) {
                // Redirecionar para o meus-exames
                window.location.href = `${global.gConfig.url_redirect_meus_exames}?protocolo=${protocolo}&cpf=${cpf}&token=${accessToken}`;
            } else {
                window.location.href = `${global.gConfig.url_redirect_alofono}?tcle_time=${new Date().getTime()}&token=${accessToken}`;
            }
            return;
        }
        setTermoAtual(termoAtual + 1);
    }

    const clickEnviarTermo = () => {
        if (termos[termoAtual].generico) {
            const dados = {
                cpf,
                tcle: termos[termoAtual].termo
            }
            setSalvando(true);
            axios.post(`${global.gConfig.url_base_administrativo}/tcle-paciente-externo`, dados)
                .then((response) => {
                    proximoTermo();
                })
                .catch((err) => {
                    setMostrarAlerta(true);
                })
                .finally(() => {
                    setSalvando(false);
                });
            return;
        }
        proximoTermo();

    };

    return (
        <>
            {
                termos.length > 0 &&
                <SttModal
                    title={strings.termoConsentimento}
                    maxWidth="lg"
                    open={true}
                    children={
                        <SttText variant="body1" dangerouslySetInnerHTML={{ __html: termos[termoAtual].termo }}></SttText>
                    }
                    footer={
                        <>
                            <SttButton variant="contained"
                                color="primary"
                                onClick={clickEnviarTermo}>
                                {strings.concordo}
                            </SttButton>
                            <SttButton variant="contained"
                                color="secondary"
                                onClick={() => window.location.reload(false)}>
                                {strings.discordo}
                            </SttButton>
                        </>
                    }
                />
            }
            <SttLoading
                open={salvando}
                text={strings.salvandoMensagemEspera}
            />
            <SttAlerta open={mostrarAlerta}
                title={strings.erro}
                message={strings.erroSalvarTcle}
                type={'error'}
                options={[
                    {
                        title: strings.ok,
                        onClick: () => {
                            window.location.reload(false);
                        }
                    }
                ]}
                onClose={() => {
                    window.location.reload(false);
                }}
            />
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        cpf: state.pacienteReducer.paciente.cpf,
        nome: state.pacienteReducer.paciente.nome,
        accessToken: state.pacienteReducer.accessToken,
    };
};

export default connect(mapStateToProps)(translate('TermoConsentimento')(TermoConsentimento));