import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setInstituicaoAlteracao as setInstituicaoAlteracaoAction
} from '@redux/actions/cadastro/instituicao/index';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import UtilInstituicao from '@util/instituicao';

const DadosInstituicao = lazy(() => import('@componentes/interno/cadastro/instituicao'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(-1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const CadastroInstituicao = (params) => {
    const { strings, user, instituicaoAlteracao, setParametrosAlerta, setOpen, setInstituicaoAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();
    const [validarForm, setValidarForm] = useState(false);

    useEffect(() => {
        if (instituicaoAlteracao) {
            setInitialValues(UtilInstituicao.inicializarValoresFormulario(instituicaoAlteracao));
        }
        else {
            setInitialValues(UtilInstituicao.inicializarValoresFormulario({}));
        }
        setCarregado(true);
    }, [instituicaoAlteracao]);

    const redirectPesquisaInstituicao = (resetForm) => {
        setOpen(false);

        if (instituicaoAlteracao) {
            navigate("/pesquisa/instituicao", { state: { pesquisar: true } });
            setInstituicaoAlteracao(null);
        } else {
            navigate("/pesquisa/instituicao");
        }

        resetForm({
            values: UtilInstituicao.inicializarValoresFormulario({})
        });
    };

    const enviarFormulario = (dados, setSubmitting, resetForm) => {
        setSubmitting(true);
        axios.post(`${ADM_API_BASE_URL}/instituicao`, dados, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {

                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                redirectPesquisaInstituicao(resetForm);
                            }
                        }
                    ],
                    onClose: () => {
                        redirectPesquisaInstituicao(resetForm);
                    }
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        mensagem = data.message;
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting, resetForm }) => {
                            let mensagem = strings.confirmacaoCadastroInstituicao;
                            if (instituicaoAlteracao?.id) {
                                mensagem = strings.confirmacaoAlteracaoInstituicao;
                            }
                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            let dadosFormatados = UtilInstituicao.formatarCadastro(dados);

                                            if (instituicaoAlteracao) {
                                                dadosFormatados.append('alteracao', true);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting, resetForm);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setInstituicaoAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/instituicao", { state: { pesquisar: true } });
                                                                }
                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{'Cadastro de instituição'}</SttHeading>

                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <DadosInstituicao
                                                    validarForm={validarForm}
                                                    setValidarForm={setValidarForm}
                                                />
                                            </Suspense>

                                            <div className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="Submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        setValidarForm(true);
                                                    }}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik>
                    :
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        instituicaoAlteracao: state.cadastroInstituicaoReducer.instituicaoAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInstituicaoAlteracao: id => dispatch(setInstituicaoAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroInstituicao')(CadastroInstituicao));