import * as yup from 'yup';
import {
    MODALIDADE, UF, MUNICIPIO, ESTADO
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [MODALIDADE]:yup.object().shape({
            descricao: yup.string(),
            id: yup.number(),
            identificador_tipo_modalidade: yup.number(),
            imprime_exame: yup.boolean(),
            modalityuid: yup.string(),
            sigla: yup.string(),
            suporte_dimp: yup.boolean()
        }).nullable().required(strings.campoObrigatorio),
        [UF]:yup.object().shape({
            codigo_ibge: yup.string(),
            id: yup.number(),
            id_pais: yup.number(),
            nome: yup.string(),
            sigla: yup.string()
        }).nullable().required(strings.campoObrigatorio),
        [MUNICIPIO]:yup.object().shape({
            codigo_ibge: yup.string(),
            id: yup.number(),
            id_estado: yup.number(),
            latitude: yup.number(),
            longitude: yup.number(),
            nome: yup.string(),
            nome_estado: yup.string(),
            sigla_estado: yup.string()
        }).nullable().required(strings.campoObrigatorio),
        [ESTADO]:yup.object().shape({
            codigo_ibge: yup.string(),
            id: yup.number(),
            id_pais: yup.number(),
            nome: yup.string(),
            sigla: yup.string()
        }).nullable()
    });
}