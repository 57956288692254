import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setAuthenticated as setAuthenticatedAction, setUser as setUserAction } from '@redux/actions';
import { setOpen as setOpenAction } from '@redux/actions/alerta';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Menu from '@componentes/interno/menu';
import { SttCircularProgress, SttAlerta } from '@stt-componentes/core';
import PesquisaFuncionario from './pesquisa/funcionario';
import PesquisaPerfil from './pesquisa/perfil';
import PesquisaPontos from './pesquisa/pontos';
import PesquisaMensagens from './pesquisa/mensagens';
import PesquisaPacs from './pesquisa/pacs';
import PesquisaInstituicao from './pesquisa/instituicao/index';
import Equipe from './pesquisa/equipes';
import PesquisaRede from './pesquisa/rede';
import TriagemAutoCadastro from './pesquisa/triagem';
import PesquisaInvalidacaoExame from './pesquisa/invalidacao-exame';
import PesquisaLogFuncionario from './pesquisa/log-funcionario';
import TriagemAcessoInstituicao from './pesquisa/acesso-instituicao';
import CadastroMotivosInvalidacaoExame from './cadastro/invalidacao-exame';
import CadastroFuncionario from './cadastro/funcionario';
import CadastroPacs from './cadastro/pacs';
import CadastroPerfil from './cadastro/perfil';
import CadastroPontos from './cadastro/pontos';
import CadastroPermissao from './cadastro/permissao';
import CadastroEquipes from './cadastro/equipes';
import CadastroRede from './cadastro/rede';
import CadastroMensagens from './cadastro/mensagens';
import PesquisaPaciente from './pesquisa/paciente';
import CadastroPaciente from './cadastro/paciente';
import CidadeReferencia from './cadastro/cidade-referencia';
import PesquisaMateriaisSuporte from './pesquisa/materiais-suporte';
import CadastroMateriaisSuporte from './cadastro/materiais-suporte';
import CadastroInstituicao from './cadastro/instituicao';
import { PERMISSOES } from '@common/Constants';
import Erro401 from '../erro/401';
import ProtectedRoute from './protectedRoute';
import { SttCacheManager } from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingBottom: theme.spacing(4),
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px',
    },
}));

const Interno = ({ isAuthenticated, setAuthenticated, setUser, setOpen, parametrosAlerta, user }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div>
            {isAuthenticated ? (
                <div className={classes.wrapper}>
                    <Menu />
                    <Routes>
                        <Route path="/nao-autorizado" element={<Erro401 />} />
                        <Route
                            path="/pesquisa/funcionario"
                            exact
                            element={<ProtectedRoute component={PesquisaFuncionario} permissao={PERMISSOES.PESQUISA_FUNCIONARIO} />}
                        />
                        <Route path="/pesquisa/perfil" element={<ProtectedRoute component={PesquisaPerfil} permissao={PERMISSOES.PESQUISA_PERFIL} />} />
                        <Route path="/pesquisa/ponto" element={<ProtectedRoute component={PesquisaPontos} permissao={PERMISSOES.PESQUISA_PONTO} />} />
                        <Route path="/pesquisa/equipes" element={<ProtectedRoute component={Equipe} permissao={PERMISSOES.PESQUISA_EQUIPES} />} />
                        <Route
                            path="/pesquisa/invalidacao-exame"
                            element={<ProtectedRoute component={PesquisaInvalidacaoExame} permissao={PERMISSOES.PESQUISA_EQUIPES} />}
                        />
                        <Route path="/cadastro/ponto" element={<ProtectedRoute component={CadastroPontos} permissao={PERMISSOES.CADASTRO_PONTO} />} />
                        <Route
                            path="/pesquisa/rede-telemedicina"
                            element={<ProtectedRoute component={PesquisaRede} permissao={PERMISSOES.PESQUISA_REDE_TELEMEDICINA} />}
                        />
                        <Route
                            path="/pesquisa/log-funcionario"
                            element={<ProtectedRoute component={PesquisaLogFuncionario} permissao={PERMISSOES.PESQUISAR_LOG_FUNCIONARIO} />}
                        />
                        <Route path="/pesquisa/paciente" element={<ProtectedRoute component={PesquisaPaciente} permissao={PERMISSOES.PESQUISAR_PACIENTE} />} />
                        <Route path="/pesquisa/pacs" element={<ProtectedRoute component={PesquisaPacs} permissao={PERMISSOES.PESQUISAR_PACS} />} />
                        <Route
                            path="/pesquisa/mensagens"
                            element={<ProtectedRoute component={PesquisaMensagens} permissao={PERMISSOES.PESQUISA_REDE_TELEMEDICINA} />}
                        />
                        <Route
                            path="/pesquisa/materiais-apoio"
                            element={<ProtectedRoute component={PesquisaMateriaisSuporte} permissao={PERMISSOES.PESQUISA_EQUIPES} />}
                        />
                        <Route
                            path="/pesquisa/instituicao"
                            element={<ProtectedRoute component={PesquisaInstituicao} permissao={PERMISSOES.PESQUISA_EQUIPES} />}
                        />
                        <Route
                            path="/cadastro/funcionario"
                            element={<ProtectedRoute component={CadastroFuncionario} permissao={PERMISSOES.CADASTRO_FUNCIONARIO} />}
                        />
                        <Route path="/cadastro/pacs" element={<ProtectedRoute component={CadastroPacs} permissao={PERMISSOES.CADASTRAR_PACS} />} />
                        <Route path="/cadastro/perfil" element={<ProtectedRoute component={CadastroPerfil} permissao={PERMISSOES.CADASTRO_PERFIL} />} />
                        <Route path="/cadastro/paciente" element={<ProtectedRoute component={CadastroPaciente} permissao={PERMISSOES.CADASTRAR_PACIENTE} />} />
                        <Route path="/cadastro/triagem" element={<ProtectedRoute component={TriagemAutoCadastro} permissao={PERMISSOES.TRIAGEM_CADASTRO} />} />
                        <Route path="/cadastro/permissao" element={<ProtectedRoute component={CadastroPermissao} permissao={PERMISSOES.PERMISSOES} />} />
                        <Route path="/cadastro/equipes" element={<ProtectedRoute component={CadastroEquipes} permissao={PERMISSOES.PESQUISA_EQUIPES} />} />
                        <Route
                            path="/cadastro/rede-telemedicina"
                            element={<ProtectedRoute component={CadastroRede} permissao={PERMISSOES.CADASTRO_REDE_TELEMEDICINA} />}
                        />
                        <Route
                            path="/cadastro/cidade-referencia"
                            element={<ProtectedRoute component={CidadeReferencia} permissao={PERMISSOES.CADASTRO_CIDADE_REF} />}
                        />
                        <Route
                            path="/cadastro/invalidacao-exame"
                            element={<ProtectedRoute component={CadastroMotivosInvalidacaoExame} permissao={PERMISSOES.PESQUISA_EQUIPES} />}
                        />
                        <Route path="/cadastro/mensagens" element={<ProtectedRoute component={CadastroMensagens} permissao={PERMISSOES.CADASTRO_MENSAGEM} />} />
                        <Route
                            path="/cadastro/materiais-apoio"
                            element={<ProtectedRoute component={CadastroMateriaisSuporte} permissao={PERMISSOES.CADASTRO_MENSAGEM} />}
                        />
                        <Route
                            path="/cadastro/instituicao"
                            element={<ProtectedRoute component={CadastroInstituicao} permissao={PERMISSOES.PESQUISA_EQUIPES} />}
                        />
                        <Route path="*" element={<Navigate replace to="/pesquisa/funcionario" />} />
                        <Route path='/pesquisa/acesso-instituicao' element={<ProtectedRoute component={TriagemAcessoInstituicao} permissao={PERMISSOES.PESQUISA_TRIAGEM_ACESSO_INSTITUICAO} />} />

                    </Routes>
                    <SttAlerta
                        open={parametrosAlerta.open}
                        title={parametrosAlerta.titulo}
                        message={parametrosAlerta.mensagem}
                        type={parametrosAlerta.tipo}
                        onClose={() => {
                            if (parametrosAlerta.onClose) {
                                parametrosAlerta.onClose();
                                return;
                            }
                            setOpen(false);
                        }}
                        options={parametrosAlerta.opcoes || []}
                    />
                </div>
            ) : (
                <>
                    <div className={classes.loading}>
                        <SttCircularProgress />
                    </div>
                    <SttCacheManager
                        config={global.gConfig}
                        onSync={(loaded) => {
                            setAuthenticated(loaded);

                            const user = Cache.getUserInfo();
                            setUser(user);
                            navigate(location);
                        }}
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.index.isAuthenticated,
        parametrosAlerta: state.alertaReducer.parametros,
        user: state.index.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthenticated: (authenticated) => dispatch(setAuthenticatedAction(authenticated)),
        setUser: (user) => dispatch(setUserAction(user)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno);
