import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PesquisaEquipes from '@componentes/interno/pesquisa/equipes';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    }
}))

const Equipe = () => {  
    const classes = useStyles();
    
    return (
        <div className={classes.wrapper}>
            <PesquisaEquipes />
        </div>
    );
};

export default Equipe;