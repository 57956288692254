/* eslint-disable no-unused-expressions */
import React from "react";
import {
    SttTranslate,
    SttModal,
    SttButton,
    SttInput,
    SttLoading,
    SttGrid
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '@src/request';
import axios from 'axios';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from "@redux/actions/alerta";
import HttpStatus from 'http-status-codes';
import { connect } from 'react-redux';
import * as yup from 'yup';
import {
    Formik,
    Field
} from 'formik';

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(1.5)
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const validationSchema = (strings) => {
    return yup.object().shape({
        'motivo': yup
            .string()
            .trim()
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const initialValues = {
    motivo: ''
};

const ModalRejeitarAcessoInstituicao = ({ openRejeitar, setOpenRejeitar, visualizarAcesso, strings, setParametrosAlerta, setAlertaOpen, callback }) => {
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const classes = useStyles();
    const schema = validationSchema(strings);

    const fecharModal = () => {
        setOpenRejeitar(false);
    }

    const rejeitar = (dados, setSubmitting) => {
        setSubmitting(true);
        axios.post(`${ADM_API_BASE_URL}/acesso-instituicao/rejeitar`, dados, { headers: getHeaders() })
            .then((response) => {
                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpenRejeitar(false);
                                setAlertaOpen(false);
                                callback();
                            }
                        }
                    ],
                    onClose: () => {
                        setOpenRejeitar(false);
                        setAlertaOpen(false);
                        callback();
                    }
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setAlertaOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    }

    return (
        <SttModal
            title={strings.rejeitarSolicitacaoAcesso}
            open={openRejeitar}
            maxWidth="md"
            outModalClose={fecharModal}
            iconClose={fecharModal}
            fullWidth={true}
            children={
                <div className={classes.modalBody}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            setSubmitting(false);
                            const dadosSalvar = {
                                id_usuario: visualizarAcesso.id_usuario,
                                id_instituicao: visualizarAcesso.id_instituicao_solicitada,
                                id_funcionario: visualizarAcesso.id_funcionario,
                                data_hora_solicitacao: visualizarAcesso.data_hora_solicitacao,
                                motivo_rejeicao: dados.motivo.trim()
                            }

                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: strings.confirmarRejeitar,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.sim,
                                        onClick: () => {
                                            rejeitar(dadosSalvar, setSubmitting);
                                            setAlertaOpen(false);

                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: () => {
                                            setAlertaOpen(false);
                                        }
                                    }
                                ],
                                onClose: () => {
                                    setAlertaOpen(false);
                                }
                            };

                            setParametrosAlerta(parametrosAlerta);

                        }}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <Field name='motivo'>
                                                    {({
                                                        field,
                                                        meta
                                                    }) => (
                                                        <SttInput
                                                            {...field}
                                                            required={true}
                                                            error={meta.touched && meta.error ? true : false}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            multiline
                                                            minRows={6}
                                                            maxRows={9}
                                                            label={strings.motivoRejeicao}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            <SttLoading
                                                open={isSubmitting}
                                                text={strings.salvandoMensagemEspera}
                                            />
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    className={classes.button}
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.confirmar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className={classes.button}
                                                    onClick={fecharModal}
                                                >
                                                    {strings.cancelar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </div>
            }
        />
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setAlertaOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(null, mapDispatchToProps)(SttTranslate('TriagemAcessoInstituicao')(ModalRejeitarAcessoInstituicao));