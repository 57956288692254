const PESQUISA_FUNCIONARIO = {
    REDUCER_TYPE: {
        SETAR_PARAMETROS_PESQUISA: 'SETAR_PARAMETROS_PESQUISA',
        LIMPAR_PARAMETROS_PESQUISA: 'LIMPAR_PARAMETROS_PESQUISA'
    },

    INITIAL_STATE_ALERTA: {
        titulo: '',
        mensagem: '',
        tipo: 'alert',
        visivel: false,
        botoes: []
    }
};

export default PESQUISA_FUNCIONARIO;