import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import FormPesquisa from './form';
import { useNavigate } from "react-router-dom";
import translate from '@componentes/translate';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttOptionsButton,
    SttMenu,
    SttMenuItem,
    SttLoading,
    SttHeading
} from '@stt-componentes/core';
import { getHeaders } from '@src/request';
import axios from 'axios';
import httpStatus from 'http-status-codes';
import { temPermissaoRBAC } from '@security/acl';
import {
    PERMISSOES
} from '@common/Constants';
import {
    setPerfilAlteracao as setPerfilAlteracaoAction
} from '@redux/actions/cadastro/perfil';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from "@redux/actions/alerta";

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        DESCRICAO: 'descricao',
        VINCULO: 'vinculo',
        TIMEOUT_TOKEN: 'timeout_token'

    }
}

const Pesquisa = (props) => {
    const { user, strings, setPerfilAlteracao, setParametrosAlerta, setOpen } = props;
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(true);

    //Solicitação
    const [perfis, setPerfis] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const navigate = useNavigate();
    const [arcoraMenu, setArcoraMenu] = useState(null);
    const [menuSelecionado, setMenuSelecionado] = useState(null);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const [carregando, setCarregando] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo, handleSubmit) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setPerfis(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
        setPerfis([]);
        setTotalRegistros(0);
    }

    const handleClickOptions = indice => event => {
        setMenuSelecionado(indice);
        setArcoraMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setArcoraMenu(null);
        setMenuSelecionado(null);
    };

    const clickEditarPerfil = (perfil) => {
        setPerfilAlteracao(perfil);
        navigate(`/cadastro/perfil`);
    };

    const clickExcluirPerfil = (perfil) => {
        let mensagem = strings.confirmacaoExcluirPerfil;
        handleCloseMenu();

        const parametrosAlerta = {
            titulo: strings.atencao,
            mensagem: mensagem,
            tipo: 'alert',
            open: true,
            opcoes: [
                {
                    title: strings.ok,
                    onClick: () => {
                        setCarregando(true);
                        axios.delete(`${ADM_API_BASE_URL}/perfil`, { headers: getHeaders(), data: perfil })
                            .then((response) => {
                                const { data } = response;
                                setCarregando(false);
                                const parametrosAlerta = {
                                    titulo: strings.atencao,
                                    mensagem: data.message,
                                    tipo: 'success',
                                    open: true,
                                    opcoes: [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setBuscar(true);
                                                setOpen(false);
                                            }
                                        }
                                    ],
                                    onClose: () => {
                                        setBuscar(true);
                                        setOpen(false);
                                    }
                                };

                                setParametrosAlerta(parametrosAlerta);
                            })
                            .catch(err => {
                                const { response } = err;
                                setCarregando(false);
                                handleCloseMenu();
                                let mensagemAlertaErro = strings.mensagemErroGeral;

                                if (response && (response.status === httpStatus.BAD_REQUEST)) {
                                    const { data } = response;
                                    mensagemAlertaErro = data.message;
                                }

                                const parametrosAlertaErro = {
                                    titulo: strings.erro,
                                    mensagem: mensagemAlertaErro,
                                    tipo: 'error',
                                    open: true,
                                    opcoes: [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setOpen(false);
                                            }
                                        }
                                    ]
                                };

                                setParametrosAlerta(parametrosAlertaErro);
                            });
                    }
                },
                {
                    title: strings.cancelar,
                    onClick: () => {
                        setOpen(false);
                    }
                }
            ]
        };

        setParametrosAlerta(parametrosAlerta);
        return true;
    };

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>
            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.DESCRICAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DESCRICAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DESCRICAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DESCRICAO)}
                                    >
                                        {strings.descricao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.VINCULO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.VINCULO}
                                        direction={orderBy === ORDENACAO.CAMPOS.VINCULO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.VINCULO)}
                                    >
                                        {strings.vinculo}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.TIMEOUT_TOKEN ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.TIMEOUT_TOKEN}
                                        direction={orderBy === ORDENACAO.CAMPOS.TIMEOUT_TOKEN ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.TIMEOUT_TOKEN)}
                                    >
                                        {strings.timeoutToken}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.opcoes}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Pesquisa de perfis</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={13}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    perfis.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={13}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        perfis.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.descricao}</SttTableCell>
                                                    <SttTableCell>{
                                                        row.vinculo ? row.vinculo.descricao : ''
                                                    }</SttTableCell>
                                                    <SttTableCell>{row.timeout_token}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.descricao}:</b> {row.descricao}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.vinculo}:</b> {row.vinculo ? row.vinculo.descricao : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.timeoutToken}:</b> {row.timeout_token ? row.timeout_token : ''}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    {
                                                        (temPermissaoRBAC(user, PERMISSOES.CADASTRO_PERFIL) ||
                                                            temPermissaoRBAC(user, PERMISSOES.DELECAO_PERFIL)) &&
                                                        <>
                                                            <SttOptionsButton onClick={handleClickOptions(index)} />
                                                            <SttMenu anchorEl={arcoraMenu}
                                                                keepMounted
                                                                open={Boolean(arcoraMenu) && menuSelecionado === index}
                                                                onClose={handleCloseMenu}>
                                                                {
                                                                    temPermissaoRBAC(user, PERMISSOES.CADASTRO_PERFIL) &&
                                                                    <SttMenuItem onClick={() => clickEditarPerfil(row)}>
                                                                        {strings.editar}
                                                                    </SttMenuItem>
                                                                }

                                                                {
                                                                    temPermissaoRBAC(user, PERMISSOES.DELECAO_PERFIL) &&
                                                                    <SttMenuItem onClick={() => clickExcluirPerfil(row)}>
                                                                        {strings.excluir}
                                                                    </SttMenuItem>
                                                                }
                                                            </SttMenu>
                                                        </>
                                                    }
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                perfis.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }

            <SttLoading
                open={carregando}
                text={strings.carregando}
            />
        </>
    )
}

Pesquisa.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPerfilAlteracao: id => dispatch(setPerfilAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaPerfil')(Pesquisa));