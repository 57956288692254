/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    NOVA_SENHA,
    CONFIRMACAO_SENHA,
} from './fieldNames';

const testeNovaSenhaConfirmacaoIguais = (parent) => {
    if (!parent.novaSenha || !parent.confirmacaoSenha) {
        return true;
    }
    return parent.novaSenha === parent.confirmacaoSenha;
}

export default (strings) => {
    return yup.object().shape({
        [NOVA_SENHA]: yup
            .string()
            .nullable()
            .trim()
            .required(strings.campoObrigatorio)
            .min(8, strings.senhaMinimo9Caracteres)
            .matches(/^[^\s]+$/, strings.senhaSemEspacos)
            .matches(/[A-Z]/, strings.senhaAoMenosUmaMaiuscula)
            .matches(/\d/, strings.senhaAoMenosUmNumero)
            .matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/, strings.senhaAoMenosUmCaracter)
            .test('senhasIguais', strings.novaSenhaConfirmacaoSenhaIgual, function () {
                return testeNovaSenhaConfirmacaoIguais(this.parent);
            }),
        [CONFIRMACAO_SENHA]: yup
            .string()
            .nullable()
            .trim()
            .required(strings.campoObrigatorio)
            .test('senhasIguais', strings.novaSenhaConfirmacaoSenhaIgual, function () {
                return testeNovaSenhaConfirmacaoIguais(this.parent);
            }),
    });
}
