export const estadoInicial = {
    nome: '',
    situacao: null,
    cpf: '',
    rqe: '',
    email: '',
    numeroConselhoTrabalho: '',
    uf: null,
    cidade: null,
    instituicao: ''
};