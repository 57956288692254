/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    VINCULO,
    MODALIDADES,
} from '../fieldNames';

export default (strings) => {
    return yup.object().shape({
        [VINCULO]: yup.object().shape({
            [MODALIDADES]:
                yup.array()
                    .when('$temPerfilAdministradorLocal', {
                        is: (val) => val,
                        then: yup.array().of(
                            yup.object().shape({
                                id: yup.number(),
                                nome: yup.string()
                            }).required()
                        ).min(1, strings.modalidadeObrigatoria)
                            .required()
                    })
        })
    });
}
