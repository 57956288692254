/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DESCRICAO
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DESCRICAO]: yup
            .string()
            .nullable()
            .matches(/^[aA-zZ\s]+$/, strings.somenteLetrasPermitidas)
    });
}
