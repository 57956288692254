/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DADOS_VINCULO,
    INSTITUICAO,
    RESPONSAVEL,
    SECRETARIO_SAUDE,
    SETOR
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_VINCULO]: yup.object().shape({
            [INSTITUICAO]: yup.object().shape({
                id: yup.number(),
                nome: yup.string(),
            })
                .nullable()
                .test('instituicaoTrocaPonto', strings.instituicaoDiferente, function () {
                    if (!this.parent.instituicaoAntiga || !this.parent[INSTITUICAO]) return true;
                    if (this.parent.instituicaoAntiga.id === this.parent[INSTITUICAO].id) {
                        return false;
                    };
                    return true;
                })
                .required(strings.campoObrigatorio),
            [SETOR]: yup.object().shape({
                id: yup.number(),
                descricao: yup.string(),
            }).nullable().required(strings.campoObrigatorio),
            [RESPONSAVEL]: yup
                .string()
                .trim()
                .min(1)
                .max(255)
                .matches(/(\w.+\s).+/i, strings.informeNomeCompleto)
                .nullable(),
            [SECRETARIO_SAUDE]: yup
                .string()
                .trim()
                .min(1)
                .max(255)
                .matches(/(\w.+\s).+/i, strings.informeNomeCompleto)
                .nullable(),
        }).required()
    });
}
