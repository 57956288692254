import React, { Suspense, lazy } from "react";
import {
    SttContainer,
    SttCircularProgress
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import translate from '@componentes/translate';

const DadosPesquisa = lazy(() => import('@componentes/interno/cadastro/permissao'));

const CadastroPermissao = (params) => {
    return (
        <SttContainer>
            <Suspense fallback={<SttCircularProgress color="primary" />}>
                <DadosPesquisa />
            </Suspense>
        </SttContainer>
    );
}

export default connect(null, null)(translate('CadastroPermissao')(CadastroPermissao));