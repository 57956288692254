import {
    NOME,
    CODIGO_INE,
    INSTITUICAO,
    ATIVA,
    ID
} from '@componentes/interno/cadastro/equipes/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};

    formulario[ID] = dadosAlteracao.id ? dadosAlteracao.id : null;
    formulario[NOME] = dadosAlteracao.nome ? dadosAlteracao.nome : '';
    formulario[CODIGO_INE] = dadosAlteracao.codigo_ine ? dadosAlteracao.codigo_ine : '';
    formulario[INSTITUICAO] = dadosAlteracao.instituicao ? dadosAlteracao.instituicao : null;
    formulario[ATIVA] = dadosAlteracao.ativa;
   
    return formulario;
}

const formatarCadastro = (dados) => {

    const formData = new FormData();

    formData.append('id', dados.id);
    formData.append('nome', dados.nome.trim());
    formData.append('codigo_ine', dados.codigo_ine.trim());
    formData.append('id_instituicao', dados.instituicao.id);
    formData.append('ativa', dados.ativa);

    return formData;
};

export default { inicializarValoresFormulario, formatarCadastro };