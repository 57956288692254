/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { getHeaders } from '@src/request';
import {
    SttHeader,
    SttMainNavigationBar,
    SttSplashScreen,
    MateriaisSuporte,
    SttNotificationScreen,
    SttHelpdeskShortcut
} from '@stt-componentes/core';
import translate from '@componentes/translate';
import CnesCadsus from './pesquisa/cnes-cadsus';
import { temPermissaoRBAC } from '@security/acl';
import { PERMISSOES } from '@common/Constants';
import { setIdFuncionarioAlteracao as setIdFuncionarioAlteracaoAction } from '@redux/actions/cadastro/funcionario/index';

const Menu = ({ user, setIdFuncionarioAlteracao, strings }) => {
    const navigate = useNavigate();
    const tabAtual = false;
    const [submenus, setSubmenus] = useState([]);
    const location = useLocation();
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);
    const [openCnesCadsus, setOpenCnesCadsus] = useState(false);
    const [exibirNotificacao, setExibirNotificacao] = useState(false);

    useEffect(() => {
        let menuOptions = [];
        var listaMenuPesquisa = [];

        if (user.permissoesRBAC) {
            if (!temPermissaoRBAC(user, PERMISSOES.ADMINISTRATIVO)) {
                navigate("/nao-autorizado");
                return;
            }

            setExibirNotificacao(true);

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_FUNCIONARIO)) {
                listaMenuPesquisa.push({
                    text: 'Funcionários/Usuários',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/funcionario') {
                            setIdFuncionarioAlteracao(null);
                            navigate(`/pesquisa/funcionario`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_PERFIL)) {
                listaMenuPesquisa.push({
                    text: 'Perfis',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/perfil') {
                            navigate(`/pesquisa/perfil`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_PONTO)) {
                listaMenuPesquisa.push({
                    text: 'Pontos',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/ponto') {
                            navigate(`/pesquisa/ponto`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_EQUIPES)) {
                listaMenuPesquisa.push({
                    text: 'Equipes de Saúde da Família (ESF)',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/equipes') {
                            navigate(`/pesquisa/equipes`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISAR_PACS)) {
                listaMenuPesquisa.push({
                    text: 'PACS',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/pacs') {
                            navigate(`/pesquisa/pacs`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISAR_PACIENTE)) {
                listaMenuPesquisa.push({
                    text: 'Paciente',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/paciente') {
                            navigate(`/pesquisa/paciente`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_REDE_TELEMEDICINA)) {
                listaMenuPesquisa.push({
                    text: 'Redes de Telemedicina',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/rede-telemedicina') {
                            navigate(`/pesquisa/rede-telemedicina`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_MENSAGEM)) {
                listaMenuPesquisa.push({
                    text: 'Mensagens',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/mensagens') {
                            navigate(`/pesquisa/mensagens`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_MOTIVOS_INVALIDACAO)) {
                listaMenuPesquisa.push({
                    text: 'Motivos de Invalidação de Exame',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/invalidacao-exame') {
                            navigate(`/pesquisa/invalidacao-exame`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_MATERIAIS_SUPORTE)) {
                listaMenuPesquisa.push({
                    text: 'Materiais de Apoio',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/materiais-apoio') {
                            navigate(`/pesquisa/materiais-apoio`);
                        }
                    },
                });
            }

            listaMenuPesquisa.sort((a, b) => a.text.localeCompare(b.text));
            if (temPermissaoRBAC(user, PERMISSOES.ACESSO_CNES_CADSUS)) {
                listaMenuPesquisa.push({
                    text: 'CNES/CADSUS',
                    onClick: () => setOpenCnesCadsus(true),
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISAR_INST)) {
                listaMenuPesquisa.push({
                    text: 'Instituição',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/instituicao') {
                            navigate(`/pesquisa/instituicao`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISAR_LOG_FUNCIONARIO)) {
                listaMenuPesquisa.push({
                    text: 'Log Funcionário',
                    onClick: () => {
                        if (location.pathname !== '/pesquisa/log-funcionario') {
                            navigate(`/pesquisa/log-funcionario`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PESQUISA_FUNCIONARIO)) {
                listaMenuPesquisa.push(
                    {
                        text: 'Triagem de acesso a instituições',
                        onClick: () => {
                            if (location.pathname !== '/pesquisa/acesso-instituicao') {
                                navigate(`/pesquisa/acesso-instituicao`);
                            }
                        }
                    }
                );
            }

            listaMenuPesquisa.sort((a, b) => a.text.localeCompare(b.text));

            //Adiciona nova lista de pesquisa somente se o usuário tem alguma pesquisa que possa fazer
            if (listaMenuPesquisa.length) {
                menuOptions = [
                    ...menuOptions,
                    {
                        id: '/pesquisa',
                        text: 'Pesquisa',
                        list: listaMenuPesquisa,
                    },
                ];
            }

            var listaMenuCadastro = [];

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_CIDADE_REF)) {
                listaMenuCadastro.push({
                    text: 'Cidade Referência',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/cidade-referencia') {
                            navigate(`/cadastro/cidade-referencia`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_FUNCIONARIO)) {
                listaMenuCadastro.push({
                    text: 'Funcionários/Usuários',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/funcionario') {
                            navigate(`/cadastro/funcionario`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRAR_PACS)) {
                listaMenuCadastro.push({
                    text: 'PACS',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/pacs') {
                            navigate(`/cadastro/pacs`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_PERFIL)) {
                listaMenuCadastro.push({
                    text: 'Perfis',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/perfil') {
                            navigate(`/cadastro/perfil`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.PERMISSOES)) {
                listaMenuCadastro.push({
                    text: 'Permissões',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/permissao') {
                            navigate(`/cadastro/permissao`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.TRIAGEM_CADASTRO)) {
                listaMenuCadastro.push({
                    text: 'Triagem de auto-cadastro',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/triagem') {
                            navigate(`/cadastro/triagem`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_PONTO)) {
                listaMenuCadastro.push({
                    text: 'Pontos',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/ponto') {
                            navigate(`/cadastro/ponto`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_ESF)) {
                listaMenuCadastro.push({
                    text: 'Equipes de Saúde da Família (ESF)',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/equipes') {
                            navigate(`/cadastro/equipes`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_REDE_TELEMEDICINA)) {
                listaMenuCadastro.push({
                    text: 'Rede de Telemedicina',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/rede-telemedicina') {
                            navigate(`/cadastro/rede-telemedicina`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_MENSAGEM)) {
                listaMenuCadastro.push({
                    text: 'Mensagens',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/mensagens') {
                            navigate(`/cadastro/mensagens`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_MOTIVOS_INVALIDACAO)) {
                listaMenuCadastro.push({
                    text: 'Motivos de Invalidação de Exame',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/invalidacao-exame') {
                            navigate(`/cadastro/invalidacao-exame`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRO_MATERIAIS_SUPORTE)) {
                listaMenuCadastro.push({
                    text: 'Materiais de Apoio',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/materiais-apoio') {
                            navigate(`/cadastro/materiais-apoio`);
                        }
                    },
                });
            }

            if (temPermissaoRBAC(user, PERMISSOES.CADASTRAR_INST)) {
                listaMenuCadastro.push({
                    text: 'Instituição',
                    onClick: () => {
                        if (location.pathname !== '/cadastro/instituicao') {
                            navigate(`/cadastro/instituicao`);
                        }
                    },
                });
            }

            listaMenuCadastro.sort((a, b) => a.text.localeCompare(b.text));

            //Adiciona nova lista de cadastro somente se o usuário tem algum cadastro que possa fazer
            if (listaMenuCadastro.length) {
                menuOptions = [
                    ...menuOptions,
                    {
                        id: '/cadastro',
                        text: 'Cadastro',
                        list: listaMenuCadastro,
                    },
                ];
            }
        }

        setSubmenus(menuOptions);
    }, [user.permissoesRBAC]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS((o) => !o),
                    onClickMateriaisSuporte: () => setOpenMS((o) => !o),
                    strings,
                }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                perfisRBAC={user.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <SttMainNavigationBar config={global.gConfig} titulo={global.gConfig.modulo_nome} submenus={submenus} selectedTab={tabAtual} />
            <CnesCadsus open={openCnesCadsus} setOpen={setOpenCnesCadsus} />
            <MateriaisSuporte strings={strings} config={global.gConfig} headers={getHeaders()} open={openMS} setOpen={setOpenMS} />
            {
                exibirNotificacao &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={user.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={user}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIdFuncionarioAlteracao: (id) => dispatch(setIdFuncionarioAlteracaoAction(id)),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('Cabecalho')(Menu));
