import {
    ASSUNTO,
    CONTEUDO,
    SITUACAO,
    AREA,
    DADOS_GERAIS
} from '@componentes/interno/cadastro/mensagens/dadosGerais/form/fieldNames';
import {
    CIDADE,
    DADOS_OUTROS,
    EQUIPE,
    INSTITUICAO,
    MACRORREGIAO,
    UF
} from '@componentes/interno/cadastro/mensagens/dadosOutros/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};

    if (dadosAlteracao.id) {
        formulario.alteracao = true;
        formulario.id = dadosAlteracao.id;
    }

    formulario[DADOS_GERAIS] = {};
    formulario[DADOS_GERAIS][ASSUNTO] = dadosAlteracao.assunto ?? '';
    formulario[DADOS_GERAIS][CONTEUDO] = dadosAlteracao.mensagem ?? '';
    formulario[DADOS_GERAIS][SITUACAO] = dadosAlteracao.ativa ?? true;
    formulario[DADOS_GERAIS][AREA] = dadosAlteracao.id_cbo_familia_telessaude ? { id: dadosAlteracao.id_cbo_familia_telessaude, descritor: dadosAlteracao.descritor_cbo_familia } : null;

    formulario[DADOS_OUTROS] = {};
    formulario[DADOS_OUTROS][UF] = dadosAlteracao.id_estado ? { id: dadosAlteracao.id_estado, sigla: dadosAlteracao.sigla_estado } : null;
    formulario[DADOS_OUTROS][CIDADE] = dadosAlteracao.id_cidade ? { id: dadosAlteracao.id_cidade, nome: dadosAlteracao.nome_cidade } : null;
    formulario[DADOS_OUTROS][INSTITUICAO] = dadosAlteracao.id_instituicao ? { id: dadosAlteracao.id_instituicao, nome: dadosAlteracao.nome_instituicao } : null;
    formulario[DADOS_OUTROS][EQUIPE] = dadosAlteracao.id_equipe_saude_familia ? { id: dadosAlteracao.id_equipe_saude_familia, nome: dadosAlteracao.nome_equipe_saude } : null;
    formulario[DADOS_OUTROS][MACRORREGIAO] = dadosAlteracao.id_macrorregiao ? { id: dadosAlteracao.id_macrorregiao, nome: dadosAlteracao.nome_macrorregiao } : null;

    return formulario;
}

export default { inicializarValoresFormulario };