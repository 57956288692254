/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    NOME,
    DESCRICAO,
    DATA,
    ATIVO,
    ANEXOS
} from './fieldNames';
import { boolean } from '../../../../../../../node_modules/yup/lib/locale';

export default (strings) => {
    return yup.object().shape({
        [NOME]: yup.string()
            .nullable()
            .min(1)
            .max(50)
            .required(strings.campoObrigatorio),
        [DESCRICAO]: yup.string()
            .nullable()
            .min(1)
            .max(255),
        [DATA]: yup
        .date()
        .typeError(strings.dataInvalida)
        .nullable()
        .required(strings.campoObrigatorio),
        [ATIVO]: yup.boolean()
        //.nullable()
        .required(boolean.campoObrigatorio),
        [ANEXOS]: yup
                    .mixed()
                    .nullable()
                    .required(strings.campoObrigatorio)
                    .test('tamanhoArquivo', strings.erroTamanhoArquivo500Mb, (value) => {
                        if (!value || !(value instanceof File)) {
                            return true;
                        }
                        return value?.size <= 1048576 * 500;
                    })
    });
}