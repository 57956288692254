import TYPES from '@redux/types/pesquisa/paciente';

import { estadoInicial } from '@componentes/interno/pesquisa/paciente/estadoInicial';
import { PACIENTES } from '@componentes/interno/pesquisa/paciente/fieldNames';
const INITIAL_STATE = estadoInicial;

function pesquisaPacienteReducer(state = INITIAL_STATE, action) {
    
    const { type, payload } = action;

    if (type === TYPES.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...INITIAL_STATE
        };
    }

    if (type === TYPES.SETAR_PARAMETROS_PESQUISA) {
        return { ...state, ...payload.parametros };
    }

    if (type === TYPES.SETAR_RESULTADO_PESQUISA) {
        return { ...state, [PACIENTES]: payload };
    }


    return state;
}

export default pesquisaPacienteReducer;