import TYPES from '@redux/types/cadastro/paciente/index';

const INITIAL_STATE = {
    pacienteAlteracao: null,
    exames: []
};

function cadastroPacienteReducer(state = INITIAL_STATE, action) {
    
    const { type, payload } = action;
    if(type === TYPES.SETAR_DADOS_PACIENTE_ALTERACAO) {
        return { pacienteAlteracao: payload };
    }
    if (type === TYPES.SET_EXAMES) {
        return { ...state, exames: payload }
    }
    return state;
}

export default cadastroPacienteReducer;