import * as yup from 'yup';
import {
    DADOS_PROFISSIONAIS,
    PROFISSAO,
    CONSELHO_TRABALHO,
    UF_CONSELHO,
    TIPO_CONSELHO,
    NUMERO_CONSELHO,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_PROFISSIONAIS]: yup.object().shape({
            [PROFISSAO]: yup
                .string()
                .trim()
                .nullable()
                .min(5, strings.profissaoInvalida)
                .max(100, strings.profissaoInvalida)
                .required(strings.campoObrigatorio),
            [CONSELHO_TRABALHO]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [UF_CONSELHO]: yup
                .string()
                .nullable()
                .when(CONSELHO_TRABALHO, {
                    is: (val) => val && val === 'S',
                    then: yup
                        .string()
                        .nullable()
                        .required(strings.campoObrigatorio)
                }),
            [TIPO_CONSELHO]: yup
                .string()
                .nullable()
                .when(CONSELHO_TRABALHO, {
                    is: (val) => val && val === 'S',
                    then: yup
                        .string()
                        .nullable()
                        .required(strings.campoObrigatorio)
                }),
            [NUMERO_CONSELHO]: yup
                .string()
                .trim()
                .nullable()
                .min(2, strings.numeroConselhoInvalido)
                .max(20, strings.numeroConselhoInvalido)
                .when(CONSELHO_TRABALHO, {
                    is: (val) => val && val === 'S',
                    then: yup
                        .string()
                        .nullable()
                        .required(strings.campoObrigatorio)
                }),
        }).required()
    });
}
