import ConstantesTriagemAutocadastro from '@constantes/cadastro/triagem';

const { REDUCER_TYPE } = ConstantesTriagemAutocadastro;

const INITIAL_STATE = {
    parametrosPesquisaTriagem: {
        nome: '',
        cpf: '',
        situacao: { sigla: 'A', descricao: 'Aguardando triagem' },
        data_inicial: null,
        data_final: null,
        acompanhar_facebook: null
    }
};

function triagemAutocadastroReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if(type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA_TRIAGEM){
        return {
            ...state,
            parametrosPesquisaTriagem: INITIAL_STATE.parametrosPesquisaTriagem
        };
    }

    if(type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA_TRIAGEM){
        return {
            ...state,
            parametrosPesquisaTriagem: payload.parametros
        };
    }

    return state;
}

export default triagemAutocadastroReducer;