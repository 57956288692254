import TYPES from '@redux/types/cadastro/rede';

const INITIAL_STATE = {
    redeAlteracao: null
};

function cadastroRedeReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === TYPES.SETAR_ID_REDE_TELEMEDICINA_ALTERACAO) {
        return {
            ...state,
            redeAlteracao: payload.redeAlteracao
        };
    }
    return state;
}

export default cadastroRedeReducer;