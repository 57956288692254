import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setEquipeAlteracao as setEquipeAlteracaoAction
} from '@redux/actions/cadastro/equipes/index';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import UtilEquipes from '@util/equipes';

const DadosEquipeSaudeFamilia = lazy(() => import('@componentes/interno/cadastro/equipes'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const Carregando = memo((props) => {
    return (
        <SttLoading {...props} />
    )
});

const CadastroEquipes = (params) => {
    const { strings, user, equipeAlteracao, setParametrosAlerta, setOpen, setEquipeAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        if (equipeAlteracao) {
            setInitialValues(UtilEquipes.inicializarValoresFormulario(equipeAlteracao));
        }
        else {
            setInitialValues(UtilEquipes.inicializarValoresFormulario({}));
        }
        setCarregado(true);
    }, [equipeAlteracao]);

    const redirectPesquisaEquipes = (resetForm) => {
        setOpen(false);
        
        if(equipeAlteracao) {
            navigate("/pesquisa/equipes", { state: { pesquisar: true } });
            setEquipeAlteracao(null);
        } else {
            navigate("/pesquisa/equipes");
        }

        resetForm({
            values: UtilEquipes.inicializarValoresFormulario({})
        });
    }

    const enviarFormulario = (dados, setSubmitting, resetForm) => {
        setSubmitting(true);
        axios
            .post(`${ADM_API_BASE_URL}/equipe`, dados, { headers: getHeaders() })
            .then((response) => {

                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                redirectPesquisaEquipes(resetForm);
                            }
                        }
                    ],
                    onClose: () => {
                        redirectPesquisaEquipes(resetForm);
                    }
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        mensagem = data.message;
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting, resetForm }) => {
                            let mensagem = strings.confirmacaoCadastroEquipes;
                            if (equipeAlteracao) {
                                mensagem = strings.confirmacaoAlteracaoEquipes;
                            }
                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            let dadosFormatados = UtilEquipes.formatarCadastro(dados);
                                            if (equipeAlteracao) {
                                                dadosFormatados.append('alteracao', true);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting, resetForm);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setEquipeAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/equipes", { state: { pesquisar: true } });
                                                                }
                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.tituloPagina}</SttHeading>

                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <DadosEquipeSaudeFamilia />
                                            </Suspense>

                                            <div className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :

                    (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        equipeAlteracao: state.cadastroEquipeReducer.equipeAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEquipeAlteracao: id => dispatch(setEquipeAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroEquipes')(CadastroEquipes));