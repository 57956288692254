import ConstantesCadastroInstituicao from '@constantes/cadastro/instituicao/index';

const { REDUCER_TYPE } = ConstantesCadastroInstituicao;

const INITIAL_STATE = {
    instituicaoAlteracao: null
};

function cadastroInstituicaoReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    const { type, payload } = action;
    
    if(type === REDUCER_TYPE.SETAR_ID_INSTITUICAO_ALTERACAO) {
        return {
            ...state,
            instituicaoAlteracao: payload
        };
    }

    return state;
};

export default cadastroInstituicaoReducer;