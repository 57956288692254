/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    IDENTIFICADOR,
    DESCRICAO,
    VINCULO,
    TIMEOUT_TOKEN
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [IDENTIFICADOR]: yup.string()
            .nullable()
            .min(1)
            .max(20)
            .matches(/^[aA-zZ\s]+$/, strings.somenteLetrasSemAcentoPermitidas)
            .matches(/^\S*$/, strings.espacosNaoPermitidos)
            .required(strings.campoObrigatorio),
        [DESCRICAO]: yup.string()
            .nullable()
            .min(1)
            .max(255)
            .matches(/^[A-Za-zÀ-ÿ ]+$/, strings.somenteLetrasPermitidas)
            .required(strings.campoObrigatorio),
        [VINCULO]: yup.object().shape({
            id: yup.number(),
            descricao: yup.string(),
            sigla: yup.string()
        }).nullable().required(strings.campoObrigatorio),
        [TIMEOUT_TOKEN]: yup.number()
            .nullable()
            .positive(strings.mumeroPositivo),
    });
}
