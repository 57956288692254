import * as yup from 'yup';
import validarCpf from 'validar-cpf';
import {
    CPF
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [CPF]: yup
            .string()
            .nullable()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = (cpf || '').replace(/\D+/g, '');
                if (cpf.length === 0) {
                    return true;
                }
                return validarCpf(cpf);
            })
    });
}
