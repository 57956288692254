/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DADOS_TIPO,
    AETITLE,
    BRIDGE,
    CONFIRMACAO_SENHA,
    MODALIDADE,
    REDE_TELEMEDICINA,
    SENHA,
    TIPO
} from './fieldNames';
import { PONTOS } from '@common/Constants';

const testeNovaSenhaConfirmacaoIguais = (parent) => {
    if (!parent[SENHA] || !parent[CONFIRMACAO_SENHA]) {
        return true;
    }
    return parent[SENHA] === parent[CONFIRMACAO_SENHA];
}

export default (strings) => {
    return yup.object().shape({
        [DADOS_TIPO]: yup.object().shape({
            [MODALIDADE]: yup.object().shape({
                id: yup.number(),
                descricao: yup.string(),
            }).nullable().required(strings.campoObrigatorio),
            [REDE_TELEMEDICINA]: yup.object().shape({
                id: yup.number(),
                descricao: yup.string(),
            }).nullable().required(strings.campoObrigatorio),
            [BRIDGE]: yup
                .object()
                .nullable()
                .when(TIPO, {
                    is: (val) => val === PONTOS.TIPO_BRIDGE,
                    then: yup.object().shape({
                        id: yup.number(),
                        nome: yup.string(),
                    }).required(strings.campoObrigatorio),
                }),
            [AETITLE]: yup
                .string()
                .nullable(),
            [SENHA]: yup.string()
                .test('senhasIguais', strings.senhaConfirmacaoSenhaIgual, function () {
                    return testeNovaSenhaConfirmacaoIguais(this.parent);
                }),
            [CONFIRMACAO_SENHA]: yup.string()
                .test('senhasIguais', strings.senhaConfirmacaoSenhaIgual, function () {
                    return testeNovaSenhaConfirmacaoIguais(this.parent);
                }),
        }).required()
    });
}
