import {
    ID,
    MODALIDADE,
    REDE_TELEMEDICINA,
    DESCRICAO,
    SITUACAO
} from '@componentes/interno/cadastro/invalidacao-exame/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};

    formulario[ID] = dadosAlteracao.id ? dadosAlteracao.id : null;
    formulario[MODALIDADE] = dadosAlteracao.id_modalidade ? dadosAlteracao.id_modalidade : null;
    formulario[REDE_TELEMEDICINA] = dadosAlteracao.rede_telemedicina ? dadosAlteracao.rede_telemedicina : null;
    formulario[DESCRICAO] = dadosAlteracao.descricao ? dadosAlteracao.descricao : '';
    formulario[SITUACAO] = dadosAlteracao.situacao;
    
    return formulario;
};

const formatarCadastro = (dados) => {
    const formData = new FormData();

    formData.append('id', dados.id);
    formData.append('id_modalidade', dados.modalidade.id);
    if (dados.rede_telemedicina) {
        formData.append('id_rede_telemedicina', dados.rede_telemedicina.id);
    }
    formData.append('descricao', dados.descricao.trim());
    formData.append('ativo', dados.situacao);

    return formData;
};

export default { inicializarValoresFormulario, formatarCadastro };