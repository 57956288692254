import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from '@material-ui/core/TableContainer';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {
    SttHeading,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttTableSortLabel,
    SttTableBody,
    SttCircularProgress,
    SttText,
    SttSeeButton,
    SttTablePagination,
    SttLoading
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '@security/acl';
import FormPesquisa from './form';
import translate from '@componentes/translate';
import {
    setEquipeAlteracao as setEquipeAlteracaoAction
} from '@redux/actions/cadastro/equipes';
import { useNavigate, useLocation } from "react-router-dom";
import { PERMISSOES } from '@src/common/Constants';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NOME: 'nome',
        CODIGO_INE: 'codigo_ine',
        UF: 'uf',
        CIDADE: 'cidade',
        CNES: 'cnes',
        INSTITUICAO: 'instituicao',
        ATIVA: 'ativa'
    }
}

const PesquisaEquipes = (props) => {
    const { user, strings, setEquipeAlteracao, setParametrosAlerta, setOpen } = props;
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [buscar, setBuscar] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);

    //Solicitação
    const [equipes, setEquipes] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [carregando, setCarregando] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setEquipes(dados.itens);
        setBuscar(false);
    };

    const callbackLimpar = () => { };

    const clickEditarEquipe = (equipe) => {
        setEquipeAlteracao({
            id: equipe.id,
            nome: equipe.nome,
            codigo_ine: equipe.codigo_ine,
            instituicao: {
                id: equipe.id_instituicao,
                nome: equipe.instituicao
            },
            ativa: equipe.ativa
        });

        navigate(`/cadastro/equipes`);
    };

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>

            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}
                                    >
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.CODIGO_INE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CODIGO_INE}
                                        direction={orderBy === ORDENACAO.CAMPOS.CODIGO_INE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CODIGO_INE)}
                                    >
                                        {strings.ine}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.CNES ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CNES}
                                        direction={orderBy === ORDENACAO.CAMPOS.CNES ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CNES)}
                                    >
                                        {strings.cnes}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.INSTITUICAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.INSTITUICAO)}
                                    >
                                        {strings.instituicao}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}
                                    >
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.ATIVA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.ATIVA}
                                        direction={orderBy === ORDENACAO.CAMPOS.ATIVA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ATIVA)}
                                    >
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="5%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.opcoes}
                                </SttTableCell>


                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Pesquisa de Equipes de Saúde da Família (ESF)</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={13}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    equipes.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={13}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        equipes.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.nome ? row.nome : ''}</SttTableCell>
                                                    <SttTableCell>{row.codigo_ine ? row.codigo_ine : ''}</SttTableCell>
                                                    <SttTableCell>{row.cnes ? row.cnes : ''}</SttTableCell>
                                                    <SttTableCell>{row.instituicao ? row.instituicao : ''}</SttTableCell>
                                                    <SttTableCell>{row.cidade ? `${row.cidade}/${row.estado}` : ''}</SttTableCell>
                                                    <SttTableCell>{row.ativa ? strings.ativa : strings.inativa}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.nome}:</b> {row.nome ? row.nome : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.ine}:</b> {row.codigo_ine ? row.codigo_ine : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cnes}:</b> {row.cnes ? row.cnes : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.instituicao}:</b> {row.instituicao ? row.instituicao : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cidade}:</b> {row.cidade ? `${row.cidade}/${row.estado}` : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.situacao}:</b> {row.ativa ? strings.ativa : strings.inativa}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    {
                                                        temPermissaoRBAC(user, PERMISSOES.CADASTRO_ESF) &&
                                                        <SttSeeButton
                                                            id={`btn-ver-solicitacao-${index}`}
                                                            onClick={() => {
                                                                clickEditarEquipe(row);
                                                            }}
                                                        />
                                                    }
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                equipes.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }

            <SttLoading
                open={carregando}
                text={strings.carregando}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEquipeAlteracao: id => dispatch(setEquipeAlteracaoAction(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaEquipes')(PesquisaEquipes));