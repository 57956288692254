import { URL_BASE, DCM4CHEE, URL_TOKEN, CLIENT_ID, CLIENT_SECRET, PACS } from './fieldNames';

export const estadoInicial = {
    [URL_BASE]: '',
    [URL_TOKEN]: '',
    [CLIENT_ID]: '',
    [CLIENT_SECRET]: '',
    [DCM4CHEE]: '',
    [PACS]: [],
};
