import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import FormPesquisa from './form';
import translate from '@componentes/translate';
import TableContainer from '@material-ui/core/TableContainer';
import { useNavigate } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttSeeButton,
    SttHidden,
    SttHeading
} from '@stt-componentes/core';
import { useLocation } from "react-router-dom";
import { setIdFuncionarioAlteracao as setIdFuncionarioAlteracaoAction } from '@redux/actions/cadastro/funcionario/index';
import { Util } from '@stt-utilitarios/core';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NOME: 'nome',
        USUARIO: 'usuario',
        NUMERO_CONSELHO: 'numero_conselho',
        CIDADE: 'municipio',
        CPF: 'cpf',
        RQE: 'numero_rqe',
    }
}

const Pesquisa = (props) => {
    const { strings, setIdFuncionarioAlteracao } = props;
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);

    //Solicitação
    const [funcionarios, setFuncionarios] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }

        if (location.state && location.state.idFuncionario) {
            handleVisualizarFuncionario(location.state.idFuncionario);
        }
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo, handleSubmit) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleVisualizarFuncionario = (id) => {
        if (id) {
            setIdFuncionarioAlteracao(id);
            navigate("/cadastro/funcionario");
        }
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setFuncionarios(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => { }

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>

            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="22%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}
                                    >
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="25%">
                                    {strings.usuariosNomeEmailVinculo}
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.CPF ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CPF}
                                        direction={orderBy === ORDENACAO.CAMPOS.CPF ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CPF)}
                                    >
                                        {strings.cpf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.NUMERO_CONSELHO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NUMERO_CONSELHO}
                                        direction={orderBy === ORDENACAO.CAMPOS.NUMERO_CONSELHO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO_CONSELHO)}
                                    >
                                        {strings.conselhoTrabalho}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.RQE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.RQE}
                                        direction={orderBy === ORDENACAO.CAMPOS.RQE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.RQE)}
                                    >
                                        {strings.numeroRqe}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="17%" sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}
                                    >
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="6%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Pesquisa de funcionários</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={7}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    funcionarios.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={7}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        funcionarios.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.nome}</SttTableCell>
                                                    <SttTableCell>{
                                                        row.descricaoUsuarios.map((descricaoUsuario, indice) => {
                                                            return <p key={indice}>{descricaoUsuario}</p>
                                                        })
                                                    }</SttTableCell>
                                                    <SttTableCell>{row.cpf ? Util.util.adicionarMascara(('00000000000' + row.cpf).slice(-11), '000.000.000-00') : ''}</SttTableCell>
                                                    <SttTableCell>{row.numero_conselho ? row.numero_conselho : ''}</SttTableCell>
                                                    <SttTableCell>{row.numero_rqe ? row.numero_rqe : ''}</SttTableCell>
                                                    <SttTableCell>{row.municipio ? `${row.municipio}/${row.estado}` : ''}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.nomeFuncionario}:</b> {row.nome}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.usuariosNomeEmailVinculo}:</b> {row.descricaoUsuarios}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cpf}:</b> {row.cpf ? Util.util.adicionarMascara(('00000000000' + row.cpf).slice(-11), '000.000.000-00') : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.conselhoTrabalho}:</b> {row.numero_conselho ? row.numero_conselho : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.numeroRqe}:</b> {row.numero_rqe ? row.numero_rqe : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cidadeUf}:</b> {row.municipio ? `${row.municipio}/${row.estado}` : ''}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        id={`btn-ver-funcionario-${index}`}
                                                        onClick={() => {
                                                            handleVisualizarFuncionario(row.id);
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                funcionarios.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }
        </>
    )
}

Pesquisa.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIdFuncionarioAlteracao: id => dispatch(setIdFuncionarioAlteracaoAction(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaFuncionario')(Pesquisa));