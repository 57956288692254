import * as yup from 'yup'

import identificacaoSchema from '@componentes/solicitacao/identificacao/form/validationSchema';
import complementoSchema from '@componentes/solicitacao/complemento/form/validationSchema';
import contatoSchema from '@componentes/solicitacao/contato/form/validationSchema';
import profissaoSchema from '@componentes/solicitacao/profissao/form/validationSchema';
import vinculoSchema from '@componentes/solicitacao/vinculo/form-instituicao/validationSchema';

export default (strings) => {
    const identificacao = identificacaoSchema(strings);
    const complemento = complementoSchema(strings);
    const contato = contatoSchema(strings, global.gConfig.email_cadastro_obrigatorio);
    const profissao = profissaoSchema(strings);
    const vinculo = vinculoSchema(strings);

    const schema = yup.object().shape({});

    return schema
        .concat(identificacao)
        .concat(complemento)
        .concat(contato)
        .concat(profissao)
        .concat(vinculo);
}