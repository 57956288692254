import React, { useState, memo, lazy, Suspense, useEffect, useRef, createRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { Formik } from 'formik';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import ReCAPTCHA from "react-google-recaptcha";
import validationSchema from './validationSchema';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttAlerta,
    SttHeading,
    SttCircularProgress
} from '@stt-componentes/core';
import Functions from '@common/Functions';
import Configs from '@constantes';
import { IDENTIFICAO } from '@componentes/solicitacao/identificacao/form/fieldNames';
import { COMPLEMENTO } from '@componentes/solicitacao/complemento/form/fieldNames';
import translate from "@componentes/translate";
import {
    setUsuarioPaciente as setUsuarioPacienteAction,
    setAccessToken as setAccessTokenAction
} from '../../../../redux/actions/paciente';
import TermoConsentimento from '../avisoPaciente/termoConsentimento';
import { CONTATO } from '@src/componentes/solicitacao/contato/form/fieldNames';

const Identificacao = lazy(() => import('@componentes/solicitacao/identificacao'));
const Complemento = lazy(() => import('@componentes/solicitacao/complemento'));
const Contato = lazy(() => import('@componentes/solicitacao/contato'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const Solicitacao = ({ cpf, strings, setPaciente, setAccessToken, layout }) => {
    const classes = useStyles();
    const schema = validationSchema(strings);
    const recaptchaRef = createRef();

    const [initialValues, setInitalValues] = useState(null);
    const [exibirTcle, setExibirTcle] = useState(false);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    useEffect(() => {
        setInitalValues(Functions.formatarParaSolicitacao(cpf, {}));
    }, []);

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const secaoIdentificacao = useRef(null);
    const secaoComplemento = useRef(null);
    const secaoContato = useRef(null);

    const scrollTela = (errors) => {
        if (!errors) {
            return;
        }

        const blocos = [
            { id: IDENTIFICAO, ref: secaoIdentificacao.current },
            { id: COMPLEMENTO, ref: secaoComplemento.current },
            { id: CONTATO, ref: secaoContato.current },
        ];

        const bloco = blocos.find(b => errors[b.id]);
        if (bloco) {
            setTimeout(() => {
                bloco.ref.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'start'
                });
            }, 200);
        }
    }

    const submitForm = (dados, { setSubmitting }) => {
        setMostrarAlerta(false);
        setSubmitting(true);

        dados.identificacao.cpf = dados.identificacao.cpf.replace(/\D/g, '');

        let tipo, titulo, mensagem;
        const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
        axios.post(`${ADM_API_BASE_URL}/solicitacao-cadastro-paciente`, dados)
            .then((response) => {
                const { data } = response;
                tipo = 'success';
                titulo = strings.sucesso;
                mensagem = strings.sucessoCadastroPaciente;
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                    setPaciente(data.data.paciente);
                    setAccessToken(data.data.access_token);
                    setExibirTcle(true);
                });
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => {
                        setMostrarAlerta(false);
                        setPaciente(data.data.paciente);
                        setAccessToken(data.data.access_token);
                        setExibirTcle(true);
                    }
                }]);

            })
            .catch(err => {
                const { response } = err;
                let msg = strings.erroSalvar;

                tipo = 'error';
                titulo = strings.erro;
                mensagem = msg;
                if (response && (response.status === HttpStatus.BAD_REQUEST)) {
                    const { data } = response;
                    let arrMensagem = [];
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                    titulo = data.message || titulo;
                    mensagem = msg;
                }

                setOnCloseAlerta(() => {
                    handleCloseAlerta();
                });
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => handleCloseAlerta()
                }]);
            })
            .finally(() => {
                setSubmitting(false);
                setTipoAlerta(tipo);
                setTituloAlerta(titulo);
                setMensagemAlerta(mensagem);
                setMostrarAlerta(true);
            });
    }

    return (
        initialValues
            ?
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={submitForm}
                    validateOnChange={false}
                >
                    {
                        ({
                            isSubmitting,
                            errors,
                            handleSubmit,
                            submitForm
                        }) => {
                            return (
                                <SttContainer>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttHeading variant="h2" color="primary" align="center" className={classes.header}>{strings.novoCadastroPaciente}</SttHeading>
                                        {/* Identificação */}
                                        <SttExpansionPanel
                                            title={strings.identificacao}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <div ref={secaoIdentificacao}></div>
                                                    <Identificacao />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        <SttExpansionPanel
                                            title={strings.complemento}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <div ref={secaoComplemento}></div>
                                                    <Complemento emailObrigatorio={false}
                                                        exibirTelefoneResidencial={true}
                                                        exibirCelularWhatsapp={true}
                                                    />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        <SttExpansionPanel
                                            title={strings.contato}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <div ref={secaoComplemento}></div>
                                                    <Contato emailObrigatorio={false}
                                                        exibirTelefoneResidencial={true}
                                                        exibirCelularWhatsapp={true}
                                                    />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            onChange={submitForm}
                                            size="invisible"
                                            sitekey={Configs.GOOGLE_RECAPTCHA_KEY} />
                                        <div className={classes.buttonWrapper}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                className={classes.button}
                                                color="primary"
                                                nomarginleft="true"
                                                disabled={isSubmitting}
                                                onClick={() => scrollTela(errors)}
                                            >
                                                {strings.salvar}
                                            </SttButton>
                                        </div>
                                    </form>
                                    <SttLoading
                                        open={isSubmitting}
                                        text={strings.salvandoSolicitacao}
                                    />
                                    <Alerta
                                        open={mostrarAlerta}
                                        title={tituloAlerta}
                                        message={mensagemAlerta}
                                        type={tipoAlerta}
                                        options={opcoesAlerta}
                                        onClose={onCloseAlerta}
                                    />
                                </SttContainer>
                            )
                        }
                    }
                </Formik>
                {
                    exibirTcle &&
                    <TermoConsentimento layout={layout} />
                }
            </>
            :
            <div className={classes.carregando}>
                <SttCircularProgress color="primary" />
            </div>
    );
};

const mapStateToProps = (state) => {
    return {
        cpf: state.solicitacaoCadastroReducer.aviso.cpf
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPaciente: (paciente) => dispatch(setUsuarioPacienteAction(paciente)),
        setAccessToken: (token) => dispatch(setAccessTokenAction(token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('SolicitacaoIndex')(Solicitacao));