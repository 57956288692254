import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttExpansionPanel,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttDivider,
    SttButton
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik,
    yupToFormErrors,
    validateYupSchema
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setMensagemAlteracao as setMensagemAlteracaoAction
} from '@redux/actions/cadastro/mensagens/index';

import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import UtilMensagens from '@util/mensagens';

const DadosGerais = lazy(() => import('@componentes/interno/cadastro/mensagens/dadosGerais'));
const DadosOutros = lazy(() => import('@componentes/interno/cadastro/mensagens/dadosOutros'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Carregando = memo((props) => {
    return (
        <SttLoading {...props} />
    )
});

const CadastroMensagens = (params) => {
    const { strings, user, mensagemAlteracao, setParametrosAlerta, setOpen, setMensagemAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();

    //Carregando
    const [carregando, setCarregando] = useState(false);

    const secaoDadosGerais = useRef(null);
    const secaoDadosOutros = useRef(null);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'dadosGerais': true,
        'dadosOutros': false,
    });

    useEffect(() => {
        if (mensagemAlteracao) {
            setInitialValues(UtilMensagens.inicializarValoresFormulario(mensagemAlteracao));
        } else {
            setInitialValues(UtilMensagens.inicializarValoresFormulario({}));
        }
        setCarregado(true);
    }, [mensagemAlteracao]);

    const redirectPesquisa = () => {
        setOpen(false);
        setMensagemAlteracao(null);
        navigate("/pesquisa/mensagens", { state: { pesquisar: true } });
    }

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }

        novoSecoesAbertas[secao] = estado;

        setSecoesAbertas(novoSecoesAbertas);
    }

    const verificarSecoesComErro = (validateForm) => {
        setCarregando(true);
        validateForm().then((retorno) => {
            let node = null;
            if (retorno['dadosGerais']) {
                abrirSecao('dadosGerais', true);
                node = secaoDadosGerais.current;
            } else if (retorno['dadosOutros']) {
                abrirSecao('dadosOutros', true);
                node = secaoDadosOutros.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 500);
            }
            setCarregando(false);
        });
    }

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);

        axios.post(`${ADM_API_BASE_URL}/mensagem`, dados, { headers: getHeaders() })
            .then((response) => {
                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisa
                        }
                    ],
                    onClose: redirectPesquisa
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        mensagem = data.message;
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        validate={(values) => {
                            return new Promise(resolve => {
                                validateYupSchema(values, schema, false).then(
                                    () => {
                                        resolve({});
                                    },
                                    (err) => {
                                        resolve(yupToFormErrors(err));
                                    }
                                );
                            });
                        }}
                        onSubmit={(dados, { setSubmitting }) => {
                            let mensagem = strings.confirmacaoCadastroMensagens;
                            if (mensagemAlteracao) {
                                mensagem = strings.confirmacaoAlteracaoMensagens;
                            }

                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            enviarFormulario(dados, setSubmitting);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                validateForm
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setMensagemAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/mensagens", { state: { pesquisar: true } });
                                                                }

                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{!mensagemAlteracao ? strings.tituloPagina : strings.tituloPaginaAlteracao}</SttHeading>
                                            {/* Dados gerais */}
                                            <SttExpansionPanel
                                                title={strings.dadosGerais}
                                                opened={secoesAbertas['dadosGerais']}
                                                callback={estadoInterno => abrirSecao('dadosGerais', estadoInterno)}
                                                classegriditem={classes.expansionPanel}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosGerais
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.outrasInformacoes}
                                                opened={secoesAbertas['dadosOutros']}
                                                callback={estadoInterno => abrirSecao('dadosOutros', estadoInterno)}
                                                classegriditem={classes.expansionPanel}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosOutros
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />

                                            <div className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => verificarSecoesComErro(validateForm)}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
            }

            <Carregando
                open={carregando}
                text={strings.carregando}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        mensagemAlteracao: state.cadastroMensagensReducer.mensagemAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMensagemAlteracao: id => dispatch(setMensagemAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroMensagens')(CadastroMensagens));