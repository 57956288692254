import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import translate from '@componentes/translate';
import { getHeaders } from '@src/request/index';
import HttpStatus from 'http-status-codes';
import {
    SttHeading,
    SttContainer,
    SttGrid,
    SttAutocomplete,
    SttButton,
    SttDoubleList,
    SttLoading,
    SttAlerta,
    SttExpansionPanel,
    SttDivider
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { initialValuesForm } from './initialValues';
import validationSchema from './validationSchema';
import { Formik, Field } from 'formik';
import estruturarFiltros from '@src/util/cidade-refrencia/index';
import {
    UF,
    MUNICIPIO,
    MODALIDADE,
    CIDADES,
    ESTADO
} from './fieldNames';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const CadastroCidadeRef = (props) => {
    const { strings } = props;
    const classes = useStyles();

    // Municípios a serem usados no dropdown da pesquisa
    const [municipios, setMunicipios] = useState([]);

    // Estados da pesquisa de cidades referência
    const [uf, setUf] = useState([]);
    const [estados, setEstados] = useState([]);

    // Modalidades da pesquisa de cidades referência
    const [modalidades, setModalidades] = useState([]);

    // Cidades de referência (lado esquerdo do SttDoubleList)
    const [cidades, setCidades] = useState([]);

    //Alerta 
    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }
    
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);

    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const schema = validationSchema(strings);

    useEffect(() => {

        axios
            .get(`${UTILITARIOS_API_BASE_URL}/modalidade`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setModalidades(itens);
                }
            })
            .catch(err => console.log(err));

    }, []);

    useEffect(() => {
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/localizacao/estado`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setEstados(itens);
                    setUf(itens);
                }
            })
            .catch(err => console.log(err));
    }, []);

    //Função que procura o municipio ao preencher o estado
    const handleChangeUf = (uf, referencia) => {
        if (referencia) {
            setMunicipios([]);
            setCidades([]); 
        } else {
            setCidades([]); 
        }
        if (uf) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        if (referencia) {
                            setMunicipios(itens);
                        } else {
                            setCidades(itens);
                        }
                    }
                })
                .catch(err => console.log(err));
        };
    }

    //Função que procura as cidades referencias dependendo do parametro
    const carregarMunicipiosReferenciados = (modalidade, municipio, callback) => {
        const dados = { modalidade, municipio };
        if (dados.modalidade && dados.municipio) {
            let params = estruturarFiltros(dados);

            axios
                .get(`${ADM_API_BASE_URL}/cidade-referencia`, { params: params, headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const itens = response.data.data;
                        callback(itens);
                    }
                })
                .catch(err => {
                    console.log('Erro na pesquisa de cidades referência: ', err);
                });
        }
    }

    const enviarForm = (dados, setSubmitting, callback) => {
        setSubmitting(true);
        const params = estruturarFiltros(dados);

        axios
            .post(`${ADM_API_BASE_URL}/cidade-referencia`,  params, { headers: getHeaders() })
            .then((response) => {
                setSubmitting(false);
                callback();
                setMunicipios([]);
                setCidades([]);
                const { data } = response;
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(data.message);
                setTipoAlerta('success');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                });
            })
            .catch(err => {
                const { response } = err;
                let msg = strings.erroDesconhecido;
                let titulo = strings.erro;
                if (response) {
                    const dadosResposta = response.data;
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        let arrMensagem = [];
                        dadosResposta.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        titulo = dadosResposta.message;
                    } else {
                        msg = dadosResposta.message;
                    }
                }
                setTituloAlerta(titulo);
                setMensagemAlerta(msg);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
            })
            .finally(() => {
                setMostrarAlerta(true);
                setSubmitting(false);
            });
    }

    return (
        <>
            <Formik
                initialValues={initialValuesForm}
                validationSchema={schema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(false);
                    setTituloAlerta(strings.atencao);
                    setMensagemAlerta(strings.confirmar);
                    setTipoAlerta('alert');
                    setOpcoesAlerta([
                        {
                            title: strings.sim,
                            onClick: () => enviarForm(data, setSubmitting, resetForm)
                        },
                        {
                            title: strings.nao,
                            onClick: () => setMostrarAlerta(false)
                        }
                    ]);
                    setOnCloseAlerta(() => () => setMostrarAlerta(false));
                    setMostrarAlerta(true);
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        touched,
                        setFieldValue,
                        handleSubmit,
                        setStatus,
                        status,
                        isValid,
                        resetForm
                    }) => {
                        return (
                            <SttContainer>
                                <SttGrid container spacing={1}>
                                    <SttGrid item xs={12} >
                                        <SttHeading className={classes.heading} variant="h1" color="primary">{strings.cadastroCidadeRef}</SttHeading>
                                    </SttGrid>
                                </SttGrid>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttExpansionPanel
                                        title={strings.cidadeReferencia}
                                        opened
                                        children={
                                            <>
                                                <SttGrid container spacing={2}>
                                                    <SttGrid item xs={12} md={6}>
                                                        <Field name={MODALIDADE}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue },
                                                                meta
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.modalidade,
                                                                        required: true,
                                                                        error: meta.touched && meta.error ? meta.error : undefined
                                                                    }}
                                                                    fullWidth={true}
                                                                    getOptionLabel={option => option && option.descricao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={modalidades}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(MODALIDADE, item || null);
                                                                        setFieldValue(UF, null);
                                                                        setFieldValue(MUNICIPIO, null);
                                                                        setFieldValue(ESTADO, null);
                                                                        setFieldValue(CIDADES, []);
                                                                        setMunicipios([]);
                                                                        setCidades([]);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                    <SttGrid item xs={12} sm={4} md={2} >
                                                        <Field name = {UF}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue },
                                                                meta
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.uf,
                                                                        required: true,
                                                                        error: meta.touched && meta.error ? meta.error : undefined
                                                                    }}
                                                                    getOptionLabel={option => option && option.sigla || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={uf}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(UF, item || null);
                                                                        setFieldValue(MUNICIPIO, null);
                                                                        setFieldValue(ESTADO, null);
                                                                        setFieldValue(CIDADES, []);
                                                                        handleChangeUf(item, true);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                    <SttGrid item xs={12} sm={8} md={4} >
                                                        <Field name={MUNICIPIO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue },
                                                                meta
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.municipio,
                                                                        required: true,
                                                                        error: meta.touched && meta.error ? meta.error : undefined
                                                                    }}
                                                                    getOptionLabel={option => option && option.nome || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={municipios}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(MUNICIPIO, item || null);
                                                                        setFieldValue(CIDADES, []);
                                                                        carregarMunicipiosReferenciados(values[MODALIDADE], item, (municipios) => {
                                                                            setFieldValue(CIDADES, municipios);
                                                                        });
                                                                        setFieldValue(ESTADO, null);
                                                                        setCidades([]);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                </SttGrid>  
                                            </>  
                                    }
                                    /> 
                                    <SttDivider />
                                    <SttExpansionPanel
                                        title={strings.cidadesReferencias}
                                        opened
                                        children={
                                            <>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12} sm={4} md={2} >
                                                    <Field name={ESTADO}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.uf
                                                                }}
                                                                getOptionLabel={option => option && option.sigla || ''}
                                                                getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                options={estados}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(ESTADO, item || null);
                                                                    handleChangeUf(item, false);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            </SttGrid>
                                            <Field name={CIDADES}>
                                                {({
                                                    field: { value }
                                                }) => (
                                                    <SttDoubleList
                                                        allItens={cidades}
                                                        selectedItens={value}
                                                        onChange={(right) => {
                                                            setFieldValue(CIDADES, right);
                                                        }}
                                                        labelLeft={strings.municipios}
                                                        labelRight={strings.municipiosAdicionados}
                                                        getItemText={(item) => item?.nome + ' - ' + item?.sigla_estado}
                                                        getItemIdentifier={(item) => item?.id}
                                                        reset={status === 'RESET'}
                                                        callbackReset={() => setStatus('')}
                                                    />
                                                )}
                                            </Field>
                                            </>
                                        }
                                    />

                                        
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12} >
                                            <SttLoading
                                                open={isSubmitting}
                                                text={strings.carregando}
                                            />
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                disabled={isSubmitting || (touched && !isValid)}
                                            >
                                                {strings.salvar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => {
                                                    resetForm();
                                                    setStatus('RESET');
                                                    setMunicipios([]);
                                                    setCidades([]);
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                    
                                </form>
                            </SttContainer>                            
                        )
                    }
                }
            </Formik>
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </>
    )
};

export default connect(null, null)(translate('CadastroCidadeReferencia')(CadastroCidadeRef));
