/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import { validateBr } from 'js-brasil';
import {
    DADOS_GERAIS,
    CPF,
    NOME,
    DATA_NASCIMENTO,
    GENERO,
    UF,
    MUNICIPIO
} from './fieldNames';
import moment from 'moment';

export default (strings) => {
    return yup.object().shape({
        [DADOS_GERAIS]: yup.object().shape({
            [CPF]: yup.string()
                .nullable()
                .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                    cpf = cpf || '';

                    if (cpf.length === 0) {
                        return true;
                    }
                    return validateBr.cpf(cpf);
                })
                .required(strings.campoObrigatorio),
            [NOME]: yup
                .string()
                .trim()
                .min(1)
                .max(100)
                .matches(/(\w.+\s).+/i, strings.informeNomeCompleto)
                .nullable()
                .required(strings.campoObrigatorio),
            [DATA_NASCIMENTO]: yup.string()
                .nullable()
                .when('$temPerfilAdministradorLocal', {
                    is: (val) => !val,
                    then: yup.string()
                        .nullable()
                        .required(strings.campoObrigatorio)
                        .test(
                            "maiorQue18Anos",
                            strings.idadeMaiorDezoito,
                            value => {
                                if (!value) {
                                    return true;
                                }
                                return moment().diff(moment(value), 'years') >= 18;
                            }
                        )
                })
            ,
            [GENERO]: yup.object().shape({
                id: yup.number(),
                descricao: yup.string(),
                sigla: yup.string()
            }).nullable().required(strings.campoObrigatorio),
            [UF]: yup.object().shape({
                id: yup.number(),
                nome: yup.string(),
                sigla: yup.string(),
                id_pais: yup.number(),
                codigo_ibge: yup.string()
            }).nullable().required(strings.campoObrigatorio),
            [MUNICIPIO]: yup.object().shape({
                id: yup.number(),
                nome: yup.string(),
                id_estado: yup.number(),
                codigo_ibge: yup.string(),
                nome_estado: yup.string(),
                latitude: yup.number(),
                longitude: yup.number()
            }).nullable().required(strings.campoObrigatorio)
        }).required()
    });
}
