import TYPES from '@redux/types/pesquisa/pacs';

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SETAR_PARAMETROS_PESQUISA,
        payload: { parametros },
    };
};

export const limparParametrosPesquisa = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA,
    };
};

export const setarResultadoPesquisa = (resultado) => {
    return {
        type: TYPES.SETAR_RESULTADO_PESQUISA,
        payload: resultado,
    };
};
