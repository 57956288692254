const SOLICITACAO_CADASTRO = {
    SITUACAO: {
        AVISO_MENSAGEM: 1,
        BUSCA_CPF: 2,
        SELECAO_USUARIO_NOVA_SENHA: 3,
        SOLICITACAO_CADASTRO: 4
    },
    REDUCER_TYPE: {
        MARCAR_ENTENDI_AVISO: 'MARCAR_ENTENDI_AVISO',
        CONFIRMAR_ENTENDI_AVISO: 'CONFIRMAR_ENTENDI_AVISO',
        SELECIONAR_USUARIO_SOLICITACAO_AVISO: 'SELECIONAR_USUARIO_SOLICITACAO_AVISO',
        ABRIR_SOLICITACAO_CADASTRO: 'ABRIR_SOLICITACAO_CADASTRO',
        FECHAR_MODAL_AVISO_USUARIO: 'FECHAR_MODAL_AVISO_USUARIO',
        ALTERACAO_CAMPO_FORMULARIO: 'ALTERACAO_CAMPO_FORMULARIO'
    },
    CODIGO_MENSAGEM_AVISO: {
        ERRO_DESCONHECIDO: -1,
        SEM_SOLICITACAO: 0,
        CPF_INVALIDO: 1,
        FUNCIONARIO_DUPLICADO: 2,
        MULTIPLOS_USUARIOS: 3,
        UNICO_USUARIO: 4,
        SOLICITACAO_PENDENTE: 5,
        FUNCIONARIO_SEM_USUARIO: 6,
        SOLICITACAO_REJEITADA: 7,
        EQUIPE_SAUDE_OBRIGATORIO: 8,
        UMA_EQUIPE_INSTITUICAO: 9,
    },

    INITIAL_STATE_ALERTA: {
        titulo: '',
        mensagem: '',
        tipo: 'alert',
        visivel: false,
        botoes: []
    }
};

export default SOLICITACAO_CADASTRO;