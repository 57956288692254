import * as yup from 'yup'

import identificacaoSchema from '@componentes/solicitacao/identificacao/form/validationSchema';
import complementoSchema from '@componentes/solicitacao/complemento/form/validationSchema';
import contatoSchema from '@componentes/solicitacao/contato/form/validationSchema';

export default (strings) => {
    const identificacao = identificacaoSchema(strings);
    const complemento = complementoSchema(strings, false);
    const contato = contatoSchema(strings, false);

    const schema = yup.object().shape({});

    return schema
        .concat(identificacao)
        .concat(complemento)
        .concat(contato);
}