import React, { useEffect, useState } from 'react';
import FormPesquisa from './form';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttHeading,
    SttSeeButton
} from '@stt-componentes/core';
import {
    setMateriaisAlteracao as setMateriaisAlteracaoAction
} from '@redux/actions/cadastro/materiais-suporte';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from "@redux/actions/alerta";

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NOME: 'nome',
        DESCRICAO: 'descricao',
        DATA_CRIACAO: 'data',
        SITUACAO: 'ativo'
    }
}
const MateriaisSuporte = (props)=> {
    const { user, strings, setMateriaisAlteracao, setParametrosAlerta, setOpen  } = props;
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);

    //Solicitação
    const [materiais, setMateriais] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {

        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setMateriais(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
        setMateriais([]);
        setTotalRegistros(0);
    }

    const clickEditarMateriaisSuporte = (dados) => {
        setMateriaisAlteracao(dados);
        navigate('/cadastro/materiais-apoio', { state: { fromPesquisa: true } });
    };

    function formatarData(dataBanco) {
        const data = new Date(dataBanco);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear().toString();
        return `${dia}/${mes}/${ano}`;
    }

    
    return (
        <>
        <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
        </SttHeading>
        <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
        />
                    <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}
                                    >
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="35%" sortDirection={orderBy === ORDENACAO.CAMPOS.DESCRICAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DESCRICAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DESCRICAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DESCRICAO)}
                                    >
                                        {strings.descricao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_CRIACAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA_CRIACAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA_CRIACAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_CRIACAO)}
                                    >
                                        {strings.dataCriacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}
                                    >
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                    {
                        
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={5}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    materiais.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={5}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        materiais.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.nome}</SttTableCell>
                                                    <SttTableCell>{row.descricao}</SttTableCell>
                                                    <SttTableCell>{formatarData(row.data)}</SttTableCell>
                                                    <SttTableCell>{row.ativo ? strings.ativo : strings.inativo}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.nome}:</b> {row.nome}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.descricao}:</b> {row.descricao}
                                                        </SttText>
                                                        <SttText size="small">
                                                            
                                                            <b>{strings.data}:</b> {formatarData(row.data)}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.situacao}:</b> {row.ativo ? strings.ativo : strings.inativo}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        id={`btn-ver-rede-${index}`}
                                                        onClick={() => {
                                                            clickEditarMateriaisSuporte(row);
                                                           
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                            
                                            
                                        ))
                                        
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>

            {
                materiais.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }

    </>
    )

}

MateriaisSuporte.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setMateriaisAlteracao: material => dispatch(setMateriaisAlteracaoAction(material)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};


export default connect(mapStateToProps,mapDispatchToProps)(translate('PesquisaMateriaisSuporte')(MateriaisSuporte));