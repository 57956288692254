import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '@src/request';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import translate from '@componentes/translate';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction,
} from '@redux/actions/pesquisa/funcionario';
import { SttGrid, SttButton, SttInput, SttNumberInput, SttMaskedInput, SttAutocomplete } from '@stt-componentes/core';

import { NOME_LOGIN, CPF, NUMERO_CONSELHO_TRABALHO, UF, CIDADE, EMAIL, SITUACAO, INSTITUICAO, RQE } from './fieldNames';

import { CONSTANTES_PERFIS, PERMISSOES } from '@common/Constants';

import { temPerfil, temPermissaoRBAC } from '@security/acl';
import validationSchema from './validationSchema';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '@redux/actions/alerta';

import { setInstituicoesAdminLocal as setInstituicoesAdminLocalAction } from '@redux/actions/cadastro/funcionario';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(3),
    },
}));

const situacoesUsuario = [
    { sigla: 'A', descricao: 'Ativo ' },
    { sigla: 'I', descricao: 'Inativo ' },
];

const FormPesquisa = (props) => {
    const {
        user,
        setParametrosPesquisa,
        limparParametrosPesquisa,
        setParametrosAlerta,
        setOpen,
        parametrosPesquisa,
        callbackAndamento,
        callbackLimpar,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
        strings,
        setInstituicoesAdminLocal,
        instituicoesAdminLocal,
    } = props;

    const classes = useStyles();
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const formRef = useRef();
    const schema = validationSchema(strings);
    const navigate = useNavigate();

    //Filtros
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [estadosAdminEstadual, setEstadosAdminEstadual] = useState([]);

    useEffect(() => {
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/localizacao/estado`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    if (temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_ESTADUAL)) {
                        let estados = [];
                        user.perfisRBAC.forEach((perfilRBAC) => {
                            if (perfilRBAC.identificador === CONSTANTES_PERFIS.ADMINISTRADOR_ESTADUAL) {
                                estados = perfilRBAC.estados;
                            }
                        });
                        setEstados(estados);
                        setEstadosAdminEstadual(estados.map((estado) => estado.id));
                    } else {
                        setEstados(itens);
                    }

                    if (buscar && formRef.current) {
                        formRef.current.handleSubmit();
                    }
                }
            })
            .catch((err) => console.log(err));

        if (temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL)) {
            axios
                .get(`${ADM_API_BASE_URL}/pesquisar-instituicoes-admin-local/${user.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        setInstituicoesAdminLocal(response.data.data);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [buscar]);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = page * count;
        let outrosFiltros = false;

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados.nomeLogin) {
            filtros.nomeLogin = dados.nomeLogin.trim();
            outrosFiltros = true;
        }

        if (dados.cpf) {
            filtros.cpf = ('00000000000' + dados.cpf.replace(/[\. ,:-]+/g, '')).slice(-11);
        }

        if (dados.uf) {
            filtros.uf = dados.uf.id;
            outrosFiltros = true;
        }

        if (dados.cidade) {
            filtros.cidade = dados.cidade.id;
            outrosFiltros = true;
        }

        if (dados.email) {
            filtros.email = dados.email;
            outrosFiltros = true;
        }

        if (dados.situacaoCadastro) {
            filtros['situacao-cadastro'] = dados.situacaoCadastro.sigla;
            outrosFiltros = true;
        }

        if (dados.situacao) {
            filtros.situacao = dados.situacao.sigla;
            outrosFiltros = true;
        }

        if (dados.numeroConselhoTrabalho) {
            filtros.numeroConselhoTrabalho = dados.numeroConselhoTrabalho;
            outrosFiltros = true;
        }

        if ((dados.rqe || '').trim()) {
            filtros.rqe = dados.rqe.trim();
            outrosFiltros = true;
        }

        if (dados.instituicao) {
            filtros.instituicaoAdministradorLocal = dados.instituicao.id;
        }

        if (estadosAdminEstadual.length) {
            filtros.estadosAdminEstadual = JSON.stringify(estadosAdminEstadual);
        }

        filtros.outrosFiltros = outrosFiltros;

        setParametrosPesquisa(dados);
        return filtros;
    };

    const handleChangeUf = (uf, callbackSetCidades) => {
        if (uf) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        callbackSetCidades(itens);
                    } else {
                        callbackSetCidades([]);
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const tratarFuncionarios = (funcionarios) => {
        funcionarios.forEach((funcionario) => {
            //Tratamento dos usuários daquele funcionário
            if (funcionario.usuarios) {
                let descricaoUsuarios = [];
                funcionario.usuarios.forEach((usuario) => {
                    descricaoUsuarios.push(`Usuário: ${usuario.login}; E-mail: ${usuario.email}`);
                });
                funcionario.descricaoUsuarios = descricaoUsuarios;
            }

            //Tratamento da cidade e estado daquele funcionário
            if (funcionario.nome_cidade && funcionario.sigla_estado) {
                funcionario.cidadeEstadoFormatada = `${funcionario.nome_cidade}/${funcionario.sigla_estado}`;
            }
        });
        return funcionarios;
    };

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={parametrosPesquisa}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/funcionario`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                dados.itens = tratarFuncionarios(dados.itens);
                                callbackBusca(dados);
                            })
                            .catch((err) => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: [],
                                });

                                //TODO: PALEATIVO, DEVE-SE MUDAR AQUI O TRATAMENTO DA REQUISIÇÃO PARA NÃO VIR MAIS 404 QUANDO NÃO ACHAR NENHUM FUNCIONÁRIO.
                                if (!params.outrosFiltros && params.cpf && params.cpf !== '' && temPermissaoRBAC(user, PERMISSOES.CADASTRO_FUNCIONARIO)) {
                                    const parametrosAlerta = {
                                        titulo: strings.atencao,
                                        mensagem: strings.pesquisaFuncionarioNovoCadastro,
                                        tipo: 'alert',
                                        open: true,
                                        opcoes: [
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    navigate('/cadastro/funcionario', { state: { cpf: params.cpf } });
                                                    setOpen(false);
                                                },
                                            },
                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    setOpen(false);
                                                },
                                            },
                                        ],
                                    };

                                    setParametrosAlerta(parametrosAlerta);
                                }
                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ values, isSubmitting, handleSubmit, resetForm, isValid }) => {
                        return (
                            <>
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <SttGrid container spacing={1}>
                                        <SttGrid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                            <FastField name={NOME_LOGIN}>
                                                {({ field, meta }) => (
                                                    <SttInput
                                                        {...field}
                                                        label={strings.nomeLogin}
                                                        inputProps={{ style: { textTransform: 'uppercase' } }}
                                                        value={field.value ? field.value : ''}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={6} md={4} lg={2} xl={1}>
                                            <FastField name={CPF}>
                                                {({ field, meta }) => (
                                                    <SttMaskedInput
                                                        {...field}
                                                        mask="cpf"
                                                        label={strings.cpf}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                            <FastField name={EMAIL}>
                                                {({ field, meta }) => (
                                                    <SttInput
                                                        inputProps={{
                                                            maxLength: 100,
                                                            style: { textTransform: 'uppercase' },
                                                        }}
                                                        {...field}
                                                        label={strings.email}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={6} md={3} lg={2} xl={1}>
                                            <FastField name={NUMERO_CONSELHO_TRABALHO}>
                                                {({ field, meta }) => (
                                                    <SttNumberInput
                                                        {...field}
                                                        label={strings.conselhoTrabalho}
                                                        inputProps={{ style: { textTransform: 'uppercase' } }}
                                                        value={field.value ? field.value : ''}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={6} md={3} lg={2} xl={1}>
                                            <FastField name={RQE}>
                                                {({ field }) => (
                                                    <SttInput
                                                        inputProps={{
                                                            maxLength: 20,
                                                            style: { textTransform: 'uppercase' },
                                                        }}
                                                        {...field}
                                                        label={strings.numeroRqe}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={6} md={2} lg={1}>
                                            <Field name={UF}>
                                                {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                    <SttAutocomplete
                                                        inputprops={{
                                                            name: name,
                                                            label: strings.uf,
                                                        }}
                                                        getOptionLabel={(option) => (option && option.sigla) || ''}
                                                        getOptionSelected={(option, valueOptionSelected) =>
                                                            option && valueOptionSelected && option.id === valueOptionSelected.id
                                                        }
                                                        options={estados}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onChange={(e, item) => {
                                                            setFieldValue(UF, item || null);
                                                            setFieldValue(CIDADE, null);
                                                            handleChangeUf(item, setCidades);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={6} md={5} lg={4} xl={2}>
                                            <Field name={CIDADE}>
                                                {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                    <SttAutocomplete
                                                        inputprops={{
                                                            name: name,
                                                            label: strings.municipio,
                                                        }}
                                                        disabled={!values.uf}
                                                        getOptionLabel={(option) => (option && option.nome) || ''}
                                                        getOptionSelected={(option, valueOptionSelected) =>
                                                            option && valueOptionSelected && option.id === valueOptionSelected.id
                                                        }
                                                        options={cidades}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onChange={(e, item) => {
                                                            setFieldValue(CIDADE, item || null);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={6} md={3} lg={2} xl={1}>
                                            <Field name={SITUACAO}>
                                                {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                    <SttAutocomplete
                                                        inputprops={{
                                                            name: name,
                                                            label: strings.situacaoUsuario,
                                                        }}
                                                        getOptionLabel={(option) => (option && option.descricao) || ''}
                                                        getOptionSelected={(option, valueOptionSelected) =>
                                                            option && valueOptionSelected && option.sigla === valueOptionSelected.sigla
                                                        }
                                                        options={situacoesUsuario}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onChange={(e, item) => {
                                                            setFieldValue(SITUACAO, item || null);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>

                                        {temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL) && (
                                            <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                                <Field name={INSTITUICAO}>
                                                    {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: name,
                                                                label: strings.instituicao,
                                                            }}
                                                            getOptionLabel={(option) => (option && option.nome) || ''}
                                                            getOptionSelected={(option, valueOptionSelected) =>
                                                                option && valueOptionSelected && option.id === valueOptionSelected.id
                                                            }
                                                            options={instituicoesAdminLocal}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            onChange={(e, item) => {
                                                                setFieldValue(INSTITUICAO, item || null);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                        )}
                                    </SttGrid>
                                    <SttGrid container spacing={3} style={{ marginTop: '4px' }}>
                                        <SttGrid item xs={12} className={classes.buttonWrapper}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    callbackLimpar();
                                                }}
                                                disabled={isSubmitting || !isValid}
                                                nomarginleft="true"
                                            >
                                                {strings.pesquisar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    limparParametrosPesquisa();
                                                    callbackLimpar();
                                                    resetForm({
                                                        values: estadoInicial,
                                                    });
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            </>
                        );
                    }}
                </Formik>
            }
        </>
    );
};

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaFuncionarioReducer.parametrosPesquisa,
        instituicoesAdminLocal: state.cadastroFuncionarioReducer.instituicoesAdminLocal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosPesquisa: (parametros) => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
        setInstituicoesAdminLocal: (instituicoes) => dispatch(setInstituicoesAdminLocalAction(instituicoes)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('FormPesquisa')(FormPesquisa));
