import { makeStyles } from '@material-ui/core/styles';
import ComponentePesquisa from '@componentes/interno/pesquisa/pacs';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(3),
    },
}));

const Pesquisa = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <ComponentePesquisa />
        </div>
    );
};
export default Pesquisa;
