export const ID = 'id';
export const NOME = 'nome';
export const CNES = 'cnes';
export const CNPJ = 'cnpj';
export const LOGRADOURO = 'logradouro';
export const BAIRRO = 'bairro';
export const CIDADE = 'cidade';
export const CEP = 'cep';
export const UF = 'uf';
export const TIPO_ESTABELECIMENTO = 'tipo_estabelecimento';
export const SETORES_INSTITUICAO = 'setores_instituicao';
export const DESCRICAO = 'descricao';
export const CODINOME = 'codinome';
export const DADOS_GERAIS = 'dados_gerais';
export const LOGOTIPO = 'logotipo';
export const LOGOTIPO_EXCLUIDO = 'logotipo_excluido';
export const URL_LOGOTIPO = 'url_logotipo';
export const NOME_ARQUIVO_LOGOTIPO = 'nome_arquivo_logotipo'
export const SETORES_ASSOCIADOS = 'setores_associados';
export const SETORES_EXCLUIDOS = 'setores_excluidos';
export const CONSULTORIO_VIRTUAL_HABILITADO = 'consultorio_virtual_habilitado';