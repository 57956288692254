/* eslint-disable no-unused-expressions */
import React, { memo, useState, useEffect } from "react";
import {
    SttTranslate,
    SttModal,
    SttButton,
    SttExpansionPanel,
    SttLoading,
    SttTextItem,
    SttDivider
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '@src/request';
import axios from 'axios';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from "@redux/actions/alerta";
import HttpStatus from 'http-status-codes';
import { connect } from 'react-redux';
import ModalRejeitarAcessoInstituicao from './rejeitar'

const useStyles = makeStyles(theme => ({
    instituicoes: {
        whiteSpace: 'pre-line',
        display: 'inline-block',
        verticalAlign: 'top'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const ModalTriagemAcessoInstituicao = ({ open, setModalOpen, visualizarAcesso, strings, setParametrosAlerta, setAlertaOpen, callback, callbackFechar }) => {
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const [detalhesInstituicao, setDetalhesInstituicao] = useState(false);
    const classes = useStyles();

    const [salvando, setSalvando] = useState(false);
    const [openRejeitar, setOpenRejeitar] = useState(false);

    useEffect(() => {
        if (visualizarAcesso) {
            let id = {
                id_usuario: visualizarAcesso.id_usuario,
                id_instituicao: visualizarAcesso.id_instituicao_solicitada,
                id_funcionario: visualizarAcesso.id_funcionario,
                data_hora_solicitacao: visualizarAcesso.data_hora_solicitacao
            }

            axios
                .get(`${ADM_API_BASE_URL}/acesso-instituicao/detalhes`, { params: id, headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const dados = response.data.data[0];
                        setDetalhesInstituicao(dados);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [visualizarAcesso]);

    const preAprovarAcesso = () => {
        const parametrosAlerta = {
            titulo: strings.atencao,
            mensagem: strings.confirmarAprovar,
            tipo: 'confirmation',
            open: true,
            opcoes: [
                {
                    title: strings.sim,
                    onClick: aprovar
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        setAlertaOpen(false);
                    }
                }
            ],
            onClose: () => {
                setAlertaOpen(false);
            }
        };

        setParametrosAlerta(parametrosAlerta);
    }

    const aprovar = () => {
        const dados = {
            id_usuario: visualizarAcesso.id_usuario,
            id_instituicao: visualizarAcesso.id_instituicao_solicitada,
            id_funcionario: visualizarAcesso.id_funcionario,
            data_hora_solicitacao: visualizarAcesso.data_hora_solicitacao
        }

        setSalvando(true);
        axios.post(`${ADM_API_BASE_URL}/acesso-instituicao/aprovar`, dados, { headers: getHeaders() })
            .then((response) => {
                setSalvando(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setModalOpen(false);
                                setAlertaOpen(false);
                                callback();
                            }
                        }
                    ],
                    onClose: () => {
                        setModalOpen(false);
                        setAlertaOpen(false);
                        callback();
                    }
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSalvando(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setAlertaOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    }

    return (
        <>
            <SttModal
                title={strings.tituloPagina}
                open={open}
                maxWidth="lg"
                outModalClose={callbackFechar}
                iconClose={callbackFechar}
                fullWidth={true}
                children={
                    <>
                        <SttExpansionPanel
                            title={strings.dadosSolicitacao}
                            children={
                                <>
                                    {
                                        <div >
                                            {
                                                detalhesInstituicao && detalhesInstituicao.funcionario_solicitante &&
                                                <SttTextItem title={strings.nomeSolicitante} content={detalhesInstituicao.funcionario_solicitante} />
                                            }
                                            {
                                                detalhesInstituicao && detalhesInstituicao.conselho_trabalho && detalhesInstituicao.conselho_trabalho.length > 0 &&
                                                <SttTextItem title={strings.conselhoTrabalho} content={detalhesInstituicao.conselho_trabalho.map(item => `${item.numero} ${item.descricao}/${item.sigla}`).join(', ')} />
                                            }
                                            {
                                                detalhesInstituicao && detalhesInstituicao.rqe && detalhesInstituicao.rqe.length > 0 &&
                                                <SttTextItem title={strings.rqe} content={detalhesInstituicao.rqe.map(item => item.numero).join(', ')} />
                                            }
                                            {
                                                detalhesInstituicao && detalhesInstituicao.instituicoes_cadastradas && detalhesInstituicao.instituicoes_cadastradas.length > 0 &&
                                                <SttTextItem
                                                    title={strings.instituicoesCadastradas}
                                                    content={
                                                        <div className={classes.instituicoes} >
                                                            {detalhesInstituicao.instituicoes_cadastradas.map((item) => `${item.cnes} - ${item.nome} - ${item.cidade}/${item.uf}`).join('\n')}
                                                        </div>
                                                    }
                                                />
                                            }
                                            <SttTextItem title={strings.instituicaoSolicitada} content={`${visualizarAcesso.cnes} - ${visualizarAcesso.instituicao} - ${visualizarAcesso.cidade_instituicao}/${visualizarAcesso.uf_instituicao}`} />
                                            <SttTextItem title={strings.datHoraSolicitacao} content={visualizarAcesso.data_hora_solicitacao_formatado} />
                                            {
                                                detalhesInstituicao && detalhesInstituicao.motivo_solicitacao &&
                                                <SttTextItem title={strings.motivoSolicitacao} content={detalhesInstituicao.motivo_solicitacao} />
                                            }
                                            <SttTextItem title={strings.situacaoSolicitacao} content={visualizarAcesso.avaliacao === 'P' ? strings.pendente : visualizarAcesso.avaliacao === 'A' ? strings.aprovado : strings.rejeitado} />
                                            {
                                                detalhesInstituicao && detalhesInstituicao.data_hora_avaliacao &&
                                                <SttTextItem title={strings.dataAvaliacao} content={detalhesInstituicao.data_hora_avaliacao} />
                                            }
                                        </div>
                                    }
                                </>
                            }
                        />
                        {
                            visualizarAcesso.avaliacao === 'R' ?
                                <>
                                    <Divider />
                                    <SttExpansionPanel
                                        title={strings.dadosRejeicao}
                                        children={
                                            <>
                                                {
                                                    <div>
                                                        {
                                                            detalhesInstituicao && detalhesInstituicao.funcionario_avaliador &&
                                                            <SttTextItem title={strings.funcionarioAvaliador} content={detalhesInstituicao.funcionario_avaliador} />
                                                        }
                                                        {
                                                            detalhesInstituicao && detalhesInstituicao.motivo_rejeicao &&
                                                            <SttTextItem title={strings.motivoRejeicao} content={detalhesInstituicao.motivo_rejeicao} />
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                    />
                                </> : null
                        }
                    </>
                }
                footer={
                    <div>
                        {visualizarAcesso && visualizarAcesso.avaliacao === 'P' &&
                            <>
                                <SttButton
                                    variant="contained"
                                    color="primary"
                                    onClick={preAprovarAcesso}
                                >
                                    {strings.aprovar}
                                </SttButton>
                                <SttButton
                                    variant="contained"
                                    danger="true"
                                    onClick={() => {
                                        setOpenRejeitar(true);
                                    }}
                                >
                                    {strings.rejeitar}
                                </SttButton>
                            </>
                        }
                        <SttButton
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                callbackFechar();
                            }}
                        >
                            {strings.fechar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={salvando}
                text={strings.salvandoMensagemEspera}
            />
            {
                openRejeitar &&
                <ModalRejeitarAcessoInstituicao
                    openRejeitar={openRejeitar}
                    setOpenRejeitar={setOpenRejeitar}
                    visualizarAcesso={visualizarAcesso}
                    callback={callback}
                />
            }
        </>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setAlertaOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(null, mapDispatchToProps)(SttTranslate('TriagemAcessoInstituicao')(ModalTriagemAcessoInstituicao));