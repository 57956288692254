import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, FastField, Field } from 'formik';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '@src/request';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import translate from '@componentes/translate';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction,
} from '@redux/actions/pesquisa/log-funcionario';
import { SttGrid, SttInput, SttButton, SttDatePicker, SttAutocomplete } from '@stt-componentes/core';
import { FUNCIONARIO, EVENTO, CATEGORIA_STT1, CATEGORIA_STT2, DATA_DE, DATA_ATE } from './fieldNames';
import validationSchema from './validationSchema';
import moment from '../../../../../node_modules/moment/moment';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(3),
    },
}));

const FormPesquisa = (props) => {
    const { setParametrosPesquisa, limparParametrosPesquisa, callbackAndamento, callbackLimpar, page, count, orderBy, order, callbackBusca, buscar, strings } =
        props;

    const classes = useStyles();
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();
    const schema = validationSchema(strings);
    const [loadingCategoria, setLoadingCategoria] = useState(false);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const [categoriasSTT2, setCategoriasSTT2] = useState([]);
    const categoriasSTT1 = [
        { codigo: '1', descricao: 'Priorização de exames' },
        { codigo: '2', descricao: 'Renovido de senha' },
        { codigo: '3', descricao: 'Cadastro de novos usuários' },
        { codigo: '4', descricao: 'Alteração de usuário' },
        { codigo: '5', descricao: 'Marcar exame como teste' },
        { codigo: '6', descricao: 'Adicionar equipe' },
        { codigo: '7', descricao: 'Adicionar equipamento' },
        { codigo: '8', descricao: 'Cadastro de novos funcionários' },
        { codigo: '9', descricao: 'Alteração de funcionário' },
        { codigo: '10', descricao: 'Atendimento de funcionário no HelpDesk' },
        { codigo: '11', descricao: 'Atendimento de paciente no HelpDesk' },
    ];

    useEffect(() => {
        setLoadingCategoria(true);
        axios
            .get(`${ADM_API_BASE_URL}/log-funcionario/categorias`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const itens = response.data;
                    setCategoriasSTT2(itens);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoadingCategoria(false);
            });
    }, []);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = page * count;

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados[FUNCIONARIO]) {
            filtros[FUNCIONARIO] = dados[FUNCIONARIO].trim();
        }

        if (dados[EVENTO]) {
            filtros.evento = dados[EVENTO];
        }

        if (dados[CATEGORIA_STT1]) {
            filtros[CATEGORIA_STT1] = dados[CATEGORIA_STT1]?.codigo;
        }

        if (dados[CATEGORIA_STT2]) {
            filtros[CATEGORIA_STT2] = dados[CATEGORIA_STT2];
        }

        if (dados[DATA_DE]) {
            filtros[DATA_DE] = moment(dados[DATA_DE]).format('YYYY-MM-DD');
        }

        if (dados[DATA_ATE]) {
            filtros[DATA_ATE] = moment(dados[DATA_ATE]).format('YYYY-MM-DD');
        }

        setParametrosPesquisa(dados);
        return filtros;
    };

    return (
        <>
            {
                <Formik
                    enableReinitialize
                    innerRef={formRef}
                    initialValues={estadoInicial}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/log-funcionario`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                callbackBusca(dados);
                            })
                            .catch((err) => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: [],
                                });
                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ values, isSubmitting, handleSubmit, resetForm, isValid }) => {
                        return (
                            <>
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <SttGrid container spacing={3}>
                                        {
                                            <>
                                                <SttGrid item xs={12} sm={12} md={6} lg={4}>
                                                    <FastField name={FUNCIONARIO}>
                                                        {({ field, meta }) => (
                                                            <SttInput
                                                                inputProps={{
                                                                    maxLength: 50,
                                                                }}
                                                                {...field}
                                                                label={strings.funcionario}
                                                                value={field.value ? field.value : ''}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                    <FastField name={DATA_DE}>
                                                        {({ field: { name, value }, form: { setFieldValue, setFieldError, setFieldTouched }, meta }) => (
                                                            <SttDatePicker
                                                                label={strings.dataDe}
                                                                inputprops={{
                                                                    name: name,
                                                                }}
                                                                disableFuture
                                                                maxDate={new Date()}
                                                                error={meta.touched && meta.error ? true : false}
                                                                onError={() => {
                                                                    setFieldError(DATA_DE, meta.error);
                                                                }}
                                                                value={value}
                                                                onBlur={() => {
                                                                    setFieldTouched(DATA_DE, true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                onChange={(date) => setFieldValue(DATA_DE, date, true)}
                                                                onClose={() => setFieldTouched(DATA_DE, true)}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                    <FastField name={DATA_ATE}>
                                                        {({ field: { name, value }, form: { setFieldValue, setFieldError, setFieldTouched }, meta }) => (
                                                            <SttDatePicker
                                                                label={strings.dataAte}
                                                                inputprops={{
                                                                    name: name,
                                                                }}
                                                                disableFuture
                                                                maxDate={new Date()}
                                                                error={meta.touched && meta.error ? true : false}
                                                                onError={() => {
                                                                    setFieldError(DATA_ATE, meta.error);
                                                                }}
                                                                value={value}
                                                                onBlur={() => {
                                                                    setFieldTouched(DATA_ATE, true);
                                                                }}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                onChange={(date) => setFieldValue(DATA_ATE, date, true)}
                                                                onClose={() => setFieldTouched(DATA_ATE, true)}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={6} lg={4}>
                                                    <FastField name={EVENTO}>
                                                        {({ field, meta }) => (
                                                            <SttInput
                                                                inputProps={{
                                                                    maxLength: 20,
                                                                }}
                                                                {...field}
                                                                label={strings.evento}
                                                                value={field.value ? field.value : ''}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={6} lg={4}>
                                                    <Field name={CATEGORIA_STT1}>
                                                        {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.categoriaStt1,
                                                                }}
                                                                getOptionLabel={(option) => (option && option.descricao) || ''}
                                                                getOptionSelected={(option, valueOptionSelected) =>
                                                                    option && valueOptionSelected && option.codigo === valueOptionSelected.codigo
                                                                }
                                                                options={categoriasSTT1}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(CATEGORIA_STT1, item || null);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={12} md={12} lg={4}>
                                                    <Field name={CATEGORIA_STT2}>
                                                        {({ field: { name, value, onBlur }, form: { setFieldValue } }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.categoriaStt2,
                                                                }}
                                                                getOptionLabel={(option) => option || ''}
                                                                getOptionSelected={(option, valueOptionSelected) =>
                                                                    option && valueOptionSelected && option === valueOptionSelected
                                                                }
                                                                options={categoriasSTT2}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                carregando={loadingCategoria}
                                                                onChange={(e, item) => {
                                                                    estruturarFiltros({ ...values, [CATEGORIA_STT2]: item });
                                                                    setFieldValue(CATEGORIA_STT2, item || null);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            </>
                                        }
                                    </SttGrid>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12} className={classes.buttonWrapper}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    callbackLimpar();
                                                }}
                                                disabled={isSubmitting || !isValid}
                                                nomarginleft="true"
                                            >
                                                {strings.pesquisar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    limparParametrosPesquisa();
                                                    callbackLimpar();
                                                    resetForm({
                                                        values: estadoInicial,
                                                    });
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            </>
                        );
                    }}
                </Formik>
            }
        </>
    );
};

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaLogFuncionarioReducer.parametrosPesquisa,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosPesquisa: (parametros) => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaLogFuncionario')(FormPesquisa));
