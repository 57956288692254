import * as yup from 'yup';
import {
    CONTATO,
    EMAIL,
    TELEFONE_CELULAR,
    TELEFONE_COMERCIAL,
    TELEFONE_RESIDENCIAL
} from './fieldNames';

export default (strings, emailObrigatorio = true) => {
    let schemaContato = yup.object().shape({
        [TELEFONE_CELULAR]: yup
            .string()
            .nullable()
            .matches(/^\(\d{2}\) \d{5}-\d{4}$/, strings.telefoneInvalido)
            .required(strings.campoObrigatorio),
        [TELEFONE_COMERCIAL]: yup
            .string()
            .nullable()
            .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, strings.telefoneInvalido),
        [TELEFONE_RESIDENCIAL]: yup
            .string()
            .nullable()
            .matches(/^\(\d{2}\) \d{4}-\d{4}$/, strings.telefoneInvalido)
    }).required();

    let regra = yup
        .string()
        .email(strings.emailInvalido)
        .nullable();
        console.log(emailObrigatorio);
        
    if (emailObrigatorio) {
        regra = regra.required(strings.campoObrigatorio);
    }

    schemaContato = schemaContato.concat(
        yup.object().shape({
            [EMAIL]: regra
        })
    );

    let esquemaInterno = yup.object().shape({
        [CONTATO]: schemaContato
    });

    return esquemaInterno;
}

