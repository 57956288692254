import ConstantesPesquisaEquipe from '@constantes/pesquisa/equipe';

const { REDUCER_TYPE } = ConstantesPesquisaEquipe;

const INITIAL_STATE = {
    parametrosPesquisa: {
        nome: '',
        codigo_ine: '',
        uf: null,
        cidade: null,
        cnes: '',
        instituicao: ''
    }
};

function pesquisaEquipeReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if (type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }
    return state;
}

export default pesquisaEquipeReducer;