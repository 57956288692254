import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import translate from '@componentes/translate';
import axios from 'axios';
import { getHeaders } from '@src/request';
import { estadoInicial } from './estadoInicial';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '@redux/actions/pesquisa/acesso-instituicao';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttAutocomplete,
    SttAlerta
} from '@stt-componentes/core';
import {
    FUNCIONARIO_SOLICITANTE,
    INSTITUICAO,
    SITUACAO
} from './fieldNames';
import {
    CONSTANTES_PERFIS
} from '@common/Constants';

const situacoes = [
    { sigla: 'P', descricao: 'Pendente' },
    { sigla: 'A', descricao: 'Aprovado' },
    { sigla: 'R', descricao: 'Rejeitado' }
];

const FormPesquisa = (props) => {
    const {
        user,
        setParametrosPesquisa,
        limparParametrosPesquisa,
        callbackAndamento,
        callbackLimpar,
        parametrosPesquisa,
        page,
        resetPageOrder,
        count,
        orderBy,
        order,
        buscar,
        callbackBusca,
        strings
    } = props;

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();

    useEffect(() => {
        const params = formRef.current.values;
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const instituicoesOpcoes = [];

    if (user.perfisRBAC.some(perfil => perfil.instituicoes)) {
        user.perfisRBAC.forEach(perfil => {
            if (perfil.vinculo === 'I' && perfil.identificador === CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL) {
                perfil.instituicoes.forEach(instituicao => {
                    if (!instituicoesOpcoes.some(inst => inst.id === instituicao.id)) {
                        instituicoesOpcoes.push(instituicao);
                    }
                });
            }
        });
    };

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        dados.page = page;

        dados.limit = count;
        filtros.limit = count;

        dados.start = offset;
        filtros.start = offset

        dados.sort = orderBy;
        filtros.sort = orderBy;

        dados.direction = order;
        filtros.direction = order;

        filtros.count = count;

        if (dados.funcionario_solicitante) {
            filtros.funcionario_solicitante = dados.funcionario_solicitante;
        }

        if (dados.instituicao) {
            filtros.instituicao = dados.instituicao.id;
        }

        if (dados.situacao) {
            filtros.situacao = dados.situacao.sigla;
        }

        setParametrosPesquisa(dados);
        return filtros;
    };

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={parametrosPesquisa}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios.get(`${ADM_API_BASE_URL}/acesso-instituicao`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: []
                                });

                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            values,
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}
                                    >
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <FastField name={FUNCIONARIO_SOLICITANTE}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    label={strings.funcionarioSolicitante}
                                                                    inputProps={{
                                                                        maxLength: 255
                                                                    }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={(e) => {
                                                                        field.onChange(e)
                                                                    }}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    {
                                                        user.perfisRBAC.some(perfil => perfil.instituicoes) ? (
                                                            <SttGrid item xs={12} sm={6} md={4} lg={3}>
                                                                <Field name={INSTITUICAO}>
                                                                    {({
                                                                        field: { name, value, onBlur },
                                                                        form: { setFieldValue }
                                                                    }) => (
                                                                        <SttAutocomplete
                                                                            inputprops={{
                                                                                name: name,
                                                                                label: strings.instituicao
                                                                            }}
                                                                            getOptionLabel={option => option && option.nome || ''}
                                                                            getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                            options={instituicoesOpcoes}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            onChange={(e, item) => {
                                                                                setFieldValue(INSTITUICAO, item || null);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </SttGrid>
                                                        ) : null
                                                    }

                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <Field name={SITUACAO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.situacao
                                                                    }}
                                                                    getOptionLabel={option => option && option.descricao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.sigla === valueOptionSelected.sigla}
                                                                    options={situacoes}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(SITUACAO, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        callbackLimpar();
                                                        resetPageOrder();
                                                    }}
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        callbackLimpar();
                                                        resetPageOrder();
                                                        limparParametrosPesquisa();
                                                        resetForm({
                                                            values: estadoInicial
                                                        });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.triagemAcessoInstituicaoReducer.parametrosPesquisa
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('TriagemAcessoInstituicao')(FormPesquisa));