import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttExpansionPanel,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setPerfilAlteracao as setPerfilAlteracaoAction
} from '@redux/actions/cadastro/perfil/index';

import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';

import UtilPerfil from '@util/perfil';


const DadosGerais = lazy(() => import('@componentes/interno/cadastro/perfil'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const Carregando = memo((props) => {
    return (
        <SttLoading {...props} />
    )
});

const CadastroPerfil = (params) => {
    const { strings, user, perfilAlteracao, setParametrosAlerta, setOpen, setPerfilAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();

    //Carregando
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (perfilAlteracao) {
            setInitialValues(UtilPerfil.inicializarValoresFormulario(perfilAlteracao));
        } else {
            setInitialValues(UtilPerfil.inicializarValoresFormulario({}));
        }
        setCarregado(true);
    }, [perfilAlteracao]);

    const redirectPesquisaPerfil = () => {
        setOpen(false);
        setPerfilAlteracao(null);
        navigate("/pesquisa/perfil", { state: { pesquisar: true } });
    }

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);

        axios
            .post(`${ADM_API_BASE_URL}/perfil`, dados, { headers: getHeaders() })
            .then((response) => {

                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisaPerfil
                        }
                    ],
                    onClose: redirectPesquisaPerfil
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        mensagem = data.message;
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            let mensagem = strings.confirmacaoCadastroPerfil;
                            if (perfilAlteracao) {
                                mensagem = strings.confirmacaoAlteracaoPerfil;
                            }

                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            let dadosFormatados = UtilPerfil.formatarCadastro(dados);
                                            if (perfilAlteracao) {
                                                dadosFormatados.append('alteracao', true);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setPerfilAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/perfil", { state: { pesquisar: true } });
                                                                }

                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{perfilAlteracao ? strings.alteracaoPerfil : strings.cadastroPerfil}</SttHeading>
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <DadosGerais
                                                    carregando={carregando}
                                                    user={user}
                                                    setCarregando={setCarregando}
                                                />
                                            </Suspense>

                                            <div className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :

                    (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }

            <Carregando
                open={carregando}
                text={strings.carregando}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        perfilAlteracao: state.cadastroPerfilReducer.perfilAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPerfilAlteracao: id => dispatch(setPerfilAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroPerfil')(CadastroPerfil));