import {
    DESCRICAO,
} from '@componentes/interno/cadastro/rede/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};

    if (dadosAlteracao.id) {
        formulario.id = dadosAlteracao.id;
    }

    formulario[DESCRICAO] = dadosAlteracao.descricao ? dadosAlteracao.descricao : '';

    return formulario;
}

const formatarCadastro = (dados) => {
    const formData = new FormData();

    if (dados.id) {
        formData.append('id', dados.id)
    }

    formData.append('descricao', dados.descricao.trim());

    return formData;
};


export default { inicializarValoresFormulario, formatarCadastro };