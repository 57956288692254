import Constantes from '@constantes/pesquisa/mensagens';

const { REDUCER_TYPE } = Constantes;

const INITIAL_STATE = {
    parametrosPesquisa: {
        assunto: '',
        area: null,
        situacao: null,
        macrorregiao: null,
        cidade: null,
        uf: null,
        instituicao: '',
        equipe: '',
    }
}; 

function pesquisaMensagensReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if (type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }
    return state;
}

export default pesquisaMensagensReducer;