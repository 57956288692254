import { LogLevel } from "@azure/msal-browser";
import { ORIGEM_AUTENTICACAO_EXTERNA } from "../common/Constants";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = (config) => {
    let configuracaoAzure = {};
    if (config.autenticacao_externa) {
        configuracaoAzure = config.autenticacao_externa.origem.filter(configuracao => configuracao.identificador === ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD);
        if (configuracaoAzure) {
            configuracaoAzure = configuracaoAzure[0];
        }
    }

    return {
        auth: {
            clientId: configuracaoAzure.clientId,
            authority: configuracaoAzure.authority,
            redirectUri: configuracaoAzure.redirectUrl,
            postLogoutRedirectUri: configuracaoAzure.redirectUrl,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: isIE || isEdge || isFirefox,
        },
        system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read", "profile", "email"],
    prompt: "select_account"
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = () => {
    let configuracaoAzure = {};
    if (global.gConfig.autenticacao_externa) {
        configuracaoAzure = global.gConfig.autenticacao_externa.origem.filter(configuracao => configuracao.identificador === ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD);
        if (configuracaoAzure) {
            configuracaoAzure = configuracaoAzure[0];
        }
    }

    return {
        graphMeEndpoint: configuracaoAzure.graphMeEndpoint,
        graphUsersEndpoint: configuracaoAzure.graphUsersEndpoint
    }
}
