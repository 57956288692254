export const DADOS_PROFISSIONAIS = 'dadosProfissionais';
export const TIPO_OCUPACAO = 'tipoOcupacao';
export const OCUPACAO_NAO_CLASSIFICADA = 'ocupacaoNaoClassificada';
export const CBO_FAMILIA = 'cboFamilia';
export const CBO_OCUPACAO = 'cboOcupacao';
export const POSSUI_CONSELHO_TRABALHO = 'possuiConselhoTrabalho';
export const CONSELHO_TRABALHO = 'conselhoTrabalho';
export const NUMERO_CONSELHO_TRABALHO = 'numeroConselhoTrabalho';
export const UF_CONSELHO_TRABALHO =  'ufConselhoTrabalho';
export const TIPO_CONSELHO_TRABALHO = 'tipoConselhoTrabalho';
export const RQE = 'rqe';