/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';

import { URL_BASE, DCM4CHEE, URL_TOKEN, CLIENT_ID, CLIENT_SECRET } from './fieldNames';

export default (strings) => {
    return yup.object().shape(
        {
            [URL_BASE]: yup.string().url(strings.urlInvalida).nullable().required(strings.campoObrigatorio),

            [DCM4CHEE]: yup.number().nullable().required(strings.campoObrigatorio),

            [URL_TOKEN]: yup.string().when([CLIENT_ID, CLIENT_SECRET], {
                is: (clientId, clientSecret) => clientId || clientSecret,
                then: yup.string().url(strings.urlInvalida).nullable().required(strings.campoObrigatorio),
                otherwise: yup.string().url(strings.urlInvalida).nullable().notRequired(),
            }),

            [CLIENT_ID]: yup.string().when([URL_TOKEN, CLIENT_SECRET], {
                is: (urlToken, clientSecret) => urlToken || clientSecret,
                then: yup.string().required(strings.campoObrigatorio),
                otherwise: yup.string().nullable().notRequired(),
            }),

            [CLIENT_SECRET]: yup.string().when([URL_TOKEN, CLIENT_ID], {
                is: (urlToken, clientId) => urlToken || clientId,
                then: yup.string().required(strings.campoObrigatorio),
                otherwise: yup.string().nullable().notRequired(),
            }),
        },
        [
            [URL_TOKEN, CLIENT_ID],
            [URL_TOKEN, CLIENT_SECRET],
            [CLIENT_ID, CLIENT_SECRET],
        ]
    );
};
