import React, { useState, useEffect } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttHeading,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttTableSortLabel,
    SttTableBody,
    SttCircularProgress,
    SttText,
    SttSeeButton,
    SttTablePagination
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '@security/acl';
import Paper from '@material-ui/core/Paper';
import FormPesquisa from './form';
import translate from '@componentes/translate';
import {
    setMotivoInvalidacaoExameAlteracao as setMotivoInvalidacaoExameAlteracaoAction
} from '@redux/actions/cadastro/invalidacao-exame';
import { useNavigate, useLocation } from "react-router-dom";
import { PERMISSOES } from '@src/common/Constants';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        REDE_TELEMEDICINA: 'rede_telemedicina',
        MODALIDADE: 'modalidade',
        DESCRICAO: 'descricao',
        ATIVO: 'ativo'
    }
};

const PesquisaInvalidacaoExame = (props) => {
    const { user, strings, setMotivoInvalidacaoExameAlteracao } = props;
    const classes = useStyles();

    // filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [buscar, setBuscar] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);

    // solicitação
    const [exame, setExame] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setExame(dados.itens);
        setBuscar(false);
    };

    const callbackLimpar = () => { };

    const clickEditarMotivoInvalidacaoExame = (motivo) => {
        setMotivoInvalidacaoExameAlteracao({
            id: motivo.id,
            modalidade: { id: motivo.id_modalidade, descricao: motivo.modalidade },
            rede_telemedicina: motivo.id_rede_telemedicina ? { id: motivo.id_rede_telemedicina, descricao: motivo.rede_telemedicina } : null,
            descricao: motivo.descricao,
            situacao: motivo.ativo
        });

        navigate(`/cadastro/invalidacao-exame`);
    };

    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    return (
        <> 
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>

            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.REDE_TELEMEDICINA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.REDE_TELEMEDICINA}
                                        direction={orderBy === ORDENACAO.CAMPOS.REDE_TELEMEDICINA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.REDE_TELEMEDICINA)}
                                    >
                                        {strings.redeTelemedicina}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.MODALIDADE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.MODALIDADE}
                                        direction={orderBy === ORDENACAO.CAMPOS.MODALIDADE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.MODALIDADE)}
                                    >
                                        {strings.modalidade}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.DESCRICAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DESCRICAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DESCRICAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DESCRICAO)}
                                    >
                                        {strings.descricao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.ATIVO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.ATIVO}
                                        direction={orderBy === ORDENACAO.CAMPOS.ATIVO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ATIVO)}
                                    >
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.opcoes}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Motivos de Invalidação de Exame</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={13}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    exame.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={13}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        exame.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.rede_telemedicina ? row.rede_telemedicina : ''}</SttTableCell>
                                                    <SttTableCell>{row.modalidade ? row.modalidade : ''}</SttTableCell>
                                                    <SttTableCell>{row.descricao ? row.descricao : ''}</SttTableCell>
                                                    <SttTableCell>{row.ativo ? strings.ativo : strings.inativo}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.redeTelemedicina}:</b> {row.rede_telemedicina ? row.rede_telemedicina : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.modalidade}:</b> {row.modalidade ? row.modalidade : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.descricao}:</b> {row.descricao ? row.descricao : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.situacao}:</b> {row.ativo ? strings.ativo : strings.inativo}
                                                        </SttText>
                                                       
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    {
                                                        temPermissaoRBAC(user, PERMISSOES.CADASTRO_ESF) &&
                                                        <SttSeeButton
                                                            id={`btn-ver-solicitacao-${index}`}
                                                            onClick={() => {
                                                                clickEditarMotivoInvalidacaoExame(row);
                                                            }}
                                                        />
                                                    }
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                exame.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }            
        </>

    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMotivoInvalidacaoExameAlteracao: id => dispatch(setMotivoInvalidacaoExameAlteracaoAction(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaInvalidacaoExame')(PesquisaInvalidacaoExame));

