/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    VINCULO
} from '../fieldNames';

export default (strings) => {
    return yup.object().shape({
        [VINCULO]: yup.object().shape({}).required()
    });
}
