import * as yup from 'yup';
import {
    COMPLEMENTO,
    NOME_MAE,
    DATA_NASCIMENTO
} from './fieldNames';

export default (strings, emailObrigatorio = true) => {
    let schemaComplemento = yup.object().shape({
        [NOME_MAE]: yup
            .string()
            .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, strings.nomeInvalido)
            .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, strings.nomeInvalido)
            .trim()
            .min(5, strings.nomeInvalido)
            .max(100, strings.nomeInvalido)
            .nullable()
            .required(strings.campoObrigatorio),
        [DATA_NASCIMENTO]: yup
            .date()
            .typeError(strings.dataInvalida)
            .nullable()
            .min(new Date(1900, 0, 1), strings.dataNascimentoMinima)
            .required(strings.campoObrigatorio),
    }).required();

    let esquemaInterno = yup.object().shape({
        [COMPLEMENTO]: schemaComplemento
    });

    return esquemaInterno;
}
