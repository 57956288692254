import ConstantesCadastroMotivoInvalidacaoExame from '@constantes/cadastro/invalidacao-exame/index';

const { REDUCER_TYPE } = ConstantesCadastroMotivoInvalidacaoExame ;

const INITIAL_STATE = {
    motivoInvalidacaoExameAlteracao: null
};

function cadastroMotivoInvalidacaoExameReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    const { type, payload } = action;

    if(type === REDUCER_TYPE.SETAR_ID_MOTIVO_INVALIDACAO_EXAME_ALTERACAO) {
        return {
            ...state,
            motivoInvalidacaoExameAlteracao: payload.motivoInvalidacaoExameAlteracao
        };
    }

    return state;
};

export default cadastroMotivoInvalidacaoExameReducer;