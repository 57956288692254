import React, { useState, useEffect, Suspense, lazy } from "react";
import {
    SttExpansionPanel,
    SttContainer,
    SttHeading,
    SttCircularProgress,
    SttButton
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import {
    setPacienteAlteracao as setPacienteAlteracaoAction
} from '@redux/actions/cadastro/paciente/index';

import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';


const HistoricoProcedimentos = lazy(() => import('@componentes/interno/cadastro/paciente/historico-procedimentos'));
const Formulario = lazy(() => import('@componentes/interno/cadastro/paciente/form'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const CadastroPaciente = (params) => {
    const { strings, pacienteAlteracao, setParametrosAlerta, setOpen, setPacienteAlteracao } = params;

    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        if (!pacienteAlteracao) {
            navigate('/nao-autorizado', { replace: true });
        }
    }, []);

    
    return (

        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                className={classes.botaoVoltar}
                onClick={() => {
                    const parametrosAlerta = {
                        titulo: strings.confirmacao,
                        mensagem: strings.retornarTelaPesquisa,
                        tipo: 'alert',
                        open: true,
                        opcoes: [
                            {
                                title: strings.sim,
                                onClick: () => {
                                    setPacienteAlteracao(null);
                                    setOpen(false);
                                    navigate("/pesquisa/paciente", { state: { pesquisar: true }});
                                }

                            },
                            {
                                title: strings.nao,
                                onClick: () => {
                                    setOpen(false);
                                }

                            }
                        ]
                    };
                    setParametrosAlerta(parametrosAlerta);
                }}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>

            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>

            {/* Histórico de procedimentos */}
            <SttExpansionPanel
                title={strings.historicoProcedimentos}
                children={
                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                        <HistoricoProcedimentos />
                    </Suspense>
                }
            />

            {/* Dados do paciente */}
            <SttExpansionPanel
                title={strings.dadosCadastrais}
                children={
                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                        <Formulario />
                    </Suspense>
                }
            />
        </SttContainer>
    );
}


const mapStateToProps = (state) => {
    return {
        pacienteAlteracao: state.cadastroPacienteReducer.pacienteAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPacienteAlteracao: id => dispatch(setPacienteAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroPaciente')(CadastroPaciente));