/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DADOS_PROFISSIONAIS,
    POSSUI_CONSELHO_TRABALHO,
    NUMERO_CONSELHO_TRABALHO,
    CBO_FAMILIA,
    TIPO_OCUPACAO,
    CBO_OCUPACAO,
    UF_CONSELHO_TRABALHO,
    TIPO_CONSELHO_TRABALHO,
    OCUPACAO_NAO_CLASSIFICADA
} from './fieldNames';
import {
    CONSTANTES_TIPO_OCUPACAO,
    CONTANTES_POSSUI_CONSELHO_TRABALHO
} from '@common/Constants';

export default (strings) => {
    return yup.object().shape({
        [DADOS_PROFISSIONAIS]: yup.object().shape({
            [CBO_FAMILIA]: yup
                .object()
                .nullable()
                .when(TIPO_OCUPACAO, {
                    is: (val) => val === CONSTANTES_TIPO_OCUPACAO.CLASSIFICADA,
                    then: yup.object().shape({
                        id: yup.number(),
                        codigo: yup.string(),
                        descritor: yup.string()
                    }).nullable().required(strings.campoObrigatorio),
                }),
            [CBO_OCUPACAO]: yup
                .object()
                .nullable()
                .when(TIPO_OCUPACAO, {
                    is: (val) => val === CONSTANTES_TIPO_OCUPACAO.CLASSIFICADA,
                    then: yup.object().shape({
                        id: yup.number(),
                        codigo_ocupacao: yup.string(),
                        descritor: yup.string()
                    }).nullable().required(strings.campoObrigatorio),
                }),
            [OCUPACAO_NAO_CLASSIFICADA]: yup
                .object()
                .nullable()
                .when(TIPO_OCUPACAO, {
                    is: (val) => val === CONSTANTES_TIPO_OCUPACAO.NAO_CLASSIFICADA,
                    then: yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string(),
                        ordenacao: yup.number(),
                        sigla: yup.string()
                    }).nullable().required(strings.campoObrigatorio),
                }),
        }).required()
    });
}
