import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import DetalhesTriagem from './detalhes';
import FormPesquisa from './form';
import translate from '@componentes/translate';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttSeeButton,
    SttHidden,
    SttHeading
} from '@stt-componentes/core';
import { useLocation } from "react-router-dom";
import { Util } from '@stt-utilitarios/core';
import { CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO } from '@common/Constants';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        height: 'auto',
        textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    circulo: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        display: 'inline-block',
        border: '1px solid black'
    },
    label: {
        alignSelf: 'center',
        marginLeft: '5px'
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NOME: 'nome',
        CPF: 'cpf',
        SITUACAO: 'situacao',
        NUMERO_CONSELHO: 'numero_conselho',
        DATA_SOLICITACAO: 'data_hora_solicitacao',
        MUNICIPIO_UF: 'cidade_uf'
    }
}

const TriagemAutoCadastro = (props) => {
    const { strings } = props;
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);

    useEffect(() => {
        setBuscar(true);
    }, []);

    //Solicitação
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    //Visualização de processo
    const [idSolicitacaoCadastro, setIdSolicitacaoCadastro] = useState(null);

    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo, handleSubmit) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleTriarAutoCadastro = (id) => {
        if (id) {
            setIdSolicitacaoCadastro(id);
        }
    };

    const handleClickVoltar = () => {
        setIdSolicitacaoCadastro(null);
        setBuscar(true);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setSolicitacoes(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => { }

    const handleCorFundo = (solicitacao) => {
        if (solicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_PENDENTE) {
            return '#F7FC75';
        }

        if (solicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_APROVADO) {
            return '#8AC58B';
        }

        if (solicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_REJEITADO) {
            return '#D88080';
        }
    }

    const Legenda = () => {
        return (
            <div style={{ display: 'flex', flexFlow: 'wrap', gap: '10px' }}>
                <div>
                    <span className={classes.circulo} style={{ backgroundColor: '#ffffff' }} />
                    <label style={{ alignSelf: 'center', marginLeft: '5px' }}>{strings.aguardandoTriagem}</label>
                </div>

                <div>
                    <span className={classes.circulo} style={{ backgroundColor: '#F7FC75' }} />
                    <label style={{ alignSelf: 'center', marginLeft: '5px' }}>{strings.cadastroPendente}</label>
                </div>

                <div>
                    <span className={classes.circulo} style={{ backgroundColor: '#8AC58B' }} />
                    <label style={{ alignSelf: 'center', marginLeft: '5px' }}>{strings.cadastroAprovado}</label>
                </div>
            </div>
        );
    }

    return (
        <>
            {
                !idSolicitacaoCadastro ?
                    (
                        <>
                            <SttHeading variant="h1" color="primary" className={classes.heading}>
                                {strings.tituloPagina}
                            </SttHeading>
                            <FormPesquisa
                                callbackAndamento={setBuscaEmAndamento}
                                callbackBusca={callbackBusca}
                                callbackLimpar={callbackLimpar}
                                page={page}
                                count={count}
                                orderBy={orderBy}
                                order={order}
                                buscar={buscar}
                            />

                            <TableContainer className={classes.tableWrapper} component={Paper}>
                                <SttTable>
                                    <>
                                        <SttHidden smDown>
                                            <caption colSpan={3}><Legenda /></caption>
                                        </SttHidden>
                                        <SttHidden mdUp>
                                            <caption width="96%"><Legenda /></caption>
                                        </SttHidden>
                                    </>
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden smDown>
                                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}
                                                    >
                                                        {strings.nome}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.CPF ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.CPF}
                                                        direction={orderBy === ORDENACAO.CAMPOS.CPF ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CPF)}
                                                    >
                                                        {strings.cpf}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
                                                        direction={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}
                                                    >
                                                        {strings.situacao}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.NUMERO_CONSELHO ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.NUMERO_CONSELHO}
                                                        direction={orderBy === ORDENACAO.CAMPOS.NUMERO_CONSELHO ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO_CONSELHO)}
                                                    >
                                                        {strings.conselhoTrabalho}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.DATA_SOLICITACAO}
                                                        direction={orderBy === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO)}
                                                    >
                                                        {strings.dataSolicitacao}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.MUNICIPIO_UF ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.MUNICIPIO_UF}
                                                        direction={orderBy === ORDENACAO.CAMPOS.MUNICIPIO_UF ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.MUNICIPIO_UF)}
                                                    >
                                                        {strings.cidadeUf}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="10%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                                    {strings.visualizar}
                                                </SttTableCell>
                                            </SttHidden>
                                            <SttHidden mdUp>
                                                <SttTableCell width="100%" colSpan="2">Pesquisa de funcionários</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        {
                                            buscaEmAndamento
                                                ?
                                                <SttTableRow key={-1}>
                                                    <SttTableCell colSpan={13}
                                                        align="center">
                                                        <SttCircularProgress />
                                                    </SttTableCell>
                                                </SttTableRow>
                                                :
                                                (
                                                    solicitacoes.length === 0 ?
                                                        <SttTableRow key={-1}>
                                                            <SttTableCell colSpan={13}
                                                                align="center">
                                                                {strings.nenhumRegistroEncontrado}
                                                            </SttTableCell>
                                                        </SttTableRow>
                                                        :
                                                        solicitacoes.map((row, index) => (
                                                            <SttTableRow key={index} style={{ backgroundColor: handleCorFundo(row) }}>
                                                                <SttHidden smDown>
                                                                    <SttTableCell>{row.nome}</SttTableCell>
                                                                    <SttTableCell>{Util.util.adicionarMascara(row.cpf, '000.000.000-00')}</SttTableCell>
                                                                    <SttTableCell>{row.situacaoFormatada}</SttTableCell>
                                                                    <SttTableCell>{row.conselho_trabalho_formatado}</SttTableCell>
                                                                    <SttTableCell>{row.data_hora_solicitacao_formatada}</SttTableCell>
                                                                    <SttTableCell>{row.cidade_uf}</SttTableCell>
                                                                </SttHidden>
                                                                <SttHidden mdUp>
                                                                    <SttTableCell width="96%">
                                                                        <SttText size="small">
                                                                            <b>{strings.nome}:</b> {row.nome}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.cpf}:</b> {Util.util.adicionarMascara(row.cpf, '000.000.000-00')}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.situacao}:</b> {row.situacaoFormatada}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.conselhoTrabalho}:</b> {row.conselho_trabalho_formatado}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.dataSolicitacao}:</b> {row.data_hora_solicitacao_formatada}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.cidadeUf}:</b> {row.cidade_uf}
                                                                        </SttText>
                                                                    </SttTableCell>
                                                                </SttHidden>
                                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                                    <SttSeeButton
                                                                        id={`btn-ver-funcionario-${index}`}
                                                                        onClick={() => {
                                                                            handleTriarAutoCadastro(row.id);
                                                                        }}
                                                                    />
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        ))
                                                )
                                        }
                                    </SttTableBody>
                                </SttTable>
                            </TableContainer>
                            {
                                solicitacoes.length > 0 ?
                                    (
                                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                                            component="div"
                                            count={totalRegistros}
                                            rowsPerPage={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            labelRowsPerPage={strings.linhasPorPagina}
                                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                                        />
                                    ) : null
                            }
                        </>
                    ) : <DetalhesTriagem id={idSolicitacaoCadastro} setBuscar={setBuscar} setIdSolicitacaoCadastro={setIdSolicitacaoCadastro} voltar={handleClickVoltar} />
            }
        </>
    )
}

TriagemAutoCadastro.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(translate('TriagemAutoCadastro')(TriagemAutoCadastro));