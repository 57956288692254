import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, FastField } from 'formik';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '@src/request';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import translate from '@componentes/translate';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '@redux/actions/pesquisa/rede';
import {
    SttGrid,
    SttInput,
    SttButton
} from '@stt-componentes/core';
import {
    DESCRICAO,
} from './fieldNames';
import validationSchema from './validationSchema';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const FormPesquisa = (props) => {
    const {
        setParametrosPesquisa,
        limparParametrosPesquisa,
        callbackAndamento,
        callbackLimpar,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
        strings
    } = props;

    const classes = useStyles();
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();
    const schema = validationSchema(strings);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados.descricao) {
            filtros.descricao = dados.descricao.trim();
        }

        setParametrosPesquisa(dados);
        return filtros;
    }

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={estadoInicial}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/rede-telemedicina`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: []
                                });

                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}>
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={3}>
                                                        <FastField name={DESCRICAO}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    inputProps={{
                                                                        maxLength: 100
                                                                    }}
                                                                    {...field}
                                                                    label={strings.descricao}
                                                                    value={field.value ? field.value : ''}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>
                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        callbackLimpar();
                                                    }}
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        limparParametrosPesquisa();
                                                        callbackLimpar();
                                                        resetForm({
                                                            values: estadoInicial
                                                        });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
}

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaRedeReducer.parametrosPesquisa,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('FormPesquisaRede')(FormPesquisa));