import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttNotification
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { temPerfil } from '@security/acl';
import HttpStatus from 'http-status-codes';
import {
    Formik,
    yupToFormErrors,
    validateYupSchema
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate, useLocation } from "react-router-dom";
import {
    CONSTANTES_PERFIS
} from '@common/Constants';
import UtilFuncionario from '@util/funcionario';
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setIdFuncionarioAlteracao as setIdFuncionarioAlteracaoAction
} from '@redux/actions/cadastro/funcionario/index';
import UtilTriagem from '@util/triagem';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import { updatedDiff } from 'deep-object-diff';

const DadosGerais = lazy(() => import('@componentes/interno/cadastro/funcionario/dadosGerais'));
const DadosProfissionais = lazy(() => import('@componentes/interno/cadastro/funcionario/dadosProfissionais'));
const DadosContato = lazy(() => import('@componentes/interno/cadastro/funcionario/dadosContato'));
const DadosParticipacao = lazy(() => import('@componentes/interno/cadastro/funcionario/dadosParticipacao'));
const DadosVinculo = lazy(() => import('@componentes/interno/cadastro/funcionario/dadosVinculo'));
const DadosServico = lazy(() => import('@componentes/interno/cadastro/funcionario/dadosServico'));
const DadosUsuario = lazy(() => import('@componentes/interno/cadastro/funcionario/dadosUsuario'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1),
        '& button': {
            marginRight: theme.spacing(1)
        },
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    },
    expansionPanel: {
        width: '100%'
    },
    alert: {
        marginTop: theme.spacing(2)
    },
    notification: {
        display: 'flex',
        flexDirection: 'column'
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Carregando = memo((props) => {
    return (
        <SttLoading {...props} />
    )
});

const Solicitacao = (params) => {
    const { strings, user, idFuncionarioAlteracao, setParametrosAlerta, setOpen, setIdFuncionarioAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const AUTH2_API_BASE_URL = global.gConfig.url_base_api_oauth2;

    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    //Carregando
    const [carregando, setCarregando] = useState(false);

    //CPF que vem da pesquisa onde nenhum resultado foi encontrado
    const [cpfPesquisa, setCpfPesquisa] = useState(null);

    // Lista de logins do funcionário.
    const [logins, setLogins] = useState([]);

    // IDs das solicitações pendentes
    const [idsSolicitacoesPendentes, setIdsSolicitacoesPendentes] = useState([]);
    const [profissoesSolicitacoesPendentes, setProfissoesSolicitacoesPendentes] = useState(null);

    const [aprovarAutoCadastro, setAprovarAutoCadastro] = useState(false);

    useEffect(() => {
        if (location.state && location.state.cpf) {
            setCpfPesquisa(location.state.cpf);
        }
    }, []);

    const secaoDadosGerais = useRef(null);
    const secaoDadosContato = useRef(null);
    const secaoDadosProfissionais = useRef(null);
    const secaoDadosParticipacao = useRef(null);
    const secaoDadosVinculo = useRef(null);
    const secaoDadosServico = useRef(null);
    const secaoDadosUsuario = useRef(null);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'dadosGerais': true,
        'dadosContato': false,
        'dadosProfissionais': false,
        'dadosParticipacao': false,
        'vinculo': false,
        'dadosServico': false,
        'dadosUsuario': false
    });


    useEffect(() => {
        if (idFuncionarioAlteracao) {
            //Existe um funcionário para alteração
            axios.get(`${ADM_API_BASE_URL}/funcionario/${idFuncionarioAlteracao}`, { headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    setIdsSolicitacoesPendentes(dados.solicitacoesCadastro?.length ? dados.solicitacoesCadastro.map((s) => s.id) : []);
                    setProfissoesSolicitacoesPendentes(dados.solicitacoesCadastro?.length ? dados.solicitacoesCadastro.map((s) => s.profissao).join(', ') : null);
                    setInitialValues(UtilFuncionario.inicializarValoresFormulario(user, dados));
                    setLogins(dados?.usuarios?.map((usuario) => usuario.login));
                    setCarregado(true);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            //Irá cadastrar funcionário do começo
            setInitialValues(UtilFuncionario.inicializarValoresFormulario(user, {}));
            setCarregado(true);
        }
    }, [idFuncionarioAlteracao]);

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);

        if (idFuncionarioAlteracao) {
            dados.append('id', idFuncionarioAlteracao);
        }

        dados.append('instancia', global.gConfig.basename);

        axios
            .post(`${ADM_API_BASE_URL}/funcionario`, dados, { headers: getHeaders() })
            .then((response) => {
                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisaFuncionario
                        }
                    ],
                    onClose: redirectPesquisaFuncionario
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    const redirectPesquisaFuncionario = () => {
        setOpen(false);
        setIdFuncionarioAlteracao(null);
        navigate("/pesquisa/funcionario", { state: { pesquisar: true } });
    }

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }

        novoSecoesAbertas[secao] = estado;

        setSecoesAbertas(novoSecoesAbertas);
    }

    const deslogarUsuario = (logins) => {
        axios
            .post(`${AUTH2_API_BASE_URL}/admin/usuarios/deslogar-logins`, { logins }, { headers: getHeaders() })
            .then((_) => {
                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: strings.sucessoDeslogarUsuario,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            },
                        },
                    ],
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const verificarSecoesComErro = (validateForm) => {
        setCarregando(true);
        validateForm().then((retorno) => {
            let node = null;
            if (retorno['dadosGerais']) {
                abrirSecao('dadosGerais', true);
                node = secaoDadosGerais.current;
            } else if (retorno['dadosContato']) {
                abrirSecao('dadosContato', true);
                node = secaoDadosContato.current;
            } else if (retorno['dadosProfissionais']) {
                abrirSecao('dadosProfissionais', true);
                node = secaoDadosProfissionais.current;
            } else if (retorno['dadosParticipacao']) {
                abrirSecao('dadosParticipacao', true);
                node = secaoDadosParticipacao.current;
            } else if (retorno['vinculo']) {
                abrirSecao('vinculo', true);
                node = secaoDadosVinculo.current;
            } else if (retorno['dadosServico']) {
                abrirSecao('dadosServico', true);
                node = secaoDadosServico.current;
            } else if (retorno['dadosUsuario']) {
                abrirSecao('dadosUsuario', true);
                node = secaoDadosUsuario.current;
            }


            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 500);
            }
            setCarregando(false);
        });
    }

    const verificarAlteracaoFuncionario = (dadosSubmit) => {
        let diferenca = updatedDiff(initialValues, dadosSubmit);


        //Verificar se mudou dados gerais
        if (diferenca.dadosGerais) {
            if (diferenca.dadosGerais.genero && diferenca.dadosGerais.genero.id === initialValues.dadosGerais.genero) {
                delete diferenca.dadosGerais.genero;
            }

            if (diferenca.dadosGerais.municipio && diferenca.dadosGerais.municipio.id === initialValues.dadosGerais.municipio) {
                delete diferenca.dadosGerais.municipio;
            }

            if (diferenca.dadosGerais.uf && diferenca.dadosGerais.uf.id === initialValues.dadosGerais.uf) {
                delete diferenca.dadosGerais.uf;
            }

            if (diferenca.dadosGerais.situacaoCadastro && diferenca.dadosGerais.situacaoCadastro.identificador === initialValues.dadosGerais.situacaoCadastro) {
                delete diferenca.dadosGerais.situacaoCadastro;
            }
        }

        if (Object.keys(diferenca.dadosGerais).length === 0) {
            delete diferenca.dadosGerais;
        }

        //Verificar se mudou dadosProfissionais
        if (diferenca.dadosProfissionais) {
            if (diferenca.dadosProfissionais.cboFamilia && diferenca.dadosProfissionais.cboFamilia.id === initialValues.dadosProfissionais.cboFamilia) {
                delete diferenca.dadosProfissionais.cboFamilia;
            }

            if (diferenca.dadosProfissionais.cboOcupacao && diferenca.dadosProfissionais.cboOcupacao.id === initialValues.dadosProfissionais.cboOcupacao) {
                delete diferenca.dadosProfissionais.cboOcupacao;
            }
        }

        if (Object.keys(diferenca.dadosProfissionais).length === 0) {
            delete diferenca.dadosProfissionais;
        }

        delete diferenca.usuarios;

        //Se for diferente de zero, houve alterações no form
        return Object.keys(diferenca).length !== 0;
    }

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validate={(values) => {
                            let temPerfilAdministrador = (
                                temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR) ||
                                temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_TELEMEDICINA) ||
                                temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_TELESSAUDE) ||
                                temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_ESTADUAL)
                            );
                            let temPerfilAdministradorLocal = temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL);
                            return new Promise(resolve => {
                                const valores = {
                                    temPerfilAdministrador: temPerfilAdministrador,
                                    temPerfilAdministradorLocal: temPerfilAdministradorLocal
                                };
                                validateYupSchema(values, schema, false, valores).then(
                                    () => {
                                        resolve({});
                                    },
                                    (err) => {
                                        resolve(yupToFormErrors(err));
                                    }
                                );
                            });
                        }}
                        onSubmit={(dados, { setSubmitting }) => {
                            let mensagem = strings.confirmacaoCadastroFuncionario;

                            if (idFuncionarioAlteracao) {
                                mensagem = strings.confirmacaoAlteracaoFuncionario;
                            }

                            if (aprovarAutoCadastro) {
                                mensagem = strings.desejaAprovarCadastro;
                            }

                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.sim,
                                        onClick: () => {
                                            let dadosFormatados = UtilFuncionario.formatarCadastro(dados);
                                            dadosFormatados.append('adminLocal', temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL));
                                            dadosFormatados.append('funcionarioAlterado', verificarAlteracaoFuncionario(dados));
                                            if (aprovarAutoCadastro) {
                                                dadosFormatados.append('solicitacaoAprovacao', idsSolicitacoesPendentes[0]);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                validateForm,
                                values
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            {
                                                idsSolicitacoesPendentes.length > 0 &&
                                                <SttNotification severity="info" className={classes.alert}>
                                                    <div className={classes.notification} dangerouslySetInnerHTML={{ __html: strings.instrucaoAprovacaoAutoCadastro }} />
                                                </SttNotification>
                                            }
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setIdFuncionarioAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/funcionario", { state: { pesquisar: true } });
                                                                }
                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{idFuncionarioAlteracao ? strings.alteracaoFuncionario : strings.cadastroFuncionario}</SttHeading>
                                            {/* Dados gerais */}
                                            <SttExpansionPanel
                                                title={strings.dadosGerais}
                                                opened={secoesAbertas['dadosGerais']}
                                                callback={estadoInterno => abrirSecao('dadosGerais', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosGerais}></div>
                                                        <DadosGerais
                                                            carregando={carregando}
                                                            cpfPesquisa={cpfPesquisa}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosContato}
                                                opened={secoesAbertas['dadosContato']}
                                                callback={estadoInterno => abrirSecao('dadosContato', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosContato}></div>
                                                        <DadosContato
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosProfissionais}
                                                opened={secoesAbertas['dadosProfissionais']}
                                                callback={estadoInterno => abrirSecao('dadosProfissionais', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosProfissionais}></div>
                                                        <DadosProfissionais
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                            profissoesSolicitacoesPendentes={profissoesSolicitacoesPendentes}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            {
                                                temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL) &&
                                                <>
                                                    <SttExpansionPanel
                                                        title={temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL) ? strings.residencia : strings.maisMedicosResidencia}
                                                        classegriditem={classes.expansionPanel}
                                                        opened={secoesAbertas['dadosParticipacao']}
                                                        callback={estadoInterno => abrirSecao('dadosParticipacao', estadoInterno)}
                                                        children={
                                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                <div ref={secaoDadosParticipacao}></div>
                                                                <DadosParticipacao />
                                                            </Suspense>
                                                        }
                                                    />
                                                    <Divider />
                                                </>
                                            }


                                            <SttExpansionPanel
                                                title={strings.vinculos}
                                                opened={secoesAbertas['vinculo']}
                                                callback={estadoInterno => abrirSecao('vinculo', estadoInterno)}
                                                classegriditem={classes.expansionPanel}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosVinculo}></div>
                                                        <DadosVinculo
                                                            user={user}
                                                        />
                                                    </Suspense>
                                                }
                                            />

                                            <Divider />

                                            {
                                                (
                                                    temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR) ||
                                                    temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_TELEMEDICINA) ||
                                                    temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_TELESSAUDE) ||
                                                    temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_ESTADUAL) ||
                                                    temPerfil(user, CONSTANTES_PERFIS.SUPORTE_USUARIO) ||
                                                    temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL)
                                                ) &&
                                                <>
                                                    < SttExpansionPanel
                                                        title={strings.servicos}
                                                        opened={secoesAbertas['dadosServico']}
                                                        callback={estadoInterno => abrirSecao('dadosServico', estadoInterno)}
                                                        classegriditem={classes.expansionPanel}
                                                        children={
                                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                <div ref={secaoDadosServico}></div>
                                                                <DadosServico
                                                                    user={user}
                                                                />
                                                            </Suspense>
                                                        }
                                                    />

                                                    <Divider />
                                                </>
                                            }

                                            <SttExpansionPanel
                                                title={strings.usuarios}
                                                opened={secoesAbertas['dadosUsuario']}
                                                callback={estadoInterno => abrirSecao('dadosUsuario', estadoInterno)}
                                                classegriditem={classes.expansionPanel}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosUsuario}></div>
                                                        <DadosUsuario />
                                                    </Suspense>
                                                }
                                            />

                                            <Divider />

                                            <div className={classes.buttonWrapper}>
                                                {
                                                    idsSolicitacoesPendentes.length > 0 &&
                                                    UtilTriagem.verificarPodeAprovarTriagem(user, values.vinculo.instituicoes) &&
                                                    <SttButton
                                                        nomarginleft="true"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            setAprovarAutoCadastro(true);
                                                            verificarSecoesComErro(validateForm);
                                                        }}
                                                    >
                                                        {strings.aprovar}
                                                    </SttButton>
                                                }

                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    nomarginleft="true"
                                                    onClick={() => verificarSecoesComErro(validateForm)}
                                                >
                                                    {strings.salvar}
                                                </SttButton>

                                                {logins.length > 0 && (
                                                    <SttButton
                                                        type="button"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            const parametrosAlerta = {
                                                                titulo: strings.atencao,
                                                                mensagem: strings.confirmacaoDeslogarUsuario,
                                                                tipo: 'alert',
                                                                open: true,
                                                                opcoes: [
                                                                    {
                                                                        title: strings.sim,
                                                                        onClick: () => {
                                                                            deslogarUsuario(logins);
                                                                            setOpen(false);
                                                                        },
                                                                    },
                                                                    {
                                                                        title: strings.nao,
                                                                        onClick: () => {
                                                                            setOpen(false);
                                                                        },
                                                                    },
                                                                ],
                                                            };

                                                            setParametrosAlerta(parametrosAlerta);
                                                            return true;
                                                        }}
                                                    >
                                                        {strings.deslogarFuncionario}
                                                    </SttButton>
                                                )}
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :

                    (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }

            <Carregando
                open={carregando}
                text={strings.carregando}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        idFuncionarioAlteracao: state.cadastroFuncionarioReducer.idFuncionarioAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIdFuncionarioAlteracao: id => dispatch(setIdFuncionarioAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroFuncionario')(Solicitacao));