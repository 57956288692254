import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttPaper,
    SttBox,
    SttGrid
} from '@stt-componentes/core';
import Aviso from '../../aviso/aviso';
import Solicitacao from '../../solicitacao';
import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';
import themeUfpa from '../../../../../themeUfpa';
import { ThemeProvider } from '@material-ui/core/styles';
const { SITUACAO } = ConstantesSolicitacaoCadastro;

const useStyles = makeStyles(theme => ({
    boxAviso: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: 'column',
        width: "100%",
        backgroundColor: '#93c01f'
    },
    boxSolicitarCadastro: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: 'column',
        width: "100%",
        maxWidth: "1200px"
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    forms: {
        alignItems: "stretch",
        justifyContent: "center",
        flexDirection: 'row',
        display: "flex",
        paddingBottom: theme.spacing(3),
        flexWrap: "wrap",
        maxWidth: "900px"
    },
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: "2px solid",
        borderColor: theme.palette.background.border,
        display: "flex",
        marginTop: theme.spacing(1),
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1.5, 1.5, 0.5, 1.5),
        },
    }
}));

const ConteudoUfpa = ({ aviso, layout }) => {
    const classes = useStyles();

    const box = (aviso.situacaoSolicitacao === SITUACAO.SOLICITACAO_CADASTRO) ? classes.boxSolicitarCadastro : classes.boxAviso;

    return (
        <SttBox className={box}>
            <SttGrid container className={classes.forms}>
                {
                    (aviso.situacaoSolicitacao === SITUACAO.SOLICITACAO_CADASTRO) ? (
                        <SttGrid item xs={12}>
                            <SttPaper className={classes.formColumn}>
                                <Solicitacao />
                            </SttPaper>
                        </SttGrid>
                    ) : (
                            <SttGrid item md={8} sm={9} xs={12}>
                                <SttPaper className={classes.formColumn}>
                                    <ThemeProvider theme={themeUfpa}>
                                        <Aviso />
                                    </ThemeProvider>
                                </SttPaper>
                            </SttGrid>
                        )
                }
            </SttGrid>
        </SttBox>
    );
}

export default connect(state => ({
    aviso: state.solicitacaoCadastroReducer.aviso
}))(ConteudoUfpa);