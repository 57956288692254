export const DADOS_GERAIS = 'dadosGerais';
export const CPF = 'cpf';
export const NOME = 'nome';
export const DATA_NASCIMENTO = 'dataNascimento';
export const NOME_MAE = 'nomeMae';
export const GENERO = 'genero';
export const UF = 'uf';
export const MUNICIPIO = 'municipio';
export const SITUACAO_CADASTRO = 'situacaoCadastro';
export const EMAIL = 'email';
export const LOGIN = 'login';
export const ID = 'id';
export const SITUACAO = 'situacao';
export const MOTIVO_RECUSA = 'motivoRecusa';