import ConstantesPesquisaInvalidacaoExame from '@constantes/pesquisa/invalidacao-exame';

const { REDUCER_TYPE } = ConstantesPesquisaInvalidacaoExame

const INITIAL_STATE = {
    parametrosPesquisa: {
        rede_telemedicina: null,
        descricao: '',
        situacao: { sigla: 'T', descricao: 'Todos' }
    }
};

function pesquisaInvalidacaoExameReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if (type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }
    return state;
};

export default pesquisaInvalidacaoExameReducer;