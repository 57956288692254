import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import FormPesquisa from './form';
import { useNavigate, useLocation } from "react-router-dom";
import translate from '@componentes/translate';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttSeeButton,
    SttHeading
} from '@stt-componentes/core';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "@redux/actions/alerta";
import { PONTOS } from '@common/Constants';
import {
    setPontoAlteracao as setPontoAlteracaoAction
} from '@redux/actions/cadastro/ponto';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        DATA: 'data_inclusao',
        CNES: 'cnes',
        INSTITUICAO: 'instituicao',
        CIDADE: 'cidade',
        SETOR: 'setor',
        NOME: 'nome',
        TIPO: 'tipo',
        SITUACAO: 'situacao',
    }
}

const Pesquisa = ({ strings, setPontoAlteracao }) => {
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);

    //Solicitação
    const [pontos, setPontos] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setPontos(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
        setPontos([]);
        setTotalRegistros(0);
    }

    const clickEditarPonto = (idPonto) => {
        setPontoAlteracao(idPonto);
        navigate(`/cadastro/ponto`);
    };

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>
            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="7%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}
                                    >
                                        {strings.data}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="7%" sortDirection={orderBy === ORDENACAO.CAMPOS.CNES ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CNES}
                                        direction={orderBy === ORDENACAO.CAMPOS.CNES ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CNES)}
                                    >
                                        {strings.cnes}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.INSTITUICAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.INSTITUICAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.INSTITUICAO)}
                                    >
                                        {strings.instituicao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}
                                    >
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.SETOR ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.SETOR}
                                        direction={orderBy === ORDENACAO.CAMPOS.SETOR ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SETOR)}
                                    >
                                        {strings.setor}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}
                                    >
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="8%" sortDirection={orderBy === ORDENACAO.CAMPOS.TIPO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.TIPO}
                                        direction={orderBy === ORDENACAO.CAMPOS.TIPO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.TIPO)}
                                    >
                                        {strings.tipo}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="8%" sortDirection={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}
                                    >
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">{strings.tituloPagina}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={8} align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    pontos.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={8} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        pontos.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.data}</SttTableCell>
                                                    <SttTableCell>{row.cnes}</SttTableCell>
                                                    <SttTableCell>{row.instituicao}</SttTableCell>
                                                    <SttTableCell>{row.cidade} / {row.uf}</SttTableCell>
                                                    <SttTableCell>{row.setor}</SttTableCell>
                                                    <SttTableCell>{row.nome}</SttTableCell>
                                                    <SttTableCell>
                                                        {
                                                            PONTOS.TIPOS.find(t => t.identificador === row.tipo).descricao
                                                        }
                                                    </SttTableCell>
                                                    <SttTableCell>
                                                        {
                                                            PONTOS.SITUACAO.find(s => s.identificador === row.situacao).descricao
                                                        }
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.data}:</b> {row.data}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cnes}:</b> {row.cnes}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.instituicao}:</b> {row.instituicao}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cidadeUf}:</b> {row.cidade} / ${row.uf}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.setor}:</b> {row.setor}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.nome}:</b> {row.nome}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.tipo}:</b>
                                                            {
                                                                PONTOS.TIPOS.find(t => t.identificador === row.tipo).descricao
                                                            }
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.situacao}:</b>
                                                            {
                                                                PONTOS.SITUACAO.find(s => s.identificador === row.situacao).descricao
                                                            }
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        id={`btn-ver-funcionario-${index}`}
                                                        onClick={() => {
                                                            clickEditarPonto(row.id);
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                pontos.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }
        </>
    )
}

Pesquisa.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPontoAlteracao: id => dispatch(setPontoAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaPontos')(Pesquisa));