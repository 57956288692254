import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import translate from '@componentes/translate';
import axios from 'axios';
import { getHeaders } from '@src/request';
import { estadoInicial } from './estadoInicial';
import { temPerfil } from '@security/acl';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '@redux/actions/pesquisa/invalidacao-exame';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttAutocomplete
} from '@stt-componentes/core';
import {
    REDE_TELEMEDICINA,
    DESCRICAO,
    SITUACAO
} from './fieldNames';
import {
    CONSTANTES_PERFIS
} from '@common/Constants';

const FormPesquisa = (props) => {
    const {
        user,
        setParametrosPesquisa,
        limparParametrosPesquisa,
        callbackAndamento,
        callbackLimpar,
        parametrosPesquisa,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
        strings
    } = props;

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
     //Filtros
    const formRef = useRef();
    const [redeTelemedicina, setRedeTelemedicina] = useState([]);
    const situacoes = [
        { sigla: 'T', descricao: 'Todos' },
        { sigla: 'A', descricao: 'Ativos', boolean: true},
        { sigla: 'I', descricao: 'Inativos', boolean: false}
    ];

    useEffect(() => {
        axios
            .get(`${ADM_API_BASE_URL}/rede-telemedicina`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setRedeTelemedicina(itens);
                    if (buscar && formRef.current) {
                        formRef.current.handleSubmit();
                    }
                }
            })
            .catch(err => console.log(err));
    }, [buscar]);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados.rede_telemedicina) {
            filtros.rede_telemedicina = dados.rede_telemedicina.id;
        }

        if (dados.descricao) {
            filtros.descricao = dados.descricao;
        }

        if (dados.situacao) {
            filtros.situacao = dados.situacao.boolean;         
        }

        setParametrosPesquisa(dados);
        return filtros;
    };

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={parametrosPesquisa}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/invalidacao-exame`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: []
                                });

                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            values,
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}
                                    >
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                        <Field name={REDE_TELEMEDICINA}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.redeTelemedicina
                                                                    }}
                                                                    getOptionLabel={option => option && option.descricao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={redeTelemedicina || []}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(REDE_TELEMEDICINA, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid> 

                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <FastField name={DESCRICAO}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    label={strings.descricao}
                                                                    inputProps={{
                                                                        maxLength: 100,
                                                                        style: { textTransform: "uppercase" }
                                                                    }}
                                                                    value={field.value ? field.value : ''}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                                        <Field name={SITUACAO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.situacao
                                                                    }}
                                                                    getOptionLabel={option => option && option.descricao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.sigla === valueOptionSelected.sigla}
                                                                    options={situacoes}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(SITUACAO, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>

                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        callbackLimpar();
                                                    }}
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        limparParametrosPesquisa();
                                                        callbackLimpar();
                                                        resetForm({
                                                            values: estadoInicial
                                                        });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaInvalidacaoExameReducer.parametrosPesquisa
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaInvalidacaoExame')(FormPesquisa));








