import moment from "moment";

export const estadoInicial = {
    funcionario: '',
    evento: '',
    categoriaStt1: null,
    categoriaStt2: null,
    'data-de': moment().subtract(1, 'months').format(),
    'data-ate': moment().format(),
};
