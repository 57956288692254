import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import translate from '@src/componentes/translate';
import axios from 'axios';
import FormularioTriagem from './solicitacao/formulario';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttDivider,
    SttTable,
    SttTableBody,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttText,
    SttLoading
} from '@stt-componentes/core';
import { getHeaders } from '@src/request';
import { Util } from '@stt-utilitarios/core';
import TIPOS_PARTICIPACOES from '@src/constantes/participacao';
import {
    CONSTANTES_ORIGEM_INSTITUICAO_VINCULO,
    CONSTANTES_ACOMPANHAR_FACEBOOK_TRIAGEM_AUTOCADASTRO,
    CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO
} from '@common/Constants';
import Moment from 'react-moment';
import UtilTriagem from '@util/triagem';
import {
    setIdFuncionarioAlteracao as setIdFuncionarioAlteracaoAction
} from '@redux/actions/cadastro/funcionario/index';

import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    textoQuebraLinha: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-all'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    divWrapperMovimentacao: {
        marginBottom: theme.spacing(1)
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    semResultado: {
        fontWeight: 'bold'
    },
    textItem: {
        textTransform: 'uppercase'
    },
    circulo: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        display: 'inline-block',
        border: '1px solid black'
    },
    label: {
        alignSelf: 'center',
        marginLeft: '5px'
    }
}));

const DetalhesSolicitacaoAutoCadastro = (props) => {
    const {
        id,
        voltar,
        strings,
        setParametrosAlerta,
        setOpen,
        setBuscar,
        setIdSolicitacaoCadastro,
        user
    } = props;

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const classes = useStyles();
    const [detalhesSolicitacao, setdetalhesSolicitacao] = useState(null);
    const navigate = useNavigate();

    const [carregando, setCarregando] = useState(false);

    //Carregando
    const [carregado, setCarregado] = useState(false);

    const [triarAutoCadastro, setTriarAutoCadastro] = useState(false);

    useEffect(() => {
        if (id) {
            axios
                .get(`${ADM_API_BASE_URL}/triagem/${id}`, { headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    setdetalhesSolicitacao(formatarDadosExibicao(dados));

                    //Verifica se o cadastro está como aguardando triagem e seta o mesmo para pendente
                    if (dados.situacao === 'A') {
                        atualizarCadastroPendente();
                    } else {
                        setCarregado(true);
                    }
                })
                .catch(err => {
                    setCarregado(true);
                    console.log(err);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const atualizarCadastroPendente = () => {
        axios
            .post(`${ADM_API_BASE_URL}/atualizar-cadastro-pendente`, { id: id }, { headers: getHeaders() })
            .then(() => {
                setCarregando(false);
                setCarregado(true);
            })
            .catch(err => { console.log(err); });
    }

    const formatarDadosExibicao = (dados) => {

        if (dados.cpf) {
            dados.cpf = ('00000000000' + dados.cpf.replace(/[\. ,:-]+/g, "")).slice(-11);
        }

        if ((dados.telefone_comercial && dados.telefone_comercial !== '') || (dados.telefone_celular && dados.telefone_celular !== '')) {
            dados.contatos = [];

            if (dados.telefone_comercial && dados.telefone_comercial !== '') {
                dados.contatos.push({
                    contato: Util.util.adicionarMascaraContato(dados.telefone_comercial, 3),
                    categoria: 3,
                    descricao: 'Telefone comercial'
                });
            }

            if (dados.telefone_celular && dados.telefone_celular !== '') {
                dados.contatos.push({
                    contato: Util.util.adicionarMascaraContato(dados.telefone_celular, 2),
                    categoria: 2,
                    descricao: 'Telefone celular'
                });
            }
        }

        if (dados.acompanhar_facebook) {
            switch (dados.acompanhar_facebook) {
                case CONSTANTES_ACOMPANHAR_FACEBOOK_TRIAGEM_AUTOCADASTRO.SIM:
                    dados.acompanhar_facebook = strings.sim;
                    break;
                case CONSTANTES_ACOMPANHAR_FACEBOOK_TRIAGEM_AUTOCADASTRO.NAO:
                    dados.acompanhar_facebook = strings.nao;
                    break;
                default:
                    dados.acompanhar_facebook = strings.naoRespondeu;
            }
        }

        if (dados.situacao) {
            switch (dados.situacao) {
                case CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_APROVADO:
                    dados.situacao_formatada = strings.cadastroAprovado;
                    break;

                case CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_REJEITADO:
                    dados.situacao_formatada = strings.cadastroRejeitado;
                    break;

                case CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_PENDENTE:
                    dados.situacao_formatada = strings.cadastroPendente;
                    break;

                default:
                    dados.situacao_formatada = strings.aguardandoTriagem;
            }
        }
        let participacao = {
            maisMedicos: [],
            provab: [],
            residencia: []
        };

        if (dados.participacao_mais_medicos) {
            participacao.maisMedicos.push(
                {
                    descricao: strings.maisMedicos,
                    tipoParticipacao: TIPOS_PARTICIPACOES[0],
                    dataInicial: dados.data_inicio_participacao_mais_medicos,
                    dataFinal: null
                }
            );
        }

        if (dados.participacao_provab) {
            participacao.provab.push(
                {
                    descricao: strings.provab,
                    tipoParticipacao: TIPOS_PARTICIPACOES[1],
                    dataInicial: dados.data_inicio_participacao_provab,
                    dataFinal: null
                }
            );
        }

        if (dados.residencia) {
            participacao.residencia.push(
                {
                    descricao: strings.residencia,
                    tipoParticipacao: TIPOS_PARTICIPACOES[2],
                    dataInicial: dados.data_inicio_residencia,
                    dataFinal: null
                }
            );
        }

        dados.participacao = participacao;

        dados.vinculo = {};
        dados.vinculo.instituicoes = [];
        dados.vinculo.equipes = [];

        if (dados.vinculos) {
            //Adicionar instituições diferentes
            dados.vinculos.forEach(vinculo => {
                const instituicaoFormatada = {
                    id_instituicao: vinculo.id_instituicao,
                    nome: vinculo.nome_instituicao,
                    cnes: vinculo.cnes_instituicao,
                    municipio: vinculo.municipio,
                    estado: vinculo.estado,
                    origem_instituicao: vinculo.origem_instituicao,
                    ativo: true
                }

                if (dados.vinculo.instituicoes.findIndex(instAdicionada => instAdicionada.id_instituicao === instituicaoFormatada.id_instituicao) === -1) {
                    //Não foi adicionada esta instituição ainda
                    dados.vinculo.instituicoes.push(instituicaoFormatada);
                }

                let equipeFormatada = {};
                if (vinculo.nome_equipe_saude_familia) {
                    equipeFormatada.nome = vinculo.nome_equipe_saude_familia;
                    equipeFormatada.id_equipe_saude_familia = vinculo.id_equipe_saude_familia;
                    equipeFormatada.id_instituicao = vinculo.id_instituicao;
                    equipeFormatada.nome_instituicao = vinculo.nome_instituicao;
                    equipeFormatada.municipio = vinculo.municipio;
                    equipeFormatada.estado = vinculo.estado;
                    equipeFormatada.cidade_uf_equipe = `${vinculo.municipio}/${vinculo.estado}`
                    equipeFormatada.ativo = true;

                    dados.vinculo.equipes.push(equipeFormatada);
                }
            });
        }

        return dados;
    };

    const callbackVoltar = () => {
        voltar(true);
    }

    const handleCorFundo = (inst) => {
        if (inst.origem_instituicao === CONSTANTES_ORIGEM_INSTITUICAO_VINCULO.CADSUS) {
            return 'rgb(138, 197, 139)';
        }
    }

    const aprovarCadastro = () => {
        const parametrosAlertaAtualizacaoCadastroPendente = {
            titulo: strings.atencao,
            mensagem: strings.desejaAprovarAutoCadastro,
            tipo: 'alert',
            open: true,
            opcoes: [
                {
                    title: strings.sim,
                    onClick: () => {
                        setCarregando(true);
                        axios
                            .post(`${ADM_API_BASE_URL}/aprovar-auto-cadastro`, { id: id }, { headers: getHeaders() })
                            .then(() => {
                                const parametrosAlertaAprovacaoCadastro = {
                                    titulo: strings.atencao,
                                    mensagem: strings.aprovacaoCadastro,
                                    tipo: 'success',
                                    open: true,
                                    opcoes: [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setOpen(false);
                                                callbackVoltar();
                                            }
                                        }
                                    ]
                                };
                                setParametrosAlerta(parametrosAlertaAprovacaoCadastro);
                            })
                            .catch(err => { console.log(err); })
                            .finally(() => {
                                setCarregando(false);
                            });
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        setOpen(false);
                    }
                }
            ]
        };
        setParametrosAlerta(parametrosAlertaAtualizacaoCadastroPendente);
    }

    const tornarPendente = (id) => {
        const parametrosAlerta = {
            titulo: strings.confirmacao,
            mensagem: strings.confirmacaoTornarPendente,
            tipo: 'alert',
            open: true,
            opcoes: [
                {
                    title: strings.sim,
                    onClick: () => {
                        setCarregando(true);
                        setOpen(false);
                        axios
                            .post(`${ADM_API_BASE_URL}/atualizar-cadastro-pendente`, { id: id }, { headers: getHeaders() })
                            .then(() => {
                                setCarregando(false);
                                callbackVoltar();
                                const parametrosAlertaAtualizacaoCadastroPendente = {
                                    titulo: strings.atencao,
                                    mensagem: strings.solicitacaoPendenteAtualizada,
                                    tipo: 'alert',
                                    open: true,
                                    opcoes: [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setOpen(false);
                                            }
                                        }
                                    ]
                                };
                                setParametrosAlerta(parametrosAlertaAtualizacaoCadastroPendente);
                            })
                            .catch(err => { console.log(err); });
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        setOpen(false);
                    }

                }
            ]
        };
        setParametrosAlerta(parametrosAlerta);
    }

    const irParaCadastro = (id) => {
        navigate("/pesquisa/funcionario", { state: { idFuncionario: id } });
    }

    const Legenda = () => {
        return (
            <>
                <div style={{ display: 'flex', flexFlow: 'wrap', gap: '10px' }}>
                    <span className={classes.circulo} style={{ backgroundColor: 'rgb(138, 197, 139)' }} />
                    <label style={{ alignSelf: 'center', marginLeft: '5px' }}>{strings.legendaInstituicaoVinculoCadsus}</label>

                    <span className={classes.circulo} style={{ backgroundColor: '#ffffff' }} />
                    <label style={{ alignSelf: 'center', marginLeft: '5px' }}>{strings.legendaInstituicaoVinculoStt}</label>
                </div>
            </>
        );
    }

    return (
        <>
            {
                !triarAutoCadastro ?
                    <SttContainer>
                        <SttButton
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={callbackVoltar}
                            nomarginleft="true"
                        >
                            {strings.voltar}
                        </SttButton>
                        {
                            (!carregado)
                                ?
                                <div className={classes.carregando}>
                                    <SttCircularProgress color="primary" />
                                </div>
                                :
                                <>
                                    <SttHeading variant="h1" color="primary" align="center">{strings.visualizacaoFuncionario}</SttHeading>
                                    <SttExpansionPanel
                                        title={strings.dadosGerais}
                                        children={
                                            <div>
                                                {detalhesSolicitacao.nome && <SttTextItem classContent={classes.textItem} key="gerais_1" title={strings.nome} content={detalhesSolicitacao.nome} />}
                                                {detalhesSolicitacao.cpf && <SttTextItem classContent={classes.textItem} key="gerais_2" title={strings.cpf} content={Util.util.adicionarMascara(detalhesSolicitacao.cpf, '000.000.000-00')} />}
                                                {detalhesSolicitacao.acompanhar_facebook && <SttTextItem classContent={classes.textItem} key="gerais_3" title={strings.acompanharFacebook} content={detalhesSolicitacao.acompanhar_facebook} />}
                                                {detalhesSolicitacao.data_hora_solicitacao_formatada && <SttTextItem classContent={classes.textItem} key="gerais_4" title={strings.dataHoraSolicitacao} content={detalhesSolicitacao.data_hora_solicitacao_formatada} />}
                                                {detalhesSolicitacao.data_nascimento_formatada && <SttTextItem classContent={classes.textItem} key="gerais_5" title={strings.dataNascimento} content={detalhesSolicitacao.data_nascimento_formatada} />}
                                                {detalhesSolicitacao.email_pessoal && <SttTextItem classContent={classes.textItem} key="gerais_6" title={strings.email} content={detalhesSolicitacao.email_pessoal} />}
                                                {detalhesSolicitacao.situacao_formatada && <SttTextItem classContent={classes.textItem} key="gerais_8" title={strings.situacao} content={detalhesSolicitacao.situacao_formatada} />}
                                                {detalhesSolicitacao.nome_mae && <SttTextItem classContent={classes.textItem} key="gerais_9" title={strings.nomeMae} content={detalhesSolicitacao.nome_mae} />}
                                                {detalhesSolicitacao.descricao_sexo && <SttTextItem classContent={classes.textItem} key="gerais_10" title={strings.sexo} content={detalhesSolicitacao.descricao_sexo} />}
                                                {detalhesSolicitacao.cidade_uf && <SttTextItem classContent={classes.textItem} key="gerais_11" title={strings.cidade} content={detalhesSolicitacao.cidade_uf} />}
                                                {
                                                    detalhesSolicitacao.numero_conselho &&
                                                    detalhesSolicitacao.sigla_uf_conselho_trabalho &&
                                                    detalhesSolicitacao.descricao_conselho_trabalho &&
                                                    <SttTextItem classContent={classes.textItem} key="gerais_12" title={strings.conselhoTrabalho} content={`${detalhesSolicitacao.descricao_conselho_trabalho} ${detalhesSolicitacao.numero_conselho}/${detalhesSolicitacao.sigla_uf_conselho_trabalho}`} />
                                                }
                                                {detalhesSolicitacao.motivo_recusa && <SttTextItem classContent={classes.textItem} key="gerais_13" title={strings.motivoRecusa} content={detalhesSolicitacao.motivo_recusa} />}
                                                {detalhesSolicitacao.profissao && <SttTextItem classContent={classes.textItem} key="gerais_7" title={strings.profissaoInformada} content={detalhesSolicitacao.profissao} />}
                                                {detalhesSolicitacao.logins && <SttTextItem classContent={classes.textItem} key="gerais_1" title={strings.login} content={detalhesSolicitacao.logins} />}
                                            </div>
                                        }
                                    />

                                    {
                                        detalhesSolicitacao.contatos && detalhesSolicitacao.contatos.length > 0 &&
                                        <SttExpansionPanel
                                            title={strings.dadosContato}
                                            children={
                                                <div>
                                                    {
                                                        detalhesSolicitacao.contatos.map((contato, index) => {
                                                            if (contato.contato && contato.contato !== '') {
                                                                return <SttTextItem classContent={classes.textItem} key={`contato_${index}`} title={contato.descricao} content={contato.contato} />
                                                            }
                                                        })
                                                    }
                                                </div>
                                            }
                                        />
                                    }

                                    {
                                        detalhesSolicitacao.participacao &&
                                        <SttExpansionPanel
                                            title={strings.maisMedicosResidencia}
                                            children={
                                                <div>
                                                    <SttTable stickyHeader>
                                                        <SttTableHead>
                                                            <SttTableRow>
                                                                <SttHidden xsDown>
                                                                    <SttTableCell width="40%">{strings.participacao}</SttTableCell>
                                                                    <SttTableCell width="25%">{strings.dataInicial}</SttTableCell>
                                                                    <SttTableCell width="25%">{strings.dataFinal}</SttTableCell>
                                                                </SttHidden>
                                                                <SttHidden smUp>
                                                                    <SttTableCell width="100%" colSpan="3">{strings.participacao}</SttTableCell>
                                                                </SttHidden>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                (
                                                                    detalhesSolicitacao.participacao.maisMedicos.length > 0 ||
                                                                    detalhesSolicitacao.participacao.provab.length > 0 ||
                                                                    detalhesSolicitacao.participacao.residencia.length > 0
                                                                ) ? (
                                                                    <>
                                                                        <>
                                                                            {
                                                                                detalhesSolicitacao.participacao.maisMedicos &&
                                                                                detalhesSolicitacao.participacao.maisMedicos.map((part, indice) => (
                                                                                    <SttTableRow key={part.id}>
                                                                                        <SttHidden xsDown>
                                                                                            <SttTableCell>{part.descricao}</SttTableCell>
                                                                                            <SttTableCell>
                                                                                                <Moment format="DD/MM/YYYY">
                                                                                                    {part.dataInicial}
                                                                                                </Moment>
                                                                                            </SttTableCell>
                                                                                            <SttTableCell>
                                                                                                {
                                                                                                    part.dataFinal ?
                                                                                                        <Moment format="DD/MM/YYYY">
                                                                                                            {part.dataFinal}
                                                                                                        </Moment> :
                                                                                                        strings.naoInformado
                                                                                                }
                                                                                            </SttTableCell>
                                                                                        </SttHidden>
                                                                                        <SttHidden smUp>
                                                                                            <SttTableCell width="96%">
                                                                                                <SttText size="small" classContent={classes.textItem}>
                                                                                                    <b>{strings.participacao}:</b> {part.descricao}
                                                                                                </SttText>
                                                                                                <SttText size="small">
                                                                                                    <b>{strings.dataInicial}:</b>
                                                                                                    <Moment format="DD/MM/YYYY">
                                                                                                        {part.dataInicial}
                                                                                                    </Moment>
                                                                                                </SttText>
                                                                                                {
                                                                                                    <SttText size="small">
                                                                                                        <b>{strings.dataFinal}:</b>
                                                                                                        {
                                                                                                            part.dataFinal ?
                                                                                                                <Moment format="DD/MM/YYYY">
                                                                                                                    {part.dataFinal}
                                                                                                                </Moment> :
                                                                                                                strings.naoInformado
                                                                                                        }
                                                                                                    </SttText>
                                                                                                }
                                                                                            </SttTableCell>
                                                                                        </SttHidden>
                                                                                    </SttTableRow>
                                                                                ))
                                                                            }
                                                                        </>

                                                                        <>
                                                                            {
                                                                                detalhesSolicitacao.participacao.provab &&
                                                                                detalhesSolicitacao.participacao.provab.map((part, indice) => (
                                                                                    <SttTableRow key={part.id}>
                                                                                        <SttHidden xsDown>
                                                                                            <SttTableCell>{part.descricao}</SttTableCell>
                                                                                            <SttTableCell>
                                                                                                <Moment format="DD/MM/YYYY">
                                                                                                    {part.dataInicial}
                                                                                                </Moment>
                                                                                            </SttTableCell>
                                                                                            <SttTableCell>
                                                                                                {
                                                                                                    part.dataFinal ?
                                                                                                        <Moment format="DD/MM/YYYY">
                                                                                                            {part.dataFinal}
                                                                                                        </Moment> :
                                                                                                        strings.naoInformado
                                                                                                }
                                                                                            </SttTableCell>
                                                                                        </SttHidden>
                                                                                        <SttHidden smUp>
                                                                                            <SttTableCell width="96%">
                                                                                                <SttText size="small">
                                                                                                    <b>{strings.participacao}:</b> {part.descricao}
                                                                                                </SttText>
                                                                                                <SttText size="small">
                                                                                                    <b>{strings.dataInicial}:</b>
                                                                                                    <Moment format="DD/MM/YYYY">
                                                                                                        {part.dataInicial}
                                                                                                    </Moment>
                                                                                                </SttText>
                                                                                                {
                                                                                                    <SttText size="small">
                                                                                                        <b>{strings.dataFinal}:</b>
                                                                                                        {
                                                                                                            part.dataFinal ?
                                                                                                                <Moment format="DD/MM/YYYY">
                                                                                                                    {part.dataFinal}
                                                                                                                </Moment> :
                                                                                                                strings.naoInformado
                                                                                                        }
                                                                                                    </SttText>
                                                                                                }
                                                                                            </SttTableCell>
                                                                                        </SttHidden>
                                                                                    </SttTableRow>
                                                                                ))
                                                                            }
                                                                        </>

                                                                        <>
                                                                            {
                                                                                detalhesSolicitacao.participacao.residencia &&
                                                                                detalhesSolicitacao.participacao.residencia.map((part, indice) => (
                                                                                    <SttTableRow key={part.id}>
                                                                                        <SttHidden xsDown>
                                                                                            <SttTableCell>{part.descricao}</SttTableCell>
                                                                                            <SttTableCell>
                                                                                                <Moment format="DD/MM/YYYY">
                                                                                                    {part.dataInicial}
                                                                                                </Moment>
                                                                                            </SttTableCell>
                                                                                            <SttTableCell>
                                                                                                {
                                                                                                    part.dataFinal ?
                                                                                                        <Moment format="DD/MM/YYYY">
                                                                                                            {part.dataFinal}
                                                                                                        </Moment> :
                                                                                                        strings.naoInformado
                                                                                                }
                                                                                            </SttTableCell>
                                                                                        </SttHidden>
                                                                                        <SttHidden smUp>
                                                                                            <SttTableCell width="96%">
                                                                                                <SttText size="small">
                                                                                                    <b>{strings.participacao}:</b> {part.descricao}
                                                                                                </SttText>
                                                                                                <SttText size="small">
                                                                                                    <b>{strings.dataInicial}:</b>
                                                                                                    <Moment format="DD/MM/YYYY">
                                                                                                        {part.dataInicial}
                                                                                                    </Moment>
                                                                                                </SttText>
                                                                                                <SttText size="small">
                                                                                                    <b>{strings.dataFinal}:</b>
                                                                                                    {
                                                                                                        part.dataFinal ?
                                                                                                            <Moment format="DD/MM/YYYY">
                                                                                                                {part.dataFinal}
                                                                                                            </Moment> :
                                                                                                            strings.naoInformado
                                                                                                    }
                                                                                                </SttText>
                                                                                            </SttTableCell>
                                                                                        </SttHidden>
                                                                                    </SttTableRow>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    </>


                                                                ) : (
                                                                    <SttTableRow key={-1}>
                                                                        <SttTableCell colSpan={3}
                                                                            align="center"
                                                                            className={classes.semResultado}>
                                                                            {strings.nenhumRegistroEncontrado}
                                                                        </SttTableCell>
                                                                    </SttTableRow>
                                                                )
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </div>
                                            }
                                        />
                                    }

                                    {
                                        detalhesSolicitacao.vinculo &&
                                        <SttExpansionPanel
                                            title={strings.vinculo}
                                            children={
                                                <div>
                                                    <div style={{ marginBottom: '10px' }}>
                                                        <SttTable stickyHeader>
                                                            <>
                                                                <SttHidden smDown>
                                                                    <caption colSpan={3}><Legenda /></caption>
                                                                </SttHidden>
                                                                <SttHidden mdUp>
                                                                    <caption width="96%"><Legenda /></caption>
                                                                </SttHidden>
                                                            </>
                                                            <SttTableHead>
                                                                <SttTableRow>
                                                                    <SttHidden xsDown>
                                                                        <SttTableCell width="45%">{strings.instituicao}</SttTableCell>
                                                                        <SttTableCell width="45%">{strings.cidadeUf}</SttTableCell>
                                                                        <SttTableCell width="10%">{strings.situacao}</SttTableCell>
                                                                    </SttHidden>
                                                                    <SttHidden smUp>
                                                                        <SttTableCell width="100%" colSpan="3">{strings.instituicao}</SttTableCell>
                                                                    </SttHidden>
                                                                </SttTableRow>
                                                            </SttTableHead>
                                                            <SttTableBody>
                                                                {
                                                                    (
                                                                        detalhesSolicitacao.vinculo.instituicoes.length > 0
                                                                    ) ? (
                                                                        detalhesSolicitacao.vinculo.instituicoes.map((inst, indice) => (
                                                                            <SttTableRow key={inst.id} style={{ backgroundColor: handleCorFundo(inst) }}>
                                                                                <SttHidden xsDown>
                                                                                    <SttTableCell>{inst.nome}</SttTableCell>
                                                                                    <SttTableCell>{inst.municipio}/{inst.estado}</SttTableCell>
                                                                                    <SttTableCell>{inst.ativo ? 'Ativo' : 'Inativo'}</SttTableCell>
                                                                                </SttHidden>
                                                                                <SttHidden smUp>
                                                                                    <SttTableCell width="96%">
                                                                                        <SttText size="small">
                                                                                            <b>{strings.instituicao}:</b> {inst.nome}
                                                                                        </SttText>
                                                                                        <SttText size="small">
                                                                                            <b>{strings.cidadeUf}:</b> {inst.municipio}/{inst.estado}
                                                                                        </SttText>
                                                                                        <SttText size="small">
                                                                                            <b>{strings.situacao}:</b> {inst.ativo ? 'Ativo' : 'Inativo'}
                                                                                        </SttText>
                                                                                    </SttTableCell>
                                                                                </SttHidden>
                                                                            </SttTableRow>
                                                                        ))
                                                                    ) : (
                                                                        <SttTableRow key={-1}>
                                                                            <SttTableCell colSpan={3}
                                                                                align="center"
                                                                                className={classes.semResultado}>
                                                                                {strings.nenhumRegistroEncontrado}
                                                                            </SttTableCell>
                                                                        </SttTableRow>
                                                                    )
                                                                }
                                                            </SttTableBody>
                                                        </SttTable>
                                                    </div>
                                                    {
                                                        <div style={{ marginBottom: '10px' }}>
                                                            <SttTable stickyHeader>
                                                                <SttTableHead>
                                                                    <SttTableRow>
                                                                        <SttHidden xsDown>
                                                                            <SttTableCell width="40%">{strings.equipeSaudeFamilia}</SttTableCell>
                                                                            <SttTableCell width="25%">{strings.instituicao}</SttTableCell>
                                                                            <SttTableCell width="25%">{strings.cidadeUf}</SttTableCell>
                                                                            <SttTableCell width="10%">{strings.situacao}</SttTableCell>
                                                                        </SttHidden>
                                                                        <SttHidden smUp>
                                                                            <SttTableCell width="100%" colSpan="4">{strings.equipeSaudeFamilia}</SttTableCell>
                                                                        </SttHidden>
                                                                    </SttTableRow>
                                                                </SttTableHead>
                                                                <SttTableBody>
                                                                    {
                                                                        (
                                                                            detalhesSolicitacao.vinculo.equipes.length > 0
                                                                        ) ? (
                                                                            detalhesSolicitacao.vinculo.equipes.map((equipe, indice) => (
                                                                                <SttTableRow key={equipe.id}>
                                                                                    <SttHidden xsDown>
                                                                                        <SttTableCell>{equipe.nome}</SttTableCell>
                                                                                        <SttTableCell>{equipe.nome_instituicao}</SttTableCell>
                                                                                        <SttTableCell>{equipe.cidade_uf_equipe}</SttTableCell>
                                                                                        <SttTableCell>{equipe.ativo ? 'Ativo' : 'Inativo'}</SttTableCell>
                                                                                    </SttHidden>
                                                                                    <SttHidden smUp>
                                                                                        <SttTableCell width="96%">
                                                                                            <SttText size="small">
                                                                                                <b>{strings.equipeSaudeFamilia}:</b> {equipe.nome}
                                                                                            </SttText>
                                                                                            <SttText size="small">
                                                                                                <b>{strings.instituicao}:</b> {equipe.nome_instituicao}
                                                                                            </SttText>
                                                                                            <SttText size="small">
                                                                                                <b>{strings.cidadeUf}:</b> {equipe.cidade_uf_equipe}
                                                                                            </SttText>
                                                                                            <SttText size="small">
                                                                                                <b>{strings.situacao}:</b> {equipe.ativo ? 'Ativo' : 'Inativo'}
                                                                                            </SttText>
                                                                                        </SttTableCell>
                                                                                    </SttHidden>
                                                                                </SttTableRow>
                                                                            ))
                                                                        ) : (
                                                                            <SttTableRow key={-1}>
                                                                                <SttTableCell colSpan={4}
                                                                                    align="center"
                                                                                    className={classes.semResultado}>
                                                                                    {strings.nenhumRegistroEncontrado}
                                                                                </SttTableCell>
                                                                            </SttTableRow>
                                                                        )
                                                                    }
                                                                </SttTableBody>
                                                            </SttTable>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        />
                                    }
                                    <SttDivider />
                                    <div className={classes.buttonWrapper}>
                                        {
                                            (detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.AGUARDANDO_TRIAGEM ||
                                                detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_PENDENTE) &&
                                            !detalhesSolicitacao.id_funcionario &&
                                            <SttButton
                                                nomarginleft='true'
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => { setTriarAutoCadastro(true); }}
                                            >
                                                {strings.triarAutoCadastro}
                                            </SttButton>
                                        }

                                        {
                                            (detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.AGUARDANDO_TRIAGEM ||
                                                detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_PENDENTE) &&
                                            detalhesSolicitacao.id_funcionario &&
                                            UtilTriagem.verificarPodeAprovarTriagem(user, detalhesSolicitacao.vinculos) &&
                                            <SttButton
                                                nomarginleft='true'
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => { aprovarCadastro(true); }}
                                            >
                                                {strings.aprovar}
                                            </SttButton>
                                        }

                                        {
                                            (detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_APROVADO ||
                                                ((detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.AGUARDANDO_TRIAGEM ||
                                                    detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_PENDENTE) &&
                                                    detalhesSolicitacao.id_funcionario)) &&
                                            <SttButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => { irParaCadastro(detalhesSolicitacao.id_funcionario_cadastrado) }}
                                            >
                                                {strings.irParaCadastro}
                                            </SttButton>
                                        }

                                        {
                                            detalhesSolicitacao.situacao === CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO.CADASTRO_REJEITADO &&
                                            <SttButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => { tornarPendente(detalhesSolicitacao.id) }}
                                            >
                                                {strings.tornarPendente}
                                            </SttButton>
                                        }

                                    </div>

                                    <SttLoading
                                        open={carregando}
                                        text={strings.carregando}
                                    />
                                </>
                        }
                    </SttContainer>
                    : <FormularioTriagem dados={detalhesSolicitacao} id={id} setBuscar={setBuscar} setIdSolicitacaoCadastro={setIdSolicitacaoCadastro} voltar={voltar} />
            }
        </>
    )
}

DetalhesSolicitacaoAutoCadastro.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        funcionario: state.index.funcionario
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIdFuncionarioAlteracao: id => dispatch(setIdFuncionarioAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('DetalhesSolicitacaoAutoCadastro')(DetalhesSolicitacaoAutoCadastro));