const MODAL = {
    REDUCER_TYPE: {
        ALTERAR_PARAMETROS_PESQUISA_INSTITUICAO: 'ALTERAR_PARAMETROS_PESQUISA',
        PESQUISAR_INSTITUICAO: 'PESQUISAR_INSTITUICAO',
        BUSCA_INSTITUICAO_FINALIZADA: 'BUSCA_INSTITUICAO_FINALIZADA',
        LIMPAR_FILTROS_PESQUISA_INSTITUICAO: 'LIMPAR_FILTROS_PESQUISA_INSTITUICAO',
        INSTITUICAO_SELECIONADA: 'INSTITUICAO_SELECIONADA',

        PARTICIPACAO_SELECIONADA: 'PARTICIPACAO_SELECIONADA',

        EQUIPE_SELECIONADA: 'EQUIPE_SELECIONADA',
    }
};

export default MODAL;