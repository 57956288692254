export const CONTATO = {
    CATEGORIA: {
        TELEFONE_RESIDENCIAL: 1,
        TELEFONE_CELULAR: 2,
        TELEFONE_COMERCIAL: 3,
        EMAIL: 4,
        FAX: 5,
        OUTRO: 6,
    },
};

export const PERFIL = {
    ADMINISTRADOR: 'administrador',
};

export const TIPO_USUARIO = {
    INTERNO: 'INTERNO',
    EXTERNO: 'EXTERNO',
};

export const CONSTANTES_PERFIS = {
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_TELESSAUDE: 'adminTelessaude',
    ADMINISTRADOR_TELEMEDICINA: 'adminTelemedicina',
    SUPER_USUARIO: 'superUsuario',
    ADMINISTRADOR_LOCAL: 'adminLocal',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    TECNICO: 'tecnico',
    SUPORTE_USUARIO: 'suporAoUsuar'
};

export const CONSTANTES_TIPO_OCUPACAO = {
    CLASSIFICADA: 'C',
    NAO_CLASSIFICADA: 'NC',
};

export const CONTANTES_POSSUI_CONSELHO_TRABALHO = {
    SIM: 'S',
    NAO: 'N',
};

export const CONSTANTES_SITUACAO_CADASTRO = {
    CADASTRO_CONFIRMADO_ADMINISTRADOR: 'A',
    CADASTRO_CONFIRMADO_FUNCIONARIO: 'F',
    AGUARDANDO_CONFIRMACAO_FUNCIONARIO: 'G',
    AGUARDANDO_CONFIRMACAO_ADMINISTRADOR: 'U',
};

export const DESCRICAO_SITUACAO_CADASTRO = {
    A: 'Cadastro confirmado pelo administrador do sistema',
    F: 'Cadastro confirmado pelo funcionário',
    G: 'Cadastro aguardando confirmação pelo funcionário',
    U: 'Cadastro aguardando confirmação pelo administrador do sistema',
};

export const CONSTANTES_ORIGEM_INSTITUICAO_VINCULO = {
    STT: 'S',
    CADSUS: 'C',
};

export const CONSTANTES_SITUACAO_TRIAGEM_AUTOCADASTRO = {
    AGUARDANDO_TRIAGEM: 'A',
    CADASTRO_APROVADO: 'P',
    CADASTRO_REJEITADO: 'R',
    CADASTRO_PENDENTE: 'E',
};

export const CONSTANTES_ACOMPANHAR_FACEBOOK_TRIAGEM_AUTOCADASTRO = {
    SIM: 'S',
    NAO: 'N',
};

export const PERMISSOES = {
    ADMINISTRATIVO: 'ADMINISTRATIVO_ADMINISTRATIVO',
    PESQUISA_PERFIL: 'ADMINISTRATIVO_PESQUISAR_PERFIL',
    PESQUISA_EQUIPES: 'ADMINISTRATIVO_PESQUISAR_EQUIPE',
    PESQUISAR_PACIENTE: 'ADMINISTRATIVO_PESQUISAR_PACIENTE',
    PESQUISAR_PACS: 'ADMINISTRATIVO_PESQUISAR_PACS',
    PESQUISA_MOTIVOS_INVALIDACAO: 'ADMINISTRATIVO_PESQUISAR_MOT_INV',
    CADASTRAR_PACS: 'ADMINISTRATIVO_CADASTRAR_PACS',
    CADASTRO_PERFIL: 'ADMINISTRATIVO_CADASTRAR_PERFIL',
    CADASTRAR_PACIENTE: 'ADMINISTRATIVO_CADASTRAR_PACIENTE',
    DELECAO_PERFIL: 'ADMINISTRATIVO_DELETAR_PERFIL',
    PESQUISA_TRIAGEM_ACESSO_INSTITUICAO: 'ADMINISTRATIVO_TRIAGEM_INSTITUICAO',
    PESQUISA_FUNCIONARIO: 'ADMINISTRATIVO_PESQUISAR_FUNCION',
    CADASTRO_FUNCIONARIO: 'ADMINISTRATIVO_CADASTRAR_FUNCION',
    PERMISSOES: 'ADMINISTRATIVO_PERMISSOES',
    TRIAGEM_CADASTRO: 'ADMINISTRATIVO_TRIAR_CADASTRO',
    CADASTRO_REDE_TELEMEDICINA: 'ADMINISTRATIVO_CADASTRAR_REDTELEMED',
    PESQUISA_REDE_TELEMEDICINA: 'ADMINISTRATIVO_PESQUISAR_REDTELEMED',
    PESQUISA_MENSAGEM: 'ADMINISTRATIVO_PESQUISAR_MENSAGEM',
    CADASTRO_MENSAGEM: 'ADMINISTRATIVO_CADASTRAR_MENSAGEM',
    PESQUISA_PONTO: 'ADMINISTRATIVO_PESQUISAR_PONTO',
    CADASTRO_PONTO: 'ADMINISTRATIVO_CADASTRAR_PONTO',
    CADASTRO_ESF: 'ADMINISTRATIVO_CADASTRAR_ESF',
    CADASTRO_CIDADE_REF: 'ADMINISTRATIVO_CADASTRAR_CIDADE_REF',
    CADASTRO_MOTIVOS_INVALIDACAO: 'ADMINISTRATIVO_CADASTRAR_MOT_INV',
    CADASTRO_MATERIAIS_SUPORTE: 'ADMINISTRATIVO_CADASTRAR_MAT_SUP',
    PESQUISA_MATERIAIS_SUPORTE: 'ADMINISTRATIVO_PESQUISAR_MAT_SUP',
    ACESSO_CNES_CADSUS: 'ADMINISTRATIVO_ACESSO_CNES_CADSUS',
    CADASTRAR_INST: 'ADMINISTRATIVO_CADASTRAR_INST',
    PESQUISAR_INST: 'ADMINISTRATIVO_PESQUISAR_INST',
    PESQUISAR_LOG_FUNCIONARIO: 'ADMINISTRATIVO_PESQUISAR_LOGFUNC',
};

export const VINCULO_PERFIL_ESTADUAL = 'E';
export const VINCULO_PERFIL_INSTITUICOES = 'I';

const TIPO_TELEDIAGNOSTICO = 'X';
const TIPO_BRIDGE = 'B';

const SITUACAO_ATIVO = 'A';
const SITUACAO_INATIVO = 'I';
const SITUACAO_BLOQUEADO = 'B';
const SITUACAO_DESATIVADO = 'D';

export const MENSAGENS = {};

export const MYME_TYPE_ARQUIVOS = {
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    PNG: 'image/png',
};

export const MYME_TYPE_PERMITIDOS = Object.values(MYME_TYPE_ARQUIVOS);

export const PONTOS = {
    REDUCER_TYPE: {
        SETAR_PARAMETROS_PESQUISA: 'SETAR_PARAMETROS_PESQUISA',
        LIMPAR_PARAMETROS_PESQUISA: 'LIMPAR_PARAMETROS_PESQUISA',
    },
    TIPO_TELEDIAGNOSTICO,
    TIPO_BRIDGE,
    TIPOS: [
        {
            identificador: TIPO_TELEDIAGNOSTICO,
            descricao: 'Telediagnóstico',
        },
        {
            identificador: TIPO_BRIDGE,
            descricao: 'Bridge',
        },
    ],
    SITUACAO_ATIVO,
    SITUACAO_INATIVO,
    SITUACAO_BLOQUEADO,
    SITUACAO_DESATIVADO,
    SITUACAO: [
        {
            identificador: SITUACAO_ATIVO,
            descricao: 'Ativo',
        },
        {
            identificador: SITUACAO_INATIVO,
            descricao: 'Inativo',
        },
        {
            identificador: SITUACAO_BLOQUEADO,
            descricao: 'Bloqueado',
        },
        {
            identificador: SITUACAO_DESATIVADO,
            descricao: 'Desativado',
        },
    ],
    OPERACOES: {
        DESATIVAR: 'desativar',
        ATIVAR: 'ativar',
        BLOQUEAR: 'bloquear',
        DESBLOQUEAR: 'desbloquear',
    },
};

export const MYME_TYPE_ARQUIVOS_MATERIAIS = {
    PDF: 'application/pdf',
};

export const MYME_TYPE_PERMITIDOS_MATERIAIS = Object.values(MYME_TYPE_ARQUIVOS_MATERIAIS);

export const ORIGEM_AUTENTICACAO_EXTERNA = {
    AZURE_AD: 'AZURE_AD'
}