/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    MODALIDADE,
    DESCRICAO
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DESCRICAO]: yup.string().required(strings.campoObrigatorio),
        [MODALIDADE]: yup.object().shape({
            id: yup.number(),
            descricao: yup.string(),
            sigla: yup.string()
        }).nullable().required(strings.campoObrigatorio),
    });
};
