
import {
    CPF,
    CNS,
    NOME,
    DATA_NASCIMENTO,
    PROTOCOLO_EXAME,
    PACIENTES
} from './fieldNames';

export const estadoInicial = {
    [CPF]: null,
    [CNS]: null,
    [NOME]: '',
    [DATA_NASCIMENTO]: null,
    [PROTOCOLO_EXAME]: '',
    [PACIENTES]: []
};