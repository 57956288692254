import TYPES from '@redux/types/pesquisa/pacs';

import { PACS } from '@componentes/interno/pesquisa/pacs/fieldNames';
import { estadoInicial } from '@componentes/interno/pesquisa/pacs/estadoInicial';

const INITIAL_STATE = estadoInicial;

function pesquisaPacsReducer(state = INITIAL_STATE, action) {
    const { type, payload } = action;

    if (type === TYPES.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...INITIAL_STATE,
        };
    }

    if (type === TYPES.SETAR_PARAMETROS_PESQUISA) {
        return { ...state, ...payload.parametros };
    }

    if (type === TYPES.SETAR_RESULTADO_PESQUISA) {
        return { ...state, [PACS]: payload };
    }

    return state;
}

export default pesquisaPacsReducer;
