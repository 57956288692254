import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    UF,
    MUNICIPIO
} from './fieldNames';
import { SttAutocomplete } from '@stt-componentes/core';
import translate from '@componentes/translate';
import { getHeaders } from '@src/request';

const Municipio = (props) => {
    const { form, field, obrigatorio, somenteLeitura, meta, municipioCep, strings } = props;
    const { name, value, onBlur } = field;
    const { setFieldValue, values } = form;

    const [municipios, setMunicipios] = useState([]);
    const [municipiosCarregando, setMunicipiosCarregando] = useState(true);

    useEffect(() => {
        const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
        if (values[UF] && values[UF].id) {
            setMunicipiosCarregando(true);
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${values[UF].id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setMunicipios(itens);
                        if (value) {
                            if (Number.isInteger(value)) {
                                const municipio = itens.find(m => m.id === value)
                                setFieldValue(`${MUNICIPIO}`, municipio);
                            }
                        } else if (municipioCep) {
                            const municipio = itens.filter(item => item.codigo_ibge === municipioCep);
                            setFieldValue(`${MUNICIPIO}`, municipio[0]);
                        }
                    } else {
                        setMunicipios([]);
                    }
                    setMunicipiosCarregando(false);
                })
                .catch(err => console.log(err));
        } else {
            setMunicipios([]);
            setMunicipiosCarregando(false);
        }
    }, [values[UF], value]);

    return (
        <SttAutocomplete
            inputprops={{
                name: name,
                label: strings.municipio,
                required: obrigatorio,
                error: meta.touched && meta.error ? meta.error : undefined,
                disabled: !values[UF]?.id
            }}
            getOptionLabel={option => option && option.nome || ''}
            getOptionSelected={(option, valueOptionSelected) => {
                if (option && valueOptionSelected) {
                    if (valueOptionSelected.id) {
                        return option.id === valueOptionSelected.id
                    } else {
                        return option.id === valueOptionSelected;
                    }
                }
                return false;
            }
            }
            options={municipios}
            value={value}
            onBlur={onBlur}
            disabled={somenteLeitura}
            carregando={municipiosCarregando}
            onChange={(e, item) => setFieldValue(`${MUNICIPIO}`, item || null)}
        />
    )
}

export default translate('Paciente')(Municipio);
