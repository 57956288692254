import TYPES from '@redux/types/pesquisa/invalidacao-exame';

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SETAR_PARAMETROS_PESQUISA,
        payload: { parametros }
    };
}

export const limparParametrosPesquisa = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA
    };
}