import TIPOS_PARTICIPACOES from '@constantes/participacao';
import {
    DADOS_GERAIS,
    CPF,
    NOME,
    DATA_NASCIMENTO,
    NOME_MAE,
    GENERO,
    UF,
    MUNICIPIO,
    SITUACAO_CADASTRO,
    AUTORIZAR_DIVULGACAO,
} from '@componentes/interno/cadastro/funcionario/dadosGerais/form/fieldNames';

import {
    CBO_FAMILIA,
    CBO_OCUPACAO,
    DADOS_PROFISSIONAIS,
    TIPO_OCUPACAO,
    OCUPACAO_NAO_CLASSIFICADA,
    POSSUI_CONSELHO_TRABALHO,
    CONSELHO_TRABALHO,
    NUMERO_CONSELHO_TRABALHO,
    UF_CONSELHO_TRABALHO,
    TIPO_CONSELHO_TRABALHO,
    RQE,
} from '@componentes/interno/cadastro/funcionario/dadosProfissionais/form/fieldNames';

import {
    DADOS_CONTATO,
    TELEFONE_RESIDENCIAL,
    TELEFONE_CELULAR,
    TELEFONE_COMERCIAL
} from '@componentes/interno/cadastro/funcionario/dadosContato/form/fieldNames';

import {
    PARTICIPACOES,
} from '@componentes/interno/cadastro/funcionario/dadosParticipacao/form/fieldNames';

import {
    VINCULO,
    INSTITUICOES,
    EQUIPES,
    SETORES,
    MODALIDADES
} from '@componentes/interno/cadastro/funcionario/dadosVinculo/fieldNames';

import {
    SERVICO,
} from '@componentes/interno/cadastro/funcionario/dadosServico/fieldNames';

import {
    USUARIOS,
} from '@componentes/interno/cadastro/funcionario/dadosUsuario/fieldNames';

import {
    CONSTANTES_TIPO_OCUPACAO,
    CONTANTES_POSSUI_CONSELHO_TRABALHO
} from '@common/Constants';

import moment from 'moment';

const inicializarValoresFormulario = (user, dadosAlteracao) => {
    let formulario = {};

    if (dadosAlteracao.data_nascimento) {
        dadosAlteracao.data_nascimento = moment.utc(dadosAlteracao.data_nascimento);
        dadosAlteracao.data_nascimento = dadosAlteracao.data_nascimento.set({ h: 12 }).toISOString();
    }

    formulario[DADOS_GERAIS] = {
        [CPF]: dadosAlteracao.cpf ? ('00000000000' + dadosAlteracao.cpf.replace(/[\. ,:-]+/g, "")).slice(-11) : '', // eslint-disable-line
        [NOME]: dadosAlteracao.nome || '',
        [DATA_NASCIMENTO]: dadosAlteracao.data_nascimento || null,
        [NOME_MAE]: dadosAlteracao.nome_mae || '',
        [GENERO]: dadosAlteracao.id_sexo || null,
        [UF]: dadosAlteracao.id_estado || null,
        [MUNICIPIO]: dadosAlteracao.id_cidade || null,
        [SITUACAO_CADASTRO]: dadosAlteracao.situacao_cadastro || 'A',
        [AUTORIZAR_DIVULGACAO]: dadosAlteracao.receber_divulgacao_autorizada ?? false
    };

    formulario[DADOS_CONTATO] = {};
    if (dadosAlteracao.contatos) {
        let contatoResidencial = dadosAlteracao.contatos.find(c => c.categoria === 1);
        let contatoCelular = dadosAlteracao.contatos.find(c => c.categoria === 2);
        let contatoComercial = dadosAlteracao.contatos.find(c => c.categoria === 3);
        formulario[DADOS_CONTATO][TELEFONE_RESIDENCIAL] = "";
        formulario[DADOS_CONTATO][TELEFONE_CELULAR] = "";
        formulario[DADOS_CONTATO][TELEFONE_COMERCIAL] = "";

        if (contatoResidencial?.contato) {
            contatoResidencial.contato = contatoResidencial.contato.replace(/[^a-zA-Z0-9]/g, '');
            formulario[DADOS_CONTATO][TELEFONE_RESIDENCIAL] = contatoResidencial.contato.substring(contatoResidencial.contato.length - 10);
        }

        if (contatoCelular?.contato) {
            contatoCelular.contato = contatoCelular.contato.replace(/[^a-zA-Z0-9]/g, '');
            formulario[DADOS_CONTATO][TELEFONE_CELULAR] = contatoCelular.contato.substring(contatoCelular.contato.length - 11);
        }

        if (contatoComercial?.contato) {
            contatoComercial.contato = contatoComercial.contato.replace(/[^a-zA-Z0-9]/g, '');
            formulario[DADOS_CONTATO][TELEFONE_COMERCIAL] = contatoComercial.contato.substring(contatoComercial.contato.length - 10);
        }

    }

    formulario[DADOS_PROFISSIONAIS] = {};
    //Setar se a ocupação é do tipo classificada ou não-classificada
    if (dadosAlteracao.id_ocupacao_nao_classificada) {
        formulario[DADOS_PROFISSIONAIS][TIPO_OCUPACAO] = CONSTANTES_TIPO_OCUPACAO.NAO_CLASSIFICADA;
    } else {
        formulario[DADOS_PROFISSIONAIS][TIPO_OCUPACAO] = CONSTANTES_TIPO_OCUPACAO.CLASSIFICADA
    }

    formulario[DADOS_PROFISSIONAIS][CBO_FAMILIA] = dadosAlteracao.id_cbo_familia || null;
    formulario[DADOS_PROFISSIONAIS][CBO_OCUPACAO] = dadosAlteracao.id_cbo_ocupacao || null;
    formulario[DADOS_PROFISSIONAIS][OCUPACAO_NAO_CLASSIFICADA] = dadosAlteracao.id_ocupacao_nao_classificada || null;

    if (dadosAlteracao && !dadosAlteracao.conselhoTrabalho?.length) {
        //Não possui conselho de trabalho
        formulario[DADOS_PROFISSIONAIS][POSSUI_CONSELHO_TRABALHO] = CONTANTES_POSSUI_CONSELHO_TRABALHO.NAO;
    } else {
        //Possui conselho de trabalho OU é um cadastro novo
        formulario[DADOS_PROFISSIONAIS][POSSUI_CONSELHO_TRABALHO] = CONTANTES_POSSUI_CONSELHO_TRABALHO.SIM;
        formulario[DADOS_PROFISSIONAIS][CONSELHO_TRABALHO] = [];
        dadosAlteracao.conselhoTrabalho.forEach(conselho => {
            formulario[DADOS_PROFISSIONAIS][CONSELHO_TRABALHO].push({
                [UF_CONSELHO_TRABALHO]: conselho.id_uf,
                [TIPO_CONSELHO_TRABALHO]: conselho.id_conselho_trabalho,
                [NUMERO_CONSELHO_TRABALHO]: conselho.numero
            });
        });
        formulario[DADOS_PROFISSIONAIS][RQE] = [];
        dadosAlteracao.rqe?.forEach(rqe => {
            formulario[DADOS_PROFISSIONAIS][RQE].push(rqe.numero);
        });
    }


    formulario[PARTICIPACOES] = [];

    if (dadosAlteracao.participacao) {
        if (dadosAlteracao.participacao.maisMedicos?.length) {
            dadosAlteracao.participacao.maisMedicos.forEach(participacao => {
                if (participacao.data_inicial) {
                    participacao.data_inicial = moment.utc(participacao.data_inicial);
                    participacao.data_inicial = participacao.data_inicial.set({ h: 12 }).toISOString();
                }

                if (participacao.data_final) {
                    participacao.data_final = moment.utc(participacao.data_final);
                    participacao.data_final = participacao.data_final.set({ h: 12 }).toISOString();
                }
                formulario[PARTICIPACOES].push(
                    {
                        tipoParticipacao: TIPOS_PARTICIPACOES[0],
                        dataInicial: participacao.data_inicial,
                        dataFinal: participacao.data_final,
                    }
                );
            });
        }

        if (dadosAlteracao.participacao.provab?.length) {
            dadosAlteracao.participacao.provab.forEach(participacao => {
                if (participacao.data_inicial) {
                    participacao.data_inicial = moment.utc(participacao.data_inicial);
                    participacao.data_inicial = participacao.data_inicial.set({ h: 12 }).toISOString();
                }

                if (participacao.data_final) {
                    participacao.data_final = moment.utc(participacao.data_final);
                    participacao.data_final = participacao.data_final.set({ h: 12 }).toISOString();
                }

                formulario[PARTICIPACOES].push(
                    {
                        tipoParticipacao: TIPOS_PARTICIPACOES[1],
                        dataInicial: participacao.data_inicial,
                        dataFinal: participacao.data_final,
                    }
                );
            });
        }

        if (dadosAlteracao.participacao.residencia?.length) {
            dadosAlteracao.participacao.residencia.forEach(participacao => {
                if (participacao.data_inicial) {
                    participacao.data_inicial = moment.utc(participacao.data_inicial);
                    participacao.data_inicial = participacao.data_inicial.set({ h: 12 }).toISOString();
                }

                if (participacao.data_final) {
                    participacao.data_final = moment.utc(participacao.data_final);
                    participacao.data_final = participacao.data_final.set({ h: 12 }).toISOString();
                }

                formulario[PARTICIPACOES].push(
                    {
                        tipoParticipacao: TIPOS_PARTICIPACOES[2],
                        dataInicial: participacao.data_inicial,
                        dataFinal: participacao.data_final,
                    }
                );
            });
        }
    }

    formulario[VINCULO] = {};
    formulario[VINCULO][INSTITUICOES] = [];
    formulario[VINCULO][SETORES] = [];
    formulario[VINCULO][EQUIPES] = [];
    formulario[VINCULO][MODALIDADES] = [];

    if (dadosAlteracao.vinculo) {
        const { instituicoes, setores, equipes } = dadosAlteracao.vinculo;

        if (instituicoes) {
            formulario[VINCULO][INSTITUICOES] = dadosAlteracao.vinculo.instituicoes;
        }

        if (setores) {
            formulario[VINCULO][SETORES] = dadosAlteracao.vinculo.setores;
        }

        if (equipes) {
            formulario[VINCULO][EQUIPES] = dadosAlteracao.vinculo.equipes;
        }
    }

    //Tratamento de modalidades conectadas ao vínculo
    if (dadosAlteracao.modalidadesInstituicao) {
        dadosAlteracao.modalidadesInstituicao.forEach(modalidade => {
            modalidade.tipo_vinculo = 'I';
            if (modalidade.relacao === 'V') {
                formulario[VINCULO][MODALIDADES].push(modalidade);
            }
        });
    }

    formulario[SERVICO] = {};
    formulario[SERVICO][INSTITUICOES] = [];
    formulario[SERVICO][SETORES] = [];
    formulario[SERVICO][EQUIPES] = [];
    formulario[SERVICO][MODALIDADES] = [];

    if (dadosAlteracao.servico) {
        const { instituicoes, setores, equipes } = dadosAlteracao.servico;

        if (instituicoes) {
            formulario[SERVICO][INSTITUICOES] = dadosAlteracao.servico.instituicoes;
        }

        if (setores) {
            formulario[SERVICO][SETORES] = dadosAlteracao.servico.setores;
        }

        if (equipes) {
            formulario[SERVICO][EQUIPES] = dadosAlteracao.servico.equipes;
        }
    }

    //Tratamento de modalidades conectadas ao vínculo
    if (dadosAlteracao.modalidadesInstituicao) {
        dadosAlteracao.modalidadesInstituicao.forEach(modalidade => {
            modalidade.tipo_vinculo = 'I';

            if (modalidade.relacao === 'S') {
                formulario[SERVICO][MODALIDADES].push(modalidade);
            }
        });
    }

    if (dadosAlteracao.modalidadesRede) {
        for (let modalidadeRede of dadosAlteracao.modalidadesRede) {
            modalidadeRede.tipo_vinculo = 'R';
            formulario[SERVICO][MODALIDADES].push(modalidadeRede);
        }

    }

    formulario[USUARIOS] = [];

    if (dadosAlteracao.usuarios) {
        formulario[USUARIOS] = dadosAlteracao.usuarios;

        for (const usuario of formulario[USUARIOS]) {
            if (usuario.ativo) {
                usuario.ativo = 'A';
            } else {
                usuario.ativo = 'I';
            }
            let perfisFormatados = [];
            if (usuario.perfis?.length) {
                for (const perfil of usuario.perfis) {

                    let perfilFormatado = {};
                    perfilFormatado.perfil = {
                        identificador: perfil.identificador,
                        descricao: perfil.descricao,
                        vinculo: perfil.vinculo
                    };

                    if (perfilFormatado.perfil.vinculo === 'I') {
                        perfilFormatado.instituicao = {
                            id_instituicao: perfil.id_instituicao,
                            nome: perfil.nome_instituicao,
                            cnes: perfil.cnes_instituicao
                        }
                    }

                    if (perfilFormatado.perfil.vinculo === 'M') {
                        perfilFormatado.cidade = {
                            id_cidade: perfil.id_cidade,
                            nome: perfil.nome_cidade,
                            sigla_estado: perfil.sigla_estado
                        }
                    }

                    if (perfilFormatado.perfil.vinculo === 'E') {
                        perfilFormatado.estado = {
                            id_estado: perfil.id_estado,
                            nome: perfil.nome_estado,
                            sigla: perfil.sigla_estado
                        }
                    }

                    if (perfilFormatado.perfil.vinculo === 'R') {
                        perfilFormatado.rede = {
                            id: perfil.id_rede_telemedicina,
                            descricao: perfil.descricao_rede
                        }
                    }

                    if (perfilFormatado.perfil.vinculo === 'D') {
                        perfilFormatado.redeTeleconsultoria = {
                            id: perfil.id_cadastro_regiao_autorregulacao,
                            descricao: perfil.descricao_rede_teleconsultoria
                        }
                    }

                    perfisFormatados.push(perfilFormatado);
                }
            }
            usuario.perfis = perfisFormatados;

            //Perfis da ACL para cadastro do STT1
            perfisFormatados = [];

            if (usuario.perfisAcl?.length) {
                for (const perfil of usuario.perfisAcl) {
                    let perfilFormatado = {};
                    perfilFormatado.perfil = {
                        id: perfil.id,
                        descricao: perfil.descricao,
                        vinculo: perfil.vinculo,
                        sigla: perfil.sigla
                    };

                    if (perfilFormatado.perfil.vinculo === 'I' || (perfilFormatado.perfil.vinculo === 'A' && perfil.id_instituicao)) {
                        perfilFormatado.instituicao = {
                            id_instituicao: perfil.id_instituicao,
                            nome: perfil.nome_instituicao,
                            cnes: perfil.cnes_instituicao
                        }
                    }

                    if (perfilFormatado.perfil.vinculo === 'E') {
                        perfilFormatado.estado = {
                            id_estado: perfil.id_estado,
                            nome: perfil.nome_estado,
                            sigla: perfil.sigla_estado
                        }
                    }

                    perfisFormatados.push(perfilFormatado);
                }
            }
            usuario.perfisAcl = perfisFormatados;
        }
    }

    return formulario;
}

const formatarCadastro = (dados) => {
    const formData = new FormData();
    const { dadosGerais, dadosProfissionais, dadosContato, participacoes, vinculo, servico, usuarios } = dados;

    if (dadosGerais.nome && dadosGerais.nome !== '') {
        formData.append('nome', dadosGerais.nome.trim().toUpperCase());
    }


    formData.append('possui_conselho_trabalho', dadosProfissionais.possuiConselhoTrabalho);

    if (dadosGerais.cpf) {
        const cpfFormatado = dadosGerais.cpf.replace(/\D+/g, '');
        formData.append('cpf', cpfFormatado);
    }

    if (dadosGerais.genero) {
        formData.append('id_sexo', dadosGerais.genero.id);
    }

    if (dadosGerais.uf) {
        formData.append('id_estado', dadosGerais.uf.id);
    }

    if (dadosGerais.municipio) {
        formData.append('id_cidade', dadosGerais.municipio.id);
    }

    formData.append('receber_divulgacao_autorizada', dadosGerais.autorizarDivulgacao);
    formData.append('receber_divulgacao_autorizada_modificada', dadosGerais.autorizarDivulgacao != dadosGerais.autorizarDivulgacaoOriginal);

    formData.append('tipo_ocupacao', dadosProfissionais.tipoOcupacao);

    if (dadosProfissionais?.tipoOcupacao === CONSTANTES_TIPO_OCUPACAO.CLASSIFICADA) {
        formData.append('id_cbo_familia', dadosProfissionais.cboFamilia.id);
        formData.append('id_cbo_ocupacao', dadosProfissionais.cboOcupacao.id);
    }

    if (dadosProfissionais?.tipoOcupacao === CONSTANTES_TIPO_OCUPACAO.NAO_CLASSIFICADA) {
        formData.append('id_ocupacao_nao_classificada', dadosProfissionais.ocupacaoNaoClassificada.id);
    }

    if (dadosGerais.situacaoCadastro) {
        formData.append('situacao_cadastro', dadosGerais.situacaoCadastro.identificador);
    }

    if (dadosGerais.dataNascimento) {
        let dataMoment = moment(dadosGerais.dataNascimento);
        if (dataMoment.isValid()) {
            formData.append('data_nascimento', dataMoment.format('YYYY-MM-DD'));
        }
    }

    if (dadosGerais?.nomeMae !== '') {
        formData.append('nome_mae', dadosGerais.nomeMae.trim().toUpperCase());
    }

    // Dados de conselho de trabalho
    if (dadosProfissionais.possuiConselhoTrabalho === CONTANTES_POSSUI_CONSELHO_TRABALHO.SIM) {
        _formatarDadosConselhoTrabalho(dadosProfissionais.conselhoTrabalho, formData);
        if (dadosProfissionais.rqe?.length) {
            _formatarDadosRQE(dadosProfissionais.rqe, formData);
        }
    }

    //Dados de contato
    _formatarDadosContato(dadosContato, formData);

    //Dados de participação
    _formatarDadosParticipacao(participacoes, formData);

    //Dados de vínculo
    _formatarDadosVinculo(vinculo, formData);

    //Dados de serviço
    _formatarDadosServico(servico, formData);

    //Dados do usuário
    _formatarDadosUsuario(usuarios, formData);

    return formData;
};

const _formatarDadosUsuario = (dadosUsuarios, formData) => {
    let usuarios = [];

    dadosUsuarios.forEach(usuario => {
        let usuarioFormatado = {};
        usuarioFormatado.id = usuario.id;
        usuarioFormatado.login = usuario.login;
        usuarioFormatado.email = usuario.email;
        usuarioFormatado.ativo = usuario.ativo === "A" ? true : false;
        usuarioFormatado.usuario_alterado = usuario.usuario_alterado;
        usuarioFormatado.perfis = [];
        usuarioFormatado.perfisAcl = [];

        if (usuario.perfis?.length) {
            usuario.perfis.forEach(perfil => {
                let perfilFormatado = {};
                if (perfil.perfil) {
                    perfilFormatado.id_perfil = perfil.perfil.identificador;
                }

                if (perfil.instituicao?.id_instituicao) {
                    perfilFormatado.id_instituicao = perfil.instituicao.id_instituicao;
                }

                if (perfil.cidade?.id_cidade) {
                    perfilFormatado.id_cidade = perfil.cidade.id_cidade;
                }

                if (perfil.estado?.id_estado) {
                    perfilFormatado.id_estado = perfil.estado.id_estado;
                }

                if (perfil.rede?.id) {
                    perfilFormatado.id_rede = perfil.rede.id;
                }

                if (perfil.redeTeleconsultoria?.id) {
                    perfilFormatado.id_rede_teleconsultoria = perfil.redeTeleconsultoria.id;
                }

                usuarioFormatado.perfis.push(perfilFormatado);
            });
        }

        if (usuario.perfisAcl?.length) {
            usuario.perfisAcl.forEach(perfil => {
                let perfilFormatado = {};
                if (perfil.perfil) {
                    perfilFormatado.id_perfil = perfil.perfil.id;
                }

                if (perfil.instituicao?.id_instituicao) {
                    perfilFormatado.id_instituicao = perfil.instituicao.id_instituicao;
                }

                if (perfil.estado?.id_estado) {
                    perfilFormatado.id_estado = perfil.estado.id_estado;
                }

                usuarioFormatado.perfisAcl.push(perfilFormatado);
            });
        }

        usuarios.push(usuarioFormatado);
    });

    formData.append('usuarios', JSON.stringify(usuarios));
};

const _formatarDadosConselhoTrabalho = (dadosConselhoTrabalho, formData) => {
    let conselhos = [];
    dadosConselhoTrabalho.forEach(conselho => {
        conselhos.push({
            id_uf: conselho.ufConselhoTrabalho?.id,
            id_conselho: conselho.tipoConselhoTrabalho?.id,
            numero: conselho.numeroConselhoTrabalho?.toUpperCase()
        });
    });
    formData.append('conselho_trabalho', JSON.stringify(conselhos));
}

const _formatarDadosRQE = (dadosRqe, formData) => {
    formData.append('rqe', JSON.stringify(dadosRqe));
}

const _formatarDadosVinculo = (dadosVinculo, formData) => {
    let instituicoes = [];
    let setores = [];
    let equipes = [];
    let modalidades = [];

    if (dadosVinculo.instituicoes) {
        dadosVinculo.instituicoes.forEach(instituicao => {
            instituicoes.push(
                {
                    id: instituicao.id,
                    id_instituicao: instituicao.id_instituicao,
                    ativo: instituicao.ativo,
                    origem: instituicao.origem
                }
            );
        });
    }

    if (dadosVinculo.setores) {
        dadosVinculo.setores.forEach(setor => {
            setores.push(
                {
                    id_setor: setor.id_setor,
                    ativo: setor.ativo
                }
            );
        });
    }

    if (dadosVinculo.equipes) {
        dadosVinculo.equipes.forEach(equipe => {
            equipes.push(
                {
                    id_equipe: equipe.id_equipe_saude_familia,
                    ativo: equipe.ativo
                }
            );
        });
    }


    if (dadosVinculo.modalidades) {
        dadosVinculo.modalidades.forEach(modalidade => {
            modalidades.push(
                {
                    id_modalidade: modalidade.id_modalidade,
                    id_instituicao: modalidade.id_instituicao,
                    ativo: modalidade.ativo
                }
            );
        });
    }

    let vinculoFormatado = {
        instituicoes,
        setores,
        equipes,
        modalidades
    };
    formData.append('vinculo', JSON.stringify(vinculoFormatado));
};

const _formatarDadosServico = (dadosServicos, formData) => {
    let instituicoes = [];
    let setores = [];
    let equipes = [];
    let modalidades = [];

    if (dadosServicos.instituicoes) {
        dadosServicos.instituicoes.forEach(instituicao => {
            instituicoes.push(
                {
                    id: instituicao.id,
                    id_instituicao: instituicao.id_instituicao,
                    ativo: instituicao.ativo
                }
            );
        });
    }

    if (dadosServicos.setores) {
        dadosServicos.setores.forEach(setor => {
            setores.push(
                {
                    id: setor.id_setor,
                    ativo: setor.ativo
                }
            );
        });
    }

    if (dadosServicos.equipes) {
        dadosServicos.equipes.forEach(equipe => {
            equipes.push(
                {
                    id_equipe: equipe.id_equipe_saude_familia,
                    ativo: equipe.ativo
                }
            );
        });
    }

    if (dadosServicos.modalidades) {
        dadosServicos.modalidades.forEach(modalidade => {
            let modalidadeFormatada = {};

            modalidadeFormatada.id_modalidade = modalidade.id_modalidade;
            modalidadeFormatada.tipo = modalidade.tipo_vinculo;
            modalidadeFormatada.ativo = modalidade.ativo;

            if (modalidade.tipo_vinculo === 'I') {
                modalidadeFormatada.id_instituicao = modalidade.id_instituicao;
            } else {
                modalidadeFormatada.id_rede_telemedicina = modalidade.rede.id;
            }
            modalidades.push(modalidadeFormatada);
        });
    }

    let vinculoFormatado = {
        instituicoes,
        setores,
        equipes,
        modalidades
    };
    formData.append('servico', JSON.stringify(vinculoFormatado));
};

const _formatarDadosParticipacao = (dadosParticipacoes, formData) => {
    if (dadosParticipacoes?.length) {
        let participacoes = [];
        dadosParticipacoes.forEach(participacao => {
            let umaParticipacao = {};

            umaParticipacao.tipo = participacao.tipoParticipacao.acronimo;
            if (participacao.dataInicial) {
                let dataInicialMoment = moment(participacao.dataInicial);
                if (dataInicialMoment.isValid()) {
                    umaParticipacao.data_inicial = dataInicialMoment.format('YYYY-MM-DD');
                }
            }

            if (participacao.dataFinal) {
                let dataFinalMoment = moment(participacao.dataFinal);
                if (dataFinalMoment.isValid()) {
                    umaParticipacao.data_final = dataFinalMoment.format('YYYY-MM-DD');
                }
            }

            participacoes.push(umaParticipacao);
        });

        //Adiciona as participações somente se alguma participação for adicionada
        if (participacoes.length) {
            formData.append('participacoes', JSON.stringify(participacoes));
        }
    }
};

const _formatarDadosContato = (dadosContato, formData) => {
    let contatosFormatados = [];
    if (dadosContato.telefoneResidencial && dadosContato.telefoneResidencial !== '') {
        contatosFormatados.push(
            {
                id_tipo_contato: 1,
                contato: dadosContato.telefoneResidencial.replace(/\D/g, '')
            }
        );
    }

    if (dadosContato.telefoneCelular && dadosContato.telefoneCelular !== '') {
        contatosFormatados.push(
            {
                id_tipo_contato: 2,
                contato: dadosContato.telefoneCelular.replace(/\D/g, '')
            }
        );
    }

    if (dadosContato.telefoneComercial && dadosContato.telefoneComercial !== '') {
        contatosFormatados.push(
            {
                id_tipo_contato: 3,
                contato: dadosContato.telefoneComercial.replace(/\D/g, '')
            }
        );
    }

    //Adiciona os contatos somente se algum contato for adicionado
    if (contatosFormatados.length) {
        formData.append('contatos', JSON.stringify(contatosFormatados));
    }
};

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [
        DADOS_GERAIS,
        DADOS_CONTATO,
        DADOS_PROFISSIONAIS
    ];
    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
}

export default { inicializarValoresFormulario, verificarErrosOrdemPrecedencia, formatarCadastro };