/* eslint-disable import/no-anonymous-default-export */
const PESQUISA_PERFIL = {
    REDUCER_TYPE: {
        SETAR_PARAMETROS_PESQUISA: 'SETAR_PARAMETROS_PESQUISA',
        LIMPAR_PARAMETROS_PESQUISA: 'LIMPAR_PARAMETROS_PESQUISA'
    },
    VINCULOS: [
        {
            identificador: 'G',
            descricao: 'Geral'
        },
    
        {
            identificador: 'U',
            descricao: 'Usuário'
        },
        {
            identificador: 'I',
            descricao: 'Instituição'
        },
        {
            identificador: 'M',
            descricao: 'Municipal'
        },
        {
            identificador: 'E',
            descricao: 'Estadual'
        },
        {
            identificador: 'R',
            descricao: 'Rede de telemedicina'
        },
        {
            identificador: 'D',
            descricao: 'Rede de teleconsultoria'
        }
    ]
};

export default PESQUISA_PERFIL;