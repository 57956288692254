import ConstantesPesquisaPerfil from '@constantes/pesquisa/perfil';

const { REDUCER_TYPE } = ConstantesPesquisaPerfil;

const INITIAL_STATE = {
    parametrosPesquisa: {
        descricao : '',
        vinculo: null
    }
};

function pesquisaPerfilReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA){
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if(type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA){
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }
    return state;
}

export default pesquisaPerfilReducer;