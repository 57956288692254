import {
    ID,
    NOME,
    CODINOME,
    CNES,
    CNPJ,
    LOGRADOURO,
    BAIRRO,
    CIDADE,
    CEP,
    UF,
    TIPO_ESTABELECIMENTO,
    LOGOTIPO,
    LOGOTIPO_EXCLUIDO,
    URL_LOGOTIPO,
    NOME_ARQUIVO_LOGOTIPO,
    SETORES_INSTITUICAO,
    SETORES_ASSOCIADOS,
    SETORES_EXCLUIDOS,
    CONSULTORIO_VIRTUAL_HABILITADO
} from '@componentes/interno/cadastro/instituicao/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};

    formulario[ID] = dadosAlteracao.id ? dadosAlteracao.id : null;
    formulario[NOME] = dadosAlteracao.nome ? dadosAlteracao.nome : '';
    formulario[CODINOME] = dadosAlteracao.codinome ? dadosAlteracao.codinome : '';
    formulario[CNES] = dadosAlteracao.cnes ? dadosAlteracao.cnes : '';
    formulario[CNPJ] = dadosAlteracao.cnpj ? dadosAlteracao.cnpj : '';
    formulario[LOGRADOURO] = dadosAlteracao.logradouro ? dadosAlteracao.logradouro : '';
    formulario[BAIRRO] = dadosAlteracao.bairro ? dadosAlteracao.bairro : '';
    formulario[UF] = dadosAlteracao.estado ? dadosAlteracao.estado : null;
    formulario[CIDADE] = dadosAlteracao.cidade ? dadosAlteracao.cidade : null;
    formulario[CEP] = dadosAlteracao.cep ? dadosAlteracao.cep : '';
    formulario[TIPO_ESTABELECIMENTO] = dadosAlteracao.tipo_estabelecimento ? dadosAlteracao.tipo_estabelecimento : null;
    formulario[LOGOTIPO] = dadosAlteracao.logotipo ? dadosAlteracao.logotipo : null;
    formulario[LOGOTIPO_EXCLUIDO] = dadosAlteracao.logotipo_excluido ? dadosAlteracao.logotipo_excluido : false;
    formulario[URL_LOGOTIPO] = dadosAlteracao.url_logotipo ? dadosAlteracao.url_logotipo : null;
    formulario[NOME_ARQUIVO_LOGOTIPO] = dadosAlteracao.nome_arquivo_logotipo ? dadosAlteracao.nome_arquivo_logotipo : null;
    formulario[CONSULTORIO_VIRTUAL_HABILITADO] = dadosAlteracao.consultorio_virtual_habilitado ? dadosAlteracao.consultorio_virtual_habilitado : '';
    formulario[SETORES_INSTITUICAO] = dadosAlteracao.setores_instituicao ? dadosAlteracao.setores_instituicao : [];
    formulario[SETORES_ASSOCIADOS] = dadosAlteracao.setores_associados ? dadosAlteracao.setores_associados : [];
    formulario[SETORES_EXCLUIDOS] = dadosAlteracao.setores_excluidos ? dadosAlteracao.setores_excluidos : [];

    return formulario;
};

const formatarCadastro = (dados) => {
    const formData = new FormData();

    if (dados.id) {
        formData.append('id', dados.id);
    }
    formData.append('nome', dados.nome.trim().toUpperCase());
    if (dados.codinome) {
        formData.append('codinome', dados.codinome.toUpperCase());
    }
    formData.append('cnes', dados.cnes);

    if (dados.cnpj && dados.cnpj.length > 0) {
        formData.append('cnpj', parseInt(dados.cnpj.replace(/\D/g, ''), 10));
    }

    formData.append('logradouro', dados.logradouro.trim());
    formData.append('bairro', dados.bairro.trim());
    formData.append('id_cidade', dados.cidade.id);
    formData.append('cep', dados.cep);

    if (dados.tipo_estabelecimento) {
        formData.append('tipo_estabelecimento', dados.tipo_estabelecimento.codigo);
    }

    if (dados.logotipo) {
        formData.append('logotipo_excluido', false);
        formData.append('logotipo', dados.logotipo);
        formData.append('nome_arquivo_logotipo', dados.nome_arquivo_logotipo);
    } else {
        formData.append('logotipo_excluido', true);
    }

    formData.append('url_logotipo', dados.url_logotipo ? dados.url_logotipo : null);

    formData.append('consultorio_virtual_habilitado', dados.consultorio_virtual_habilitado);

    if (dados.setores_instituicao && dados.setores_instituicao.length > 0) {
        const descricaoFormatada = dados.setores_instituicao.map((item) => {
            const id = item.id ? item.id : null
            const descricao = item.descricao ? item.descricao.trim() : ''
            return { id, descricao };
        });
        formData.append('setores_instituicao', JSON.stringify(descricaoFormatada));
    }

    formData.append('setores_associados', dados.setores_associados)
    formData.append('setores_excluidos', JSON.stringify(dados.setores_excluidos))

    return formData;
};

export default { inicializarValoresFormulario, formatarCadastro };