import TYPES from '@redux/types/cadastro/paciente'

export const setUsuarioPaciente = (paciente) => {
    return {
        type: TYPES.SET_USUARIO_PACIENTE,
        payload: { paciente }
    };
}

export const setAccessToken = (accessToken) => {
    return {
        type: TYPES.SET_ACCESS_TOKEN,
        payload: { accessToken }
    };
}