import ConstantesPesquisaFuncionario from '@constantes/pesquisa/funcionario';

const { REDUCER_TYPE } = ConstantesPesquisaFuncionario;

const INITIAL_STATE = {
    parametrosPesquisa: {
        nome : '',
        situacao: null,
        cpf: '',
        email: '',
        numeroConselhoTrabalho: '',
        uf: null,
        cidade: null,
        instituicao: null
    },
    funcionario: {}
};

function pesquisaFuncionarioReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA){
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if(type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA){
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }

    if(type === REDUCER_TYPE.SETAR_FUNCIONARIO_PESQUISA) {
        return {
            ...state,
            funcionario: payload
        };
    }
    return state;
}

export default pesquisaFuncionarioReducer;