/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import { validateBr } from 'js-brasil';
import {
    CPF,
    CNS
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [CPF]: yup
            .string()
            .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                cpf = cpf || '';
                if(cpf.length === 0) {
                    return true;
                }
                return validateBr.cpf(cpf.trim());
            })
            .nullable(),
        [CNS]: yup
            .string()
            .test('cns-valido', strings.cnsInvalido, (cns) => {
                cns = cns || '';
                if(cns.length === 0) {
                    return true;
                }
                return validateBr.cns(cns.trim());
            }) 
            .nullable()
    });
}
