import ConstantesCadastroEquipes from '@constantes/cadastro/equipes/index';

const { REDUCER_TYPE } = ConstantesCadastroEquipes;

const INITIAL_STATE = {
    equipeAlteracao: null
};

function cadastroEquipesReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    const { type, payload } = action;

    if(type === REDUCER_TYPE.SETAR_ID_EQUIPES_ALTERACAO) {
        return {
            ...state,
            equipeAlteracao: payload.equipeAlteracao
        };
    }

    return state;
}

export default cadastroEquipesReducer;