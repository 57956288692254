import React, { useEffect, useState } from "react";
import {
    SttExternalHeader,
    SttExternalFooter
} from '@stt-componentes/core';
import Conteudo from './conteudo/index';
import { makeStyles } from "@material-ui/styles";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        overflow: 'auto'
    }
}));

const SolicitarCadastro = ({ paciente = false }) => {
    const classes = useStyles();

    const [params, setParams] = useSearchParams();
    const [instanciaHeader, setInstanciaHeader] = useState({ logo: [] });
    const [instanciaFooter, setInstanciaFooter] = useState({ logo: [] });

    useEffect(() => {
        if (params.get("layout") && global.gConfig.logo_header_alternativos?.[params.get("layout")]) {
            setInstanciaHeader(
                { logo: global.gConfig.logo_header_alternativos[params.get("layout")] }
            );
            setInstanciaFooter(
                { logo: global.gConfig.logo_footer_alternativos[params.get("layout")] }
            );
        } else {
            setInstanciaHeader(
                { logo: global.gConfig.logo_header }
            );
            setInstanciaFooter(
                { logo: global.gConfig.logo_footer }
            );
        }
    }, []);

    return (
        instanciaHeader &&
        <div className={classes.wrapper}>
            <SttExternalHeader instancia={instanciaHeader} />
            <Conteudo layout={params.get("layout")} paciente={paciente} />
            <SttExternalFooter instancia={instanciaFooter} />
        </div>
    );
}

export default SolicitarCadastro;