/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DADOS_GERAIS,
    NOME,
    DATA_INCLUSAO
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_GERAIS]: yup.object().shape({
            [NOME]: yup
                .string()
                .trim()
                .min(1)
                .max(100)
                .nullable()
                .required(strings.campoObrigatorio),
            [DATA_INCLUSAO]: yup
                .date()
                .typeError(strings.dataInvalida)
                .nullable()
                .required(strings.campoObrigatorio),
        }).required()
    });
}
