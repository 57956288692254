import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import { getHeaders } from '@src/request';
import axios from 'axios';
import PropTypes from 'prop-types';
import translate from '@componentes/translate';
import moment from 'moment';
import HttpStatus from 'http-status-codes';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '@redux/actions/pesquisa/paciente';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttMaskedInput,
    SttDatePicker,
    SttInputProtocol
} from '@stt-componentes/core';

import {
    CPF,
    CNS,
    NOME,
    DATA_NASCIMENTO,
    PROTOCOLO_EXAME
} from './fieldNames';

import validationSchema from './validationSchema';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';

const FormPesquisa = (props) => {
    const {
        parametrosPesquisa,
        setParametrosPesquisa,
        limparParametrosPesquisa,
        setParametrosAlerta,
        setOpen,
        callbackAndamento,
        callbackLimpar,
        callbackBusca,
        page,
        count,
        orderBy,
        order,
        buscar,
        strings,
        handleChangePage
    } = props;

    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const formRef = useRef();
    const schema = validationSchema(strings);

    useEffect(() => {
        if (buscar && formRef.current) {
            submitForm();
        }
    }, [buscar]);

    const submitForm = () => {
        formRef.current.handleSubmit();
    }

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);
        
        dados.page = page;

        dados.limit = count;
        filtros.limit = count;
        
        dados.start = offset;
        filtros.start = offset

        dados.sort = orderBy;
        filtros.sort = orderBy;

        dados.direction = order;
        filtros.direction = order;

        if (dados.cpf) {
            filtros.cpf = dados.cpf.replace(/\D+/g, '');
        }

        if (dados.cartao_sus) {
            filtros.cns = dados.cartao_sus.replace(/\D+/g, '');
        }

        if (dados.nome) {
            filtros.nome = dados.nome.trim();
        }

        if (dados.data_nascimento) {
            filtros['data-nascimento'] = moment(dados.data_nascimento).format('DD-MM-YYYY');
        }

        if (dados.protocolo_exame) {
            filtros['protocolo-exame'] = dados.protocolo_exame.trim();
        }

        setParametrosPesquisa(dados);
        return filtros;
    }

    return (
        <>
            {
                <Formik
                    enableReinitialize
                    innerRef={formRef}
                    initialValues={parametrosPesquisa}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${UTILITARIOS_API_BASE_URL}/paciente`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                const { response } = err;
                                
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: []
                                });
                                let parametrosAlerta = {
                                    titulo: strings.erro,
                                    tipo: 'error',
                                    mensagem: strings.erroGenerico,
                                    opcoes: [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setOpen(false);
                                            }
                                        }
                                    ]
                                };
                                
                                if (response) {
                                    if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                                        const dadosResposta = response.data;
                                        let arrMensagem = [];
                                        dadosResposta.errors.forEach(error => {
                                            arrMensagem.push(`- ${error.message}`);
                                        });
                                        parametrosAlerta = { ...parametrosAlerta, mensagem: arrMensagem.join('\n'), open: true };
                                    } else if (response.status !== HttpStatus.NOT_FOUND) {
                                        parametrosAlerta = { ...parametrosAlerta, open: true };
                                    }
                                } else {
                                    parametrosAlerta = { ...parametrosAlerta, open: true };
                                }
                                setParametrosAlerta(parametrosAlerta);
                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            if (!isValid) {
                                                return;
                                            }
                                            handleChangePage(null, 0);
                                        }
                                    }}>
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                        <FastField name={CPF}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttMaskedInput 
                                                                    { ...field }
                                                                    mask="cpf"
                                                                    label={strings.cpf}
                                                                    error={meta.value && meta.touched && meta.error ? true : false}
                                                                    helperText={meta.value && meta.touched && meta.error || undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                        <Field name={CNS}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttMaskedInput
                                                                    { ...field }
                                                                    mask="cns"
                                                                    label={strings.cns}
                                                                    error={meta.value && meta.touched && meta.error ? true : false}
                                                                    helperText={meta.value && meta.touched && meta.error || undefined}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={6} lg={4}>
                                                        <Field name={NOME}>
                                                            {({
                                                                field
                                                            }) => (
                                                                <SttInput 
                                                                    inputProps={{
                                                                        maxLength: 100
                                                                    }}
                                                                    { ...field }
                                                                    label={strings.nome}
                                                                    onInput={(e) => (e.target.value = ("" + e.target.value).toUpperCase())}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                        <Field name={DATA_NASCIMENTO}>
                                                            {({
                                                                field,
                                                                form,
                                                                meta
                                                            }) => {
                                                                let erroAtual;
                                                                if (form.errors) {
                                                                    erroAtual = form.errors[DATA_NASCIMENTO];
                                                                }
                                                                
                                                                return (<SttDatePicker
                                                                    label={strings.dataNascimento}
                                                                    inputprops={{
                                                                        name: field.name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    invalidDateMessage={strings.dataInvalida}
                                                                    maxDateMessage={strings.erroDataFutura}
                                                                    error={meta.value && meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        if (error && error !== erroAtual) {
                                                                            form.setFieldError(DATA_NASCIMENTO, error);
                                                                        }
                                                                    }}
                                                                    helperText={meta.value && meta.touched && meta.error || undefined}
                                                                    value={field.value}
                                                                    onBlur={() => {
                                                                        form.setFieldTouched(DATA_NASCIMENTO, true);
                                                                    }}
                                                                    onChange={date => form.setFieldValue(DATA_NASCIMENTO, date, true)}
                                                                />)
                                                            }}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                        <Field name={PROTOCOLO_EXAME}>
                                                            {({
                                                                field
                                                            }) => (
                                                                <SttInputProtocol
                                                                    label={strings.protocolo}
                                                                    {...field}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                    onClick={() => handleChangePage(null, 0)}
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        limparParametrosPesquisa();
                                                        callbackLimpar();
                                                        resetForm();
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
}

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaPacienteReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('FormPesquisaPerfis')(FormPesquisa));