import React from 'react';
import { temPermissaoRBAC } from '@security/acl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, permissao, user }) => {
    return temPermissaoRBAC(user, permissao) ? <Component /> : <Navigate replace to="/nao-autorizado" />;
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
    };
};

export default connect(mapStateToProps, null)(ProtectedRoute);
