import ConstantesCadastroPonto from '@constantes/cadastro/pontos';

const { REDUCER_TYPE } = ConstantesCadastroPonto;

const INITIAL_STATE = {
    pontoAlteracao: null
};

function cadastroPerfilReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.SETAR_ID_PONTO_ALTERACAO) {
        return {
            ...state,
            pontoAlteracao: payload.pontoAlteracao
        };
    }
    return state;
}

export default cadastroPerfilReducer;