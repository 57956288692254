import React from "react";
import { connect } from 'react-redux';
import {
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttButton
} from '@stt-componentes/core';
import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';
import translate from "@componentes/translate";

const { REDUCER_TYPE, SITUACAO } = ConstantesSolicitacaoCadastro;

const ConfirmacaoMensagem = ({ aviso, dispatch, classes, strings }) => {

    const handleCheckChange = (checkEntendi) => {
        return {
            type: REDUCER_TYPE.MARCAR_ENTENDI_AVISO,
            checkEntendi: !checkEntendi
        };
    };

    const clickConfirm = () => {
        return {
            type: REDUCER_TYPE.CONFIRMAR_ENTENDI_AVISO,
            situacaoSolicitacao: SITUACAO.BUSCA_CPF
        };
    };

    return (
        <SttFormGroup row className={classes.form}>
            <SttFormControlLabel
                control={
                    <SttCheckbox
                        name="check-aviso"
                        onChange={() => dispatch(handleCheckChange(aviso.checkEntendi))}
                        value={aviso.checkEntendi}
                        color="primary"
                    />
                }
                label={strings.okEntendi}
            />
            <SttButton variant="contained"
                name="btn-confirmar-aviso"
                color="primary"
                nomarginleft="true"
                className={classes.buttons}
                disabled={!aviso.checkEntendi}
                onClick={() => dispatch(clickConfirm())}>
                {strings.confirmar}
            </SttButton>
        </SttFormGroup>
    );
}

const mapStateToProps = (state) => {
    return {
        aviso: state.solicitacaoCadastroReducer.aviso
    };
};

export default connect(mapStateToProps, null)(translate('SolicitacaoConfirmacaoMensagem')(ConfirmacaoMensagem));