import * as yup from 'yup';
import {
    IDENTIFICAO,
    NOME,
    SEXO,
    UF,
    CIDADE,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [IDENTIFICAO]: yup.object().shape({
            [NOME]: yup
                .string()
                .trim()
                .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, strings.nomeInvalido)
                .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, strings.nomeInvalido)
                .min(5, strings.nomeInvalido)
                .max(100, strings.nomeInvalido)
                .nullable()
                .required(strings.campoObrigatorio),
            [SEXO]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [UF]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
            [CIDADE]: yup
                .string()
                .nullable()
                .required(strings.campoObrigatorio),
        }).required()
    });
}
