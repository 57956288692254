import ConstantesCadastroPerfil from '@constantes/cadastro/perfil';

const { REDUCER_TYPE } = ConstantesCadastroPerfil;

const INITIAL_STATE = {
    perfilAlteracao: null
};

function cadastroPerfilReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.SETAR_ID_PERFIL_ALTERACAO) {
        return {
            ...state,
            perfilAlteracao: payload.perfilAlteracao
        };
    }
    return state;
}

export default cadastroPerfilReducer;