/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DADOS_CONTATO,
    TELEFONE_RESIDENCIAL,
    TELEFONE_CELULAR,
    TELEFONE_COMERCIAL
} from './fieldNames';

export default (strings) => {
    const validarTelefone = (telefone, numeroCaracteres) => {
        if (!telefone) {
            return true;
        }
        let digitos = telefone.replace(/\D/g, '');
        if (digitos.length < numeroCaracteres) { // 8 dígitos + DDD
            return false;
        }
        return true;
    };

    return yup.object().shape({
        [DADOS_CONTATO]: yup.object().shape({
            [TELEFONE_RESIDENCIAL]:
                yup.string().test('telefone1-valido', strings.numeroTelefoneOitoDigitos, (telefone) => {
                    return validarTelefone(telefone, 10);
                }),
            [TELEFONE_CELULAR]:
                yup.string().test('celular1-valido', strings.numeroCelularNoveDigitos, (telefone) => {
                    return validarTelefone(telefone, 11);
                }),
            [TELEFONE_COMERCIAL]:
                yup.string().test('telefone2-valido', strings.numeroTelefoneOitoDigitos, (telefone) => {
                    return validarTelefone(telefone, 10);
                }),
        }).required()
    });
}
