import * as yup from 'yup';
import {
    NOME,
    CNES,
    LOGRADOURO,
    BAIRRO,
    CIDADE,
    CEP,
    UF,
    CODINOME,
    CNPJ,
    LOGOTIPO
} from './fieldNames';
import { validarCNPJ } from '../../../../../util/instituicao/cnpj';

export default (strings) => {
    return yup.object().shape({
        [NOME]: yup.string()
            .nullable()
            .min(1)
            .max(100)
            .trim()
            .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ0-9\s]+$/, strings.somenteLetrasPermitidas)
            .required(strings.campoObrigatorio),
        [CODINOME]: yup.string()
            .nullable()
            .min(1)
            .max(100)
            .trim()
            .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ0-9\s]+$/, strings.somenteLetrasPermitidas),
        [CNPJ]: yup.string()
            .test('cnpj-valido', strings.cnpjInvalido, (cnpj) => {
                cnpj = (cnpj || '').replace(/\D+/g, '');
                if (cnpj.length === 0) {
                    return true;
                }
                console.log(validarCNPJ);
                return validarCNPJ(cnpj);
            })
            .nullable(),
        [CNES]: yup.number()
            .required(strings.campoObrigatorio)
            .nullable()
            .positive(strings.mumeroPositivo),
        [CEP]: yup.string()
            .required(strings.campoObrigatorio),
        [LOGRADOURO]: yup.string()
            .nullable()
            .min(1)
            .max(255)
            .required(strings.campoObrigatorio),
        [BAIRRO]: yup.string()
            .nullable()
            .min(1)
            .max(30)
            .required(strings.campoObrigatorio),
        [UF]: yup.object().shape({
            id: yup.number(),
            sigla: yup.string()
        }).nullable().required(strings.campoObrigatorio),
        [CIDADE]: yup.object().shape({
            id: yup.number(),
            nome: yup.string()
        }).nullable().required(strings.campoObrigatorio),
        [LOGOTIPO]: yup
            .mixed()
            .nullable()
            .test('tamanhoArquivo', strings.erroTamanhoArquivo2Mb, (value) => {
                if (!value || !(value instanceof File)) {
                    return true;
                }
                return value?.size <= 2e6;
            })
    });
};