import axios from 'axios';
import HttpStatus from 'http-status-codes';
import translate from '@componentes/translate';
import validationSchema from './validationSchema';

import { Formik } from 'formik';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { getHeaders } from '@src/request';

import { useRef, useState, useEffect, memo, Suspense, lazy } from 'react';
import { SttContainer, SttLoading, SttHeading, SttCircularProgress, SttButton } from '@stt-componentes/core';
import { setPacsAlteracao as setPacsAlteracaoAction } from '@redux/actions/cadastro/pacs/index';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '@redux/actions/alerta';

import UtilPacs from '@util/pacs';

const DadosGerais = lazy(() => import('@componentes/interno/cadastro/pacs'));

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        marginTop: theme.spacing(1),
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    botaoVoltar: {
        marginTop: theme.spacing(2),
    },
}));

const Carregando = memo((props) => {
    return <SttLoading {...props} />;
});

const CadastroPacs = (params) => {
    const { strings, user, pacsAlteracao, setParametrosAlerta, setOpen, setPacsAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();

    //Carregando
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (pacsAlteracao) {
            setInitialValues(UtilPacs.inicializarValoresFormulario(pacsAlteracao));
        } else {
            setInitialValues(UtilPacs.inicializarValoresFormulario({}));
        }

        setCarregado(true);
    }, [pacsAlteracao]);

    const redirectPesquisaPacs = () => {
        setOpen(false);
        setPacsAlteracao(null);

        navigate('/pesquisa/pacs', { state: { pesquisar: true } });
    };

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);

        axios
            .post(`${ADM_API_BASE_URL}/pacs`, dados, { headers: getHeaders() })
            .then((response) => {
                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisaPacs,
                        },
                    ],
                    onClose: redirectPesquisaPacs,
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch((err) => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        mensagem = data.message;
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            },
                        },
                    ],
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    return (
        <>
            {carregado ? (
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        let mensagem = strings.confirmacaoCadastroPacs;
                        if (pacsAlteracao) {
                            mensagem = strings.confirmacaoAlteracaoPacs;
                        }

                        const parametrosAlerta = {
                            titulo: strings.atencao,
                            mensagem: mensagem,
                            tipo: 'alert',
                            open: true,
                            opcoes: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        let dadosFormatados = UtilPacs.formatarCadastro(dados);
                                        if (pacsAlteracao) {
                                            dadosFormatados.append('alteracao', true);
                                        }
                                        enviarFormulario(dadosFormatados, setSubmitting);
                                        setOpen(false);
                                    },
                                },
                                {
                                    title: strings.cancelar,
                                    onClick: () => {
                                        setOpen(false);
                                    },
                                },
                            ],
                        };

                        setParametrosAlerta(parametrosAlerta);
                        return true;
                    }}
                    validateOnChange={false}
                >
                    {({ isSubmitting, handleSubmit }) => {
                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.botaoVoltar}
                                        onClick={() => {
                                            const parametrosAlerta = {
                                                titulo: strings.confirmacao,
                                                mensagem: strings.retornarTelaPesquisa,
                                                tipo: 'alert',
                                                open: true,
                                                opcoes: [
                                                    {
                                                        title: strings.sim,
                                                        onClick: () => {
                                                            setPacsAlteracao(null);
                                                            setOpen(false);
                                                            navigate('/pesquisa/pacs', { state: { pesquisar: true } });
                                                        },
                                                    },
                                                    {
                                                        title: strings.nao,
                                                        onClick: () => {
                                                            setOpen(false);
                                                        },
                                                    },
                                                ],
                                            };
                                            setParametrosAlerta(parametrosAlerta);
                                        }}
                                        nomarginleft="true"
                                    >
                                        {strings.voltar}
                                    </SttButton>

                                    <SttHeading variant="h1" color="primary" align="center" className={classes.header}>
                                        {pacsAlteracao ? strings.alteracaoPacs : strings.cadastroPacs}
                                    </SttHeading>
                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                        <DadosGerais carregando={carregando} user={user} setCarregando={setCarregando} />
                                    </Suspense>

                                    <div className={classes.buttonWrapper}>
                                        <SttButton type="submit" variant="contained" color="primary" disabled={isSubmitting} nomarginleft="true">
                                            {strings.salvar}
                                        </SttButton>
                                    </div>
                                </form>
                                <SttLoading open={isSubmitting} text={strings.salvando} />
                            </SttContainer>
                        );
                    }}
                </Formik>
            ) : (
                <div className={classes.carregando}>
                    <SttCircularProgress color="primary" />
                </div>
            )}

            <Carregando open={carregando} text={strings.carregando} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        pacsAlteracao: state.cadastroPacsReducer.pacsAlteracao,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPacsAlteracao: (dados) => dispatch(setPacsAlteracaoAction(dados)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroPacs')(CadastroPacs));
