import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Interno from './interno/index';
import Logout from './logout';
import Erro from './erro';
import SolicitarCadastro from './externo/SolicitarCadastro';

const Home = () => {
    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Routes>

                <Route path="/solicitar-cadastro" exact element={<SolicitarCadastro />}>
                    <Route path="/solicitar-cadastro/:layout" exact element={<SolicitarCadastro />} />
                </Route>
                <Route path="/solicitar-cadastro-paciente" exact element={<SolicitarCadastro paciente={true} />}>
                    <Route path="/solicitar-cadastro-paciente/:layout" exact element={<SolicitarCadastro paciente={true} />} />
                </Route>
                <Route path="*" element={<Interno />}  />
                <Route path="/logout" element={<Logout />} />
                <Route path="/erro" element={<Erro />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Home;
