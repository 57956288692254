import TYPES from '@redux/types/cadastro/rede';

export const setRedeAlteracao = (rede) => {
    return {
        type: TYPES.SETAR_ID_REDE_TELEMEDICINA_ALTERACAO,
        payload: { redeAlteracao: rede }
    };
}


