import { default as React } from 'react';
import PropTypes from 'prop-types';
import { Strings } from '@stt-utilitarios/core';

const languages = {
    ptbr: Strings
};

const CONSTANTE_COMUM_MODULO = 'Comum';

export default function translate(keys) {
    return Component => {
        class TranslationComponent extends React.Component {
            render() {
                const siglaModulo = 'administrativo';
                //Por padrão, insere as strings comuns a todos os módulos
                let strings = languages[this.context.currentLanguage]['comum'];

                // Adicionar o comum do próprio módulo
                strings = { ...strings, ...languages[this.context.currentLanguage][siglaModulo][CONSTANTE_COMUM_MODULO] };

                if (keys instanceof Array) {
                    keys.forEach((valor) => {
                        strings = { ...strings, ...languages[this.context.currentLanguage][siglaModulo][valor] };
                    });
                } else {
                    strings = { ...strings, ...languages[this.context.currentLanguage][siglaModulo][keys] };
                }
                return <Component {...this.props} {...this.state} strings={strings} />;
            }
        }

        TranslationComponent.contextTypes = {
            currentLanguage: PropTypes.string
        };

        return TranslationComponent;
    };
}