/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    ASSUNTO,
    AREA,
    CONTEUDO,
    DADOS_GERAIS
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DADOS_GERAIS]: yup.object().shape({
            [ASSUNTO]: yup.string()
                .nullable()
                .min(1)
                .max(100)
                .required(strings.campoObrigatorio),
            [CONTEUDO]: yup.string()
                .nullable()
                .min(1)
                .required(strings.campoObrigatorio),
            [AREA]: yup.object()
                .nullable()
                .shape({
                    id: yup.number(),
                    descritor: yup.string(),
                })
                .required(strings.campoObrigatorio)
        }).required()
    });
}
