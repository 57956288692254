import TYPES from '@redux/types/alerta'

export const setOpen = (open) => {
    return {
        type: TYPES.SETAR_OPEN,
        payload: open
    };
}
export const setParametrosAlerta = (parametros) => {
    return {
        type: TYPES.SETAR_PARAMETROS_ALERTA,
        payload: { parametros }
    };
}