import {
    DADOS_GERAIS,
    NOME,
    DATA_INCLUSAO,
    SITUACAO
} from '@componentes/interno/cadastro/pontos/dadosGerais/form/fieldNames';
import { PONTOS } from '@src/common/Constants';
import {
    DADOS_TIPO,
    TIPO,
    AETITLE,
    BRIDGE,
    CONFIRMACAO_SENHA,
    MODALIDADE,
    REDE_TELEMEDICINA,
    SENHA
} from '@componentes/interno/cadastro/pontos/dadosTipo/form/fieldNames';
import {
    DADOS_VINCULO,
    RESPONSAVEL,
    SECRETARIO_SAUDE,
    SETOR,
    INSTITUICAO
} from '@src/componentes/interno/cadastro/pontos/dadosVinculo/form/fieldNames';
import {
    DADOS_ATIVIDADE,
    ATIVIDADES,
    DESCRICAO
} from '@src/componentes/interno/cadastro/pontos/dadosAtividade/form/fieldNames';
import {
    DADOS_CONTATO,
    CONTATO,
    CONTATOS,
    TIPO_CONTATO
} from '@src/componentes/interno/cadastro/pontos/dadosContato/form/fieldNames';
import moment from 'moment';

const inicializarValoresFormulario = (dadosAlteracao = {}) => {
    let formulario = {};

    if (dadosAlteracao.id) {
        formulario.id = dadosAlteracao.id;
    }

    formulario[DADOS_GERAIS] = {};
    formulario[DADOS_GERAIS][NOME] = _getValueOrEmptyString(dadosAlteracao.id_humano);
    formulario[DADOS_GERAIS][DATA_INCLUSAO] = _getDateOrNow(dadosAlteracao.data_inclusao);
    formulario[DADOS_GERAIS][SITUACAO] = dadosAlteracao.situacao ? PONTOS.SITUACAO.find(s => s.identificador === dadosAlteracao.situacao) : PONTOS.SITUACAO[0];

    formulario[DADOS_TIPO] = {};
    formulario[DADOS_TIPO][TIPO] = dadosAlteracao.tipo ? dadosAlteracao.tipo : PONTOS.TIPO_TELEDIAGNOSTICO;
    formulario[DADOS_TIPO][BRIDGE] = null;
    if (dadosAlteracao.id_bridge) {
        formulario[DADOS_TIPO][BRIDGE] = {
            id: dadosAlteracao.id_bridge,
            nome: dadosAlteracao.nome_bridge
        }
    }
    formulario[DADOS_TIPO][MODALIDADE] = null;
    if (dadosAlteracao.id_modalidade) {
        formulario[DADOS_TIPO][MODALIDADE] = {
            id: dadosAlteracao.id_modalidade,
            descricao: dadosAlteracao.descricao_modalidade
        }
    }
    formulario[DADOS_TIPO][REDE_TELEMEDICINA] = null;
    if (dadosAlteracao.id_rede_telemedicina) {
        formulario[DADOS_TIPO].redeTelemedicinaAnterior = dadosAlteracao.id_rede_telemedicina;
        formulario[DADOS_TIPO][REDE_TELEMEDICINA] = {
            id: dadosAlteracao.id_rede_telemedicina,
            descricao: dadosAlteracao.descricao_rede_telemedicina
        }
    }
    formulario[DADOS_TIPO][AETITLE] = _getValueOrEmptyString(dadosAlteracao.aetitle);
    formulario[DADOS_TIPO][CONFIRMACAO_SENHA] = _getValueOrEmptyString(dadosAlteracao.senha);
    formulario[DADOS_TIPO][SENHA] = _getValueOrEmptyString(dadosAlteracao.senha);

    formulario[DADOS_VINCULO] = {};
    formulario[DADOS_VINCULO][SETOR] = null;
    if (dadosAlteracao.id_setor) {
        formulario[DADOS_VINCULO][SETOR] = {
            id: dadosAlteracao.id_setor,
            descricao: dadosAlteracao.descricao_setor
        }
    }
    formulario[DADOS_VINCULO][INSTITUICAO] = null;
    if (dadosAlteracao.id_instituicao) {
        formulario[DADOS_VINCULO][INSTITUICAO] = {
            id: dadosAlteracao.id_instituicao,
            nome: dadosAlteracao.nome_instituicao
        }
    }
    formulario[DADOS_VINCULO][RESPONSAVEL] = _getValueOrEmptyString(dadosAlteracao.nome_responsavel);
    formulario[DADOS_VINCULO][SECRETARIO_SAUDE] = _getValueOrEmptyString(dadosAlteracao.nome_secretario_saude);

    formulario[DADOS_ATIVIDADE] = {};
    formulario[DADOS_ATIVIDADE][ATIVIDADES] = [];
    if (dadosAlteracao.atividades?.length > 0) {
        formulario[DADOS_ATIVIDADE][ATIVIDADES] = dadosAlteracao.atividades;
    }
    formulario[DADOS_ATIVIDADE][DESCRICAO] = '';

    formulario[DADOS_CONTATO] = {};
    formulario[DADOS_CONTATO][CONTATOS] = [];
    if (dadosAlteracao.contatos?.length > 0) {
        formulario[DADOS_CONTATO][CONTATOS] = dadosAlteracao.contatos;
    }
    formulario[DADOS_CONTATO][CONTATO] = '';
    formulario[DADOS_CONTATO][TIPO_CONTATO] = null;

    return formulario;
}

const inicializarValoresTrocaPontoFormulario = (dados) => {
    let formulario = { ...dados };
    formulario.trocaPonto = true;
    formulario.idPontoAntigo = formulario.id;
    formulario[DADOS_VINCULO].instituicaoAntiga = formulario[DADOS_VINCULO][INSTITUICAO];

    delete formulario.id;

    formulario[DADOS_GERAIS][DATA_INCLUSAO] = _getDateOrNow();
    formulario[DADOS_GERAIS][SITUACAO] = PONTOS.SITUACAO[0];

    formulario[DADOS_VINCULO][SETOR] = null;
    formulario[DADOS_VINCULO][INSTITUICAO] = null;

    formulario[DADOS_ATIVIDADE][ATIVIDADES] = [];
    formulario[DADOS_ATIVIDADE][DESCRICAO] = '';

    formulario[DADOS_CONTATO][CONTATOS] = [];
    formulario[DADOS_CONTATO][CONTATO] = '';
    formulario[DADOS_CONTATO][TIPO_CONTATO] = null;

    return formulario;
}

const _getDateOrNow = (value) => {
    if (!value) {
        return new Date();
    }
    if (moment(value).isValid()) {
        value = moment.utc(value);
        return value.set({ h: 12 }).toISOString();
    }

    return value;
}

const _getValueOrEmptyString = (value) => {
    return value ?? '';
}

export default { inicializarValoresFormulario, inicializarValoresTrocaPontoFormulario };