import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import translate from '@componentes/translate';
import axios from 'axios';
import { getHeaders } from '@src/request';
import { estadoInicial } from './estadoInicial';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '@redux/actions/pesquisa/funcionario';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttAutocomplete,
    SttNumberInput
} from '@stt-componentes/core';

import {
    NOME,
    CODIGO_INE,
    UF,
    CIDADE,
    CNES,
    INSTITUICAO
} from './fieldNames';

import {
    CONSTANTES_PERFIS
} from '@common/Constants';
import { temPerfil } from '@security/acl';

const FormPesquisa = (props) => {
    const {
        user,
        setParametrosPesquisa,
        limparParametrosPesquisa,
        callbackAndamento,
        callbackLimpar,
        parametrosPesquisa,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
        strings
    } = props;

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;

    //Filtros
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [estadosAdminEstadual, setEstadosAdminEstadual] = useState([]);
    const formRef = useRef();

    useEffect(() => {
        axios.get(`${UTILITARIOS_API_BASE_URL}/localizacao/estado`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    if (temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_ESTADUAL)) {
                        let estados = [];
                        user.perfisRBAC.forEach(perfilRBAC => {
                            if (perfilRBAC.identificador === CONSTANTES_PERFIS.ADMINISTRADOR_ESTADUAL) {
                                estados = perfilRBAC.estados;
                            }
                        });
                        setEstados(estados);
                        setEstadosAdminEstadual(estados.map(estado => (estado.id)));
                    } else {
                        setEstados(itens);
                    }
                }
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados.nome) {
            filtros.nome = dados.nome.trim();
        }

        if (dados.codigo_ine) {
            filtros.codigo_ine = dados.codigo_ine;
        }

        if (dados.uf) {
            filtros.uf = dados.uf.id;
        }

        if (dados.cidade) {
            filtros.cidade = dados.cidade.id;
        }

        if (dados.cnes) {
            filtros.cnes = dados.cnes
        }

        if (dados.instituicao) {
            filtros.instituicao = dados.instituicao;
        }

        if (estadosAdminEstadual.length) {
            filtros.estadosAdminEstadual = JSON.stringify(estadosAdminEstadual);
        }

        setParametrosPesquisa(dados);
        return filtros;
    }

    const handleChangeUf = (uf, callbackSetCidades) => {
        if (uf) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        callbackSetCidades(itens);
                    } else {
                        callbackSetCidades([]);
                    }
                })
                .catch(err => console.log(err));
        }
    };

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={parametrosPesquisa}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/equipe`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: []
                                });

                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            values,
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}>
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <FastField name={NOME}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    label={strings.nome}
                                                                    inputProps={{
                                                                        maxLength: 100,
                                                                        style: { textTransform: "uppercase" }
                                                                    }}
                                                                    value={field.value ? field.value : ''}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <FastField name={CODIGO_INE}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttNumberInput
                                                                    {...field}
                                                                    label={strings.ine}
                                                                    inputProps={
                                                                        {
                                                                            maxLength: 10
                                                                        }
                                                                    }
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={2} lg={1}>
                                                        <Field name={UF}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.uf
                                                                    }}
                                                                    getOptionLabel={option => option && option.sigla || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={estados}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(UF, item || null);
                                                                        setFieldValue(CIDADE, null);
                                                                        handleChangeUf(item, setCidades);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={5} lg={4} xl={3}>
                                                        <Field name={CIDADE}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.municipio
                                                                    }}
                                                                    disabled={!values.uf}
                                                                    getOptionLabel={option => option && option.nome || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={cidades}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(CIDADE, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <FastField name={CNES}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttNumberInput
                                                                    {...field}
                                                                    label={strings.cnes}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <FastField name={INSTITUICAO}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    label={strings.instituicao}
                                                                    inputProps={{
                                                                        maxLength: 100,
                                                                        style: { textTransform: "uppercase" }
                                                                    }}
                                                                    value={field.value ? field.value : ''}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>

                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        callbackLimpar();
                                                    }}
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        limparParametrosPesquisa();
                                                        callbackLimpar();
                                                        resetForm({
                                                            values: estadoInicial
                                                        });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaEquipeReducer.parametrosPesquisa,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaEquipes')(FormPesquisa));







