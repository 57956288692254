import React, { useEffect, useState } from "react";
import {
    SttModal,
    SttGrid,
    SttInput,
    SttButton,
    SttLoading,
    SttTranslate
} from '@stt-componentes/core';
import { getHeaders } from '@src/request';
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import { PONTOS } from "@src/common/Constants";
import { connect } from 'react-redux';
import { Formik, FastField } from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import * as yup from 'yup';

const validationSchema = (strings) => {
    return yup.object().shape({
        'descricao': yup
            .string()
            .min(10, strings.erroConteudoHtml)
            .trim()
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    }
}));

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const OperacoesPonto = ({ operacao, idPonto, setExibirModalOperacao, voltarPesquisa, strings, setParametrosAlerta, setOpen }) => {
    const schema = validationSchema(strings);
    const classes = useStyles();

    const [tituloModal, setTituloModal] = useState('');
    const [initialValues, setInitialValues] = useState();

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    useEffect(() => {
        if (operacao) {
            setTituloModal(capitalizeFirstLetter(`${strings[operacao]} ponto`));
            switch (operacao) {
                case PONTOS.OPERACOES.ATIVAR:
                    setInitialValues({ descricao: 'O ponto foi ativado pelo administrador.' });
                    break;
                case PONTOS.OPERACOES.DESATIVAR:
                    setInitialValues({ descricao: 'O ponto foi desativado pelo administrador.' });
                    break;
                case PONTOS.OPERACOES.BLOQUEAR:
                    setInitialValues({ descricao: 'O ponto foi bloqueado pelo administrador.' });
                    break;
                case PONTOS.OPERACOES.DESBLOQUEAR:
                    setInitialValues({ descricao: 'O ponto foi desbloqueado pelo administrador.' });
                    break;
            }
        }
    }, [operacao]);

    const callback = () => {
        setOpen(false);
        setExibirModalOperacao(false);
    }

    const callbackSucesso = () => {
        callback();
        voltarPesquisa(true);
    }

    const enviarFormulario = (dados, setSubmitting) => {
        const dadosEnviar = {
            idPonto,
            descricao: dados.descricao.trim(),
            operacao
        };

        setSubmitting(true);
        axios.post(`${ADM_API_BASE_URL}/ponto/operacao`, dadosEnviar, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message ? data.message : strings.operacaoSucesso,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: callbackSucesso
                        }
                    ],
                    onClose: callbackSucesso
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <SttModal
            title={tituloModal}
            open={true}
            maxWidth="md"
            outModalClose={callback}
            iconClose={callback}
            fullWidth={true}
            children={
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        const parametrosAlerta = {
                            titulo: strings.atencao,
                            mensagem: strings.confirmarOperacao,
                            tipo: 'alert',
                            open: true,
                            opcoes: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        enviarFormulario(dados, setSubmitting);
                                        setOpen(false);
                                    }
                                },
                                {
                                    title: strings.cancelar,
                                    onClick: () => {
                                        setOpen(false);
                                    }
                                }
                            ]
                        };
                        setParametrosAlerta(parametrosAlerta);
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <FastField name='descricao'>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttInput
                                                        multiline
                                                        rows={5}
                                                        required={true}
                                                        {...field}
                                                        label={strings.label(operacao)}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />
                                    </SttGrid>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                className={classes.button}
                                                disabled={isSubmitting}
                                            >
                                                {strings.confirmar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues });
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues });
                                                    callback();
                                                }}
                                            >
                                                {strings.fechar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            )
                        }
                    }
                </Formik>
            }
        />
    )

}

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(null, mapDispatchToProps)(SttTranslate('OperacoesPonto')(OperacoesPonto));
