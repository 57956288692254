import TYPES from '@redux/types/cadastro/paciente';

const INITIAL_STATE = {
    paciente: null,
    accessToken: null,
};

function pacienteReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === TYPES.SET_USUARIO_PACIENTE){
        return {
            ...state,
            paciente: payload.paciente
        };
    }
    if(type === TYPES.SET_ACCESS_TOKEN){
        return {
            ...state,
            accessToken: payload.accessToken
        };
    }
    return state;
}

export default pacienteReducer;