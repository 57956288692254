import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '@src/request';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import translate from '@componentes/translate';
import {
    setParametrosPesquisaTriagem as setParametrosPesquisaTriagemAction,
    limparParametrosPesquisaTriagem as limparParametrosPesquisaTriagemAction
} from '@redux/actions/cadastro/triagem';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttMaskedInput,
    SttAutocomplete,
    SttDatePicker
} from '@stt-componentes/core';
import moment from 'moment';
import {
    NOME,
    CPF,
    SITUACAO,
    DATA_INICIAL,
    DATA_FINAL,
    UF,
    MUNICIPIO
} from './fieldNames';
import validationSchema from './validationSchema';
import Uf from './uf';
import Municipio from './municipio';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const FormTriagemAutoCadastro = (props) => {
    const {
        setParametrosPesquisaTriagem,
        limparParametrosPesquisaTriagem,
        parametrosPesquisaTriagem,
        callbackAndamento,
        callbackLimpar,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
        strings
    } = props;

    const classes = useStyles();
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();
    const schema = validationSchema(strings);

    const situacoes = [
        { sigla: 'T', descricao: strings.todas },
        { sigla: 'A', descricao: strings.aguardandoTriagem },
        { sigla: 'P', descricao: strings.cadastroAprovado },
        { sigla: 'E', descricao: strings.cadastroPendente },
    ];

    const acompanharFacebook = [
        { sigla: 'T', descricao: strings.todas },
        { sigla: 'NR', descricao: strings.naoRespondeu },
        { sigla: 'N', descricao: strings.sim },
        { sigla: 'S', descricao: strings.nao },
    ];

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados.nome) {
            filtros.nome = dados.nome.trim();
        }

        if (dados.cpf) {
            filtros.cpf = ('00000000000' + dados.cpf.replace(/[\. ,:-]+/g, "")).slice(-11);
        }

        if (dados.situacao) {
            filtros.situacao = dados.situacao.sigla;
        }

        if (dados.data_inicial) {
            filtros.data_inicial = moment(dados.data_inicial).format('YYYY-MM-DD');
        }

        if (dados.data_final) {
            let dataFinal = dados.data_final.setHours(23, 59, 0, 0);
            filtros.data_final = moment(dataFinal).format('YYYY-MM-DD HH:mm:ss');
        }

        if(dados.uf) {
            filtros.uf = dados.uf.id;
        }

        if(dados.municipio) {
            filtros.municipio = dados.municipio.id;
        }

        setParametrosPesquisaTriagem(dados);
        return filtros;
    }

    const tratarDadosTriagem = (dados) => {
        dados.forEach(dado => {
            if (dado.numero_conselho && dado.descricao_conselho_trabalho && dado.sigla_uf_conselho_trabalho) {
                dado.conselho_trabalho_formatado = `${dado.descricao_conselho_trabalho} ${dado.numero_conselho}/${dado.sigla_uf_conselho_trabalho}`;
            }

            if (dado.situacao) {
                const situacao = situacoes.find(s => s.sigla === dado.situacao);
                dado.situacaoFormatada = situacao.descricao;
            }

            if (dado.acompanhar_facebook) {
                const acompanhar = acompanharFacebook.find(af => af.sigla === dado.acompanhar_facebook);
                dado.acompanharFacebookFormatado = acompanhar.descricao;
            }

            if (dado.cpf) {
                dado.cpf = ('00000000000' + dado.cpf.replace(/[\. ,:-]+/g, "")).slice(-11);
            }
        });

        return dados;
    }

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={parametrosPesquisaTriagem}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/triagem`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                dados.itens = tratarDadosTriagem(dados.itens);
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: []
                                });
                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}>
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <FastField name={NOME}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    label={strings.nome}
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    value={field.value ? field.value : ''}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <FastField name={CPF}>
                                                            {({
                                                                field,
                                                                meta,
                                                            }) => (
                                                                <SttMaskedInput
                                                                    {...field}
                                                                    mask="cpf"
                                                                    label={strings.cpf}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                                        <Field name={SITUACAO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.situacao
                                                                    }}
                                                                    getOptionLabel={option => option && option.descricao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.sigla === valueOptionSelected.sigla}
                                                                    options={situacoes}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(SITUACAO, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <Field name={DATA_INICIAL}>
                                                            {({
                                                                field: { name, value, },
                                                                form: { setFieldValue, setFieldTouched },
                                                                meta
                                                            }) => {
                                                                return (
                                                                    <SttDatePicker
                                                                        label={strings.dataInicial}
                                                                        inputprops={{
                                                                            name: name
                                                                        }}
                                                                        disableFuture
                                                                        maxDate={new Date()}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        value={value}
                                                                        onBlur={() => {
                                                                            setFieldTouched(DATA_INICIAL, true);
                                                                        }}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                        onChange={date => {
                                                                            setFieldValue(DATA_INICIAL, date, true);
                                                                        }
                                                                        }
                                                                        onClose={() => setFieldTouched(DATA_INICIAL, true)}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <Field name={DATA_FINAL}>
                                                            {({
                                                                field: { name, value, },
                                                                form: { setFieldValue, setFieldTouched },
                                                                meta
                                                            }) => {
                                                                return (
                                                                    <SttDatePicker
                                                                        label={strings.dataFinal}
                                                                        inputprops={{
                                                                            name: name
                                                                        }}
                                                                        disableFuture
                                                                        maxDate={new Date()}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        value={value}
                                                                        onBlur={() => {
                                                                            setFieldTouched(DATA_FINAL, true);
                                                                        }}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                        onChange={date => setFieldValue(DATA_FINAL, date, true)}
                                                                        onClose={() => setFieldTouched(DATA_FINAL, true)}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <Field name={UF}>
                                                            {(props) => (
                                                                <Uf
                                                                    {...props}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <Field name={MUNICIPIO}>
                                                            {(props) => (
                                                                <Municipio
                                                                    {...props}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} className={classes.buttonWrapper}>

                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        limparParametrosPesquisaTriagem();
                                                        callbackLimpar();
                                                        resetForm({
                                                            values: estadoInicial
                                                        });
                                                        handleSubmit();
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
}

FormTriagemAutoCadastro.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisaTriagem: PropTypes.func.isRequired,
    limparParametrosPesquisaTriagem: PropTypes.func.isRequired,
    parametrosPesquisaTriagem: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisaTriagem: state.triagemAutocadastroReducer.parametrosPesquisaTriagem,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisaTriagem: parametros => dispatch(setParametrosPesquisaTriagemAction(parametros)),
        limparParametrosPesquisaTriagem: () => dispatch(limparParametrosPesquisaTriagemAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('FormTriagemAutoCadastro')(FormTriagemAutoCadastro));