import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import FormPesquisa from './form';
import { useNavigate, useLocation } from "react-router-dom";
import translate from '@componentes/translate';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttSeeButton,
    SttHeading
} from '@stt-componentes/core';
import { setPacienteAlteracao as setPacienteAlteracaoAction } from '@redux/actions/cadastro/paciente';
import { 
    setarResultadoPesquisa as setarResultadoPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction 
} from '@redux/actions/pesquisa/paciente';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from "@redux/actions/alerta";

import {
    CPF,
    CNS,
    NOME,
    DATA_NASCIMENTO,
    DATA_NASCIMENTO_F,
    NOME_MAE,
    SEXO,
    PACIENTES
} from './fieldNames';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        CPF,
        CNS,
        NOME,
        DATA_NASCIMENTO,
        SEXO,
        NOME_MAE
    }
};

const Pesquisa = (props) => {
    const { 
        strings, 
        setPacienteAlteracao, 
        setParametrosAlerta, 
        setOpen,
        setarResultadoPesquisa,
        resultadoPesquisa,
        limparParametrosPesquisa,
        filtros
    } = props;

    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(filtros.limit || global.gConfig.pagination.count);
    const [page, setPage] = useState(filtros.page || global.gConfig.pagination.start);
    const [order, setOrder] = useState(filtros.direction || ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(filtros.sort || null);
    const [buscar, setBuscar] = useState(false);

    //Solicitação
    const [pacientes, setPacientes] = useState(resultadoPesquisa);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setPacientes(resultadoPesquisa);
    }, [resultadoPesquisa]);

    useEffect(() => {
        if (!location.state) {
            limparParametrosPesquisa();
        } else {
            if (location.state.pesquisar) {
                setBuscar(true);   
            }
        }
        location.state = null;
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setPacientes(dados.itens);
        setarResultadoPesquisa(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
        setPacientes([]);
        setTotalRegistros(0);
    }

    const clickEditarPaciente = (paciente) => {
        setPacienteAlteracao(paciente);
        navigate(`/cadastro/paciente`);
    };

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>
            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                setParametrosAlerta={setParametrosAlerta}
                setOpen={setOpen}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
                handleChangePage={handleChangePage}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.CPF ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CPF}
                                        direction={orderBy === ORDENACAO.CAMPOS.CPF ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CPF)}
                                    >
                                        {strings.cpf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.CNS ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CNS}
                                        direction={orderBy === ORDENACAO.CAMPOS.CNS ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CNS)}
                                    >
                                        {strings.cns}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}
                                    >
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_NASCIMENTO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA_NASCIMENTO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA_NASCIMENTO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_NASCIMENTO)}
                                    >
                                        {strings.dataNascimento}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.SEXO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.SEXO}
                                        direction={orderBy === ORDENACAO.CAMPOS.SEXO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SEXO)}
                                    >
                                        {strings.sexo}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="25%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME_MAE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME_MAE}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME_MAE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME_MAE)}
                                    >
                                        {strings.nomeMae}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">{strings.pacientes}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={7}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    pacientes.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={7}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        pacientes.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row[CPF]}</SttTableCell>
                                                    <SttTableCell>{row[CNS]}</SttTableCell>
                                                    <SttTableCell>{row[NOME]}</SttTableCell>
                                                    <SttTableCell>{row[DATA_NASCIMENTO_F]}</SttTableCell>
                                                    <SttTableCell>{row[SEXO]}</SttTableCell>
                                                    <SttTableCell>{row[NOME_MAE]}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.cpf}:</b> {row[CPF]}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cns}:</b> {row[CNS]}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.nome}:</b> {row[NOME]}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.dataNascimento}:</b> {row[DATA_NASCIMENTO_F]}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.sexo}:</b> {row[SEXO]}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.nomeMae}:</b> {row[NOME_MAE]}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        id={`btn-alterar-paciente-${index}`}
                                                        onClick={() => {
                                                            clickEditarPaciente(row);
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                totalRegistros > 0 &&
                    <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                        component="div"
                        count={totalRegistros}
                        rowsPerPage={count}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={strings.linhasPorPagina}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                    />
            }
        </>
    )
}

Pesquisa.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.pesquisaPacienteReducer,
        resultadoPesquisa: state.pesquisaPacienteReducer[PACIENTES]
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPacienteAlteracao: dados => dispatch(setPacienteAlteracaoAction(dados)),
        setarResultadoPesquisa: resultado => dispatch(setarResultadoPesquisaAction(resultado)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaPaciente')(Pesquisa));