/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    NOME,
    CODIGO_INE,
    INSTITUICAO,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [NOME]: yup.string()
            .nullable()
            .min(1, strings.campoObrigatorio)
            .max(100)
            .trim()
            .required(strings.campoObrigatorio),
        [CODIGO_INE]: yup.string()
            .nullable()
            .min(10, strings.codigoIne10Caracteres)
            .max(10, strings.codigoIne10Caracteres)
            .trim()
            .matches(/^\d+$/, strings.somenteNumeros)
            .required(strings.campoObrigatorio),
        [INSTITUICAO]: yup.object().shape({
            id: yup.number(),
            descricao: yup.string(),
            sigla: yup.string()
        }).nullable().required(strings.campoObrigatorio),
    });
}
