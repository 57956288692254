import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormPesquisa from './form';
import { useNavigate, useLocation } from 'react-router-dom';
import translate from '@componentes/translate';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttSeeButton,
    SttHeading,
} from '@stt-componentes/core';
import { setPacsAlteracao as setPacsAlteracaoAction } from '@redux/actions/cadastro/pacs';
import {
    setarResultadoPesquisa as setarResultadoPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction,
} from '@redux/actions/pesquisa/pacs';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '@redux/actions/alerta';

import { URL_BASE, URL_TOKEN, CLIENT_ID, DCM4CHEE, PACS } from './fieldNames';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: theme.spacing(3),
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center',
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc',
    },
    CAMPOS: { URL_BASE, DCM4CHEE, URL_TOKEN, CLIENT_ID },
};

const Pesquisa = (props) => {
    const { strings, setPacsAlteracao, setParametrosAlerta, setOpen, setarResultadoPesquisa, resultadoPesquisa, limparParametrosPesquisa, filtros } = props;

    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(filtros.count || global.gConfig.pagination.count);
    const [page, setPage] = useState(filtros.page || global.gConfig.pagination.start);
    const [order, setOrder] = useState(filtros.direction || ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(filtros.sort || null);
    const [buscar, setBuscar] = useState(false);

    //Solicitação
    const [pacs, setPacs] = useState(resultadoPesquisa);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setPacs(resultadoPesquisa);
    }, [resultadoPesquisa]);

    useEffect(() => {
        if (!location.state) {
            limparParametrosPesquisa();
        } else {
            if (location.state.pesquisar) {
                setBuscar(true);
            }
        }
        location.state = null;
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = orderBy === campo && order === ORDENACAO.DIRECAO.ASC;
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setPacs(dados.itens);
        setarResultadoPesquisa(dados.itens);
        setBuscar(false);
    };

    const callbackLimpar = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
        setPacs([]);
        setTotalRegistros(0);
    };

    const clickEditarPacs = (pacs) => {
        setPacsAlteracao(pacs);
        navigate(`/cadastro/pacs`);
    };

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>
            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                setParametrosAlerta={setParametrosAlerta}
                setOpen={setOpen}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
                handleChangePage={handleChangePage}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="35%" sortDirection={orderBy === ORDENACAO.CAMPOS.URL_BASE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.URL_BASE}
                                        direction={orderBy === ORDENACAO.CAMPOS.URL_BASE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.URL_BASE)}
                                    >
                                        {strings.url_base}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.DCM4CHEE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DCM4CHEE}
                                        direction={orderBy === ORDENACAO.CAMPOS.DCM4CHEE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DCM4CHEE)}
                                    >
                                        {strings.versao_dcm4chee}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="35%" sortDirection={orderBy === ORDENACAO.CAMPOS.URL_TOKEN ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.URL_TOKEN}
                                        direction={orderBy === ORDENACAO.CAMPOS.URL_TOKEN ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.URL_TOKEN)}
                                    >
                                        {strings.token_url}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.CLIENT_ID ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CLIENT_ID}
                                        direction={orderBy === ORDENACAO.CAMPOS.CLIENT_ID ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CLIENT_ID)}
                                    >
                                        {strings.token_client_id}
                                    </SttTableSortLabel>
                                </SttTableCell>

                                <SttTableCell
                                    width="5%"
                                    style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}
                                >
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">
                                    {strings.pacs}
                                </SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {buscaEmAndamento ? (
                            <SttTableRow key={-1}>
                                <SttTableCell colSpan={7} align="center">
                                    <SttCircularProgress />
                                </SttTableCell>
                            </SttTableRow>
                        ) : pacs.length === 0 ? (
                            <SttTableRow key={-1}>
                                <SttTableCell colSpan={7} align="center">
                                    {strings.nenhumRegistroEncontrado}
                                </SttTableCell>
                            </SttTableRow>
                        ) : (
                            pacs.map((row, index) => (
                                <SttTableRow key={index}>
                                    <SttHidden smDown>
                                        <SttTableCell>{row[URL_BASE]}</SttTableCell>
                                        <SttTableCell>{row[DCM4CHEE]}</SttTableCell>
                                        <SttTableCell>{row[URL_TOKEN]}</SttTableCell>
                                        <SttTableCell>{row[CLIENT_ID]}</SttTableCell>
                                    </SttHidden>
                                    <SttHidden mdUp>
                                        <SttTableCell width="96%">
                                            <SttText size="small">
                                                <b>{strings.url_base}:</b> {row[URL_BASE]}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.versao_dcm4chee}:</b> {row[DCM4CHEE]}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.token_url}:</b> {row[URL_TOKEN]}
                                            </SttText>
                                            <SttText size="small">
                                                <b>{strings.token_client_id}:</b> {row[CLIENT_ID]}
                                            </SttText>
                                        </SttTableCell>
                                    </SttHidden>
                                    <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                        <SttSeeButton
                                            id={`btn-alterar-pacs-${index}`}
                                            onClick={() => {
                                                clickEditarPacs(row);
                                            }}
                                        />
                                    </SttTableCell>
                                </SttTableRow>
                            ))
                        )}
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {totalRegistros > 0 && (
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros}
                    rowsPerPage={count}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            )}
        </>
    );
};

Pesquisa.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.pesquisaPacsReducer,
        resultadoPesquisa: state.pesquisaPacsReducer[PACS],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPacsAlteracao: (dados) => dispatch(setPacsAlteracaoAction(dados)),
        setarResultadoPesquisa: (resultado) => dispatch(setarResultadoPesquisaAction(resultado)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaPacs')(Pesquisa));
