import TYPES from '@redux/types/cadastro/mensagens';

const INITIAL_STATE = {
    mensagemAlteracao: null
};

function cadastroMensagensReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === TYPES.SETAR_ID_MENSAGEM_ALTERACAO) {
        return {
            ...state,
            mensagemAlteracao: payload.mensagemAlteracao
        };
    }
    return state;
}

export default cadastroMensagensReducer;