import axios from 'axios';
import PropTypes from 'prop-types';
import translate from '@componentes/translate';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';

import { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import { getHeaders } from '@src/request';
import { setParametrosPesquisa as setParametrosPesquisaAction, limparParametrosPesquisa as limparParametrosPesquisaAction } from '@redux/actions/pesquisa/pacs';
import { SttGrid, SttButton, SttInput, SttNumberInput } from '@stt-componentes/core';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '@redux/actions/alerta';
import { URL_BASE, DCM4CHEE } from './fieldNames';

const FormPesquisa = (props) => {
    const {
        parametrosPesquisa,
        setParametrosPesquisa,
        limparParametrosPesquisa,
        setParametrosAlerta,
        setOpen,
        callbackAndamento,
        callbackLimpar,
        callbackBusca,
        page,
        count,
        orderBy,
        order,
        buscar,
        strings,
        handleChangePage,
    } = props;

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    const formRef = useRef();
    const schema = validationSchema(strings);

    useEffect(() => {
        if (buscar && formRef.current) {
            submitForm();
        }
    }, [buscar]);

    const submitForm = () => {
        formRef.current.handleSubmit();
    };

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = page * count;

        dados.page = page;

        dados.count = count;
        filtros.count = count;

        dados.start = offset;
        filtros.start = offset;

        dados.sort = orderBy;
        filtros.sort = orderBy;

        dados.direction = order;
        filtros.direction = order;

        if (dados.url_base) {
            filtros.url_base = dados.url_base.trim();
        }

        if (dados.versao_dcm4chee) {
            filtros.versao_dcm4chee = dados.versao_dcm4chee;
        }

        setParametrosPesquisa(dados);
        return filtros;
    };

    return (
        <>
            {
                <Formik
                    enableReinitialize
                    innerRef={formRef}
                    initialValues={parametrosPesquisa}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/pacs`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                callbackBusca(dados);
                            })
                            .catch((err) => {
                                const { response } = err;

                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: [],
                                });
                                let parametrosAlerta = {
                                    titulo: strings.erro,
                                    tipo: 'error',
                                    mensagem: strings.erroGenerico,
                                    opcoes: [
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setOpen(false);
                                            },
                                        },
                                    ],
                                };

                                if (response) {
                                    if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                                        const dadosResposta = response.data;
                                        let arrMensagem = [];
                                        dadosResposta.errors.forEach((error) => {
                                            arrMensagem.push(`- ${error.message}`);
                                        });
                                        parametrosAlerta = { ...parametrosAlerta, mensagem: arrMensagem.join('\n'), open: true };
                                    } else if (response.status !== HttpStatus.NOT_FOUND) {
                                        parametrosAlerta = { ...parametrosAlerta, open: true };
                                    }
                                } else {
                                    parametrosAlerta = { ...parametrosAlerta, open: true };
                                }
                                setParametrosAlerta(parametrosAlerta);
                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting, handleSubmit, resetForm, isValid }) => {
                        return (
                            <>
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            if (!isValid) {
                                                return;
                                            }
                                            handleChangePage(null, 0);
                                        }
                                    }}
                                >
                                    <SttGrid container spacing={3}>
                                        {
                                            <>
                                                <SttGrid item xs={12} sm={9}>
                                                    <Field name={URL_BASE}>
                                                        {({ field, meta }) => (
                                                            <SttInput
                                                                {...field}
                                                                label={strings.url_base}
                                                                error={meta.value && meta.touched && meta.error ? true : false}
                                                                helperText={(meta.value && meta.touched && meta.error) || undefined}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>

                                                <SttGrid item xs={12} sm={3}>
                                                    <FastField name={DCM4CHEE}>
                                                        {({ field, meta }) => (
                                                            <SttNumberInput
                                                                inputProps={{
                                                                    maxLength: 2,
                                                                }}
                                                                {...field}
                                                                label={strings.versao_dcm4chee}
                                                                error={meta.touched && meta.error ? true : false}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            />
                                                        )}
                                                    </FastField>
                                                </SttGrid>
                                            </>
                                        }
                                    </SttGrid>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting || !isValid}
                                                nomarginleft="true"
                                                onClick={() => handleChangePage(null, 0)}
                                            >
                                                {strings.pesquisar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    limparParametrosPesquisa();
                                                    callbackLimpar();
                                                    resetForm();
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            </>
                        );
                    }}
                </Formik>
            }
        </>
    );
};

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaPacsReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosPesquisa: (parametros) => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('FormPesquisaPacs')(FormPesquisa));
