import ConstantesPesquisaRede from '@constantes/pesquisa/rede';

const { REDUCER_TYPE } = ConstantesPesquisaRede;

const INITIAL_STATE = {
    parametrosPesquisa: {
        funcionario: '',
        evento: '',
        categoriaStt1: null,
        categoriaStt2: null,
        'data-de': null,
        'data-ate': null,
    },
};

function pesquisaLogFuncionarioReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa,
        };
    }

    if (type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA) { 
        return {
            ...state,
            parametrosPesquisa: payload.parametros,
        };
    }
    return state;
}

export default pesquisaLogFuncionarioReducer;
