import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    UF,
    MUNICIPIO,
} from './fieldNames';
import { SttAutocomplete } from '@stt-componentes/core';
import translate from '@componentes/translate';
import { getHeaders } from '@src/request';

const Uf = (props) => {
    const { form, field, obrigatorio, meta, strings } = props;
    const { name, value, onBlur } = field;
    const { setFieldValue } = form;

    const [ufs, setUfs] = useState([]);
    const [ufCarregando, setUfCarregando] = useState(true);

    useEffect(() => {
        const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/localizacao/estado?id-pais=1`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setUfs(itens);
                    if (value) {
                        if (Number.isInteger(value)) {
                            const estado = itens.find(e => e.id === value)
                            setFieldValue(UF, estado);
                        }
                    }
                }
                setUfCarregando(false);
            })
            .catch(err => console.log(err));
    }, [value]);

    return (
        <SttAutocomplete
            inputprops={{
                name: name,
                label: strings.uf,
                required: obrigatorio,
                error: meta.touched && meta.error ? meta.error : undefined
            }}
            getOptionLabel={option => option && option.sigla || ''}
            getOptionSelected={(option, valueOptionSelected) => {
                if (option && valueOptionSelected) {
                    if (valueOptionSelected.id) {
                        return option.id === valueOptionSelected.id
                    } else {
                        return option.id === valueOptionSelected;
                    }
                }
                return false;
            }
            }
            options={ufs}
            value={value}
            onBlur={onBlur}
            carregando={ufCarregando}
            onChange={(e, item) => {
                setFieldValue(UF, item || null);
                setFieldValue(MUNICIPIO, null);
            }}
        />
    )
}

export default translate('Paciente')(Uf);
