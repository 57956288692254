import { retornaMsalInstance } from "@src/index";
import { loginRequest, graphConfig } from "../azure-ad/authConfig";
import axios from 'axios';

const toCamelCase = (str) => {
	str = "" + str;
	return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
		return index === 0 ? word.toLowerCase() : word.toUpperCase();
	}).replace(/\s+/g, '');
};

const normalizarString = (string) => {
	return string
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toUpperCase();
}

const validarCEP = (cep) => {
	cep = cep.replace(/\D/g, '');
	return /^\d{8}$/.test(cep);
}

const callMsGraph = async (accessToken) => {
	if (true) {
		const account = retornaMsalInstance().getActiveAccount();
		if (!account) {
			throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
		}

		const response = await retornaMsalInstance().acquireTokenSilent({
			...loginRequest,
			account: account
		});
		accessToken = response.accessToken;
	}

	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "GET",
		headers: headers
	};

	return fetch(graphConfig().graphMeEndpoint, options)
		.then(response => response.json())
		.catch(error => console.log(error));
}

const recuperarCpfAzure = async (oid, accessToken) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`
    };

    const url = `${graphConfig().graphUsersEndpoint}${oid}?$select=employeeId`;

    try {
        const response = await axios.get(url, { headers });
        return response.data.employeeId;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export default { toCamelCase, normalizarString, validarCEP, callMsGraph, recuperarCpfAzure };