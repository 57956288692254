import React, { useState, useEffect } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttHeading,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttTableSortLabel,
    SttTableBody,
    SttCircularProgress,
    SttText,
    SttSeeButton,
    SttTablePagination
} from '@stt-componentes/core';
import Paper from '@material-ui/core/Paper';
import FormPesquisa from './form';
import translate from '@componentes/translate';
import {
    setInstituicaoAlteracao as setInstituicaoAlteracaoAction
} from '@redux/actions/cadastro/instituicao';
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        CNES: 'cnes',
        NOME: 'nome',
        CIDADE: 'cidade',
    }
};

const PesquisaInstituicao = (props) => {
    const { strings, setInstituicaoAlteracao, filtros } = props;
    const classes = useStyles();
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(filtros.limit || global.gConfig.pagination.count);
    const [page, setPage] = useState(filtros.page || global.gConfig.pagination.start);
    const [order, setOrder] = useState(filtros.direction || ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(filtros.sort || null);
    const [buscar, setBuscar] = useState(false);
    const [resetGrid, setResetGrid] = useState(false);
    const [instituicao, setInstituicao] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    };

    const resetPageOrder = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setInstituicao(dados.itens);
        setBuscar(false);
    };

    const callbackLimpar = () => {
        setResetGrid(true);
    };

    useEffect(() => {
        if (resetGrid) {
            setInstituicao([]);
            setTotalRegistros(0);
            setResetGrid(false);
        }
    }, [resetGrid]);

    const clickEditarInstituicao = (instituicao) => {

        const descricaoArray = instituicao.setores_instituicao.map((item) => ({
            id: item.id,
            descricao: item.descricao,
        }));

        setInstituicaoAlteracao({
            id: instituicao.id,
            nome: instituicao.nome,
            codinome: instituicao.codinome,
            cnes: instituicao.cnes,
            cnpj: instituicao.cnpj,
            logradouro: instituicao.logradouro,
            bairro: instituicao.bairro,
            estado: instituicao.id_estado ? { id: instituicao.id_estado, sigla: instituicao.sigla_estado } : null,
            cidade: instituicao.id_municipio ? { id: instituicao.id_municipio, nome: instituicao.nome_municipio } : null,
            cep: instituicao.cep,
            tipo_estabelecimento: instituicao.codigo_estabelecimento ? { codigo: instituicao.codigo_estabelecimento, descricao: instituicao.tipo_estabelecimento } : null,
            codigo_estabelecimento: instituicao.codigo_estabelecimento,
            logotipo: instituicao.logotipo,
            logotipo_excluido: instituicao.logotipo_excluido,
            nome_arquivo_logotipo: instituicao.nome_arquivo_logotipo,
            url_logotipo: instituicao.url_logotipo,
            consultorio_virtual_habilitado: instituicao.consultorio_virtual_habilitado,
            setores_instituicao: descricaoArray,
            setores_associados: instituicao.setores_associados,
        })

        navigate(`/cadastro/instituicao`);
    };

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>

            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
                resetPageOrder={resetPageOrder}
                resetGrid={resetGrid}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.CNES ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CNES}
                                        direction={orderBy === ORDENACAO.CAMPOS.CNES ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CNES)}
                                    >
                                        {strings.cnes}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="42%" sortDirection={orderBy === ORDENACAO.CAMPOS.NOME ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NOME}
                                        direction={orderBy === ORDENACAO.CAMPOS.NOME ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NOME)}
                                    >
                                        {strings.nome}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="40%" sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}
                                    >
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="8%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Pesquisa de instituições</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={4}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    instituicao.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={13}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        instituicao.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.cnes ? row.cnes : ''}</SttTableCell>
                                                    <SttTableCell>{row.nome ? row.nome : ''}</SttTableCell>
                                                    <SttTableCell>{row.nome_municipio ? `${row.nome_municipio}/${row.sigla_estado}` : ''}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.cnes}:</b> {row.cnes ? row.cnes : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.nome}:</b> {row.nome ? row.nome : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.cidadeUf}:</b> {row.nome_municipio ? `${row.nome_municipio}/${row.sigla_estado}` : ''}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            clickEditarInstituicao(row);
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                instituicao.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }
        </>

    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.pesquisaInstituicaoReducer.parametrosPesquisa
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInstituicaoAlteracao: id => dispatch(setInstituicaoAlteracaoAction(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaInstituicao')(PesquisaInstituicao));

