/* eslint-disable import/no-anonymous-default-export */

import { URL_BASE, DCM4CHEE, URL_TOKEN, CLIENT_ID, CLIENT_SECRET } from '@componentes/interno/cadastro/pacs/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};

    formulario[URL_BASE] = dadosAlteracao.url_base ? dadosAlteracao.url_base : '';
    formulario[DCM4CHEE] = dadosAlteracao.versao_dcm4chee ? dadosAlteracao.versao_dcm4chee : '';
    formulario[URL_TOKEN] = dadosAlteracao.token_url ? dadosAlteracao.token_url : '';
    formulario[CLIENT_ID] = dadosAlteracao.token_client_id ? dadosAlteracao.token_client_id : '';
    formulario[CLIENT_SECRET] = dadosAlteracao.token_client_secret ? dadosAlteracao.token_client_secret : '';

    return formulario;
};

const formatarCadastro = (dados) => {
    const formData = new FormData();

    formData.append('url_base', dados.url_base.trim());
    formData.append('versao_dcm4chee', dados.versao_dcm4chee);
    formData.append('token_url', dados.token_url.trim());
    formData.append('token_client_id', dados.token_client_id.trim());
    formData.append('token_client_secret', dados.token_client_secret.trim());

    return formData;
};

export default { inicializarValoresFormulario, formatarCadastro };
