import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttInput,
    SttModal
} from '@stt-componentes/core';
import {
    Formik,
    FastField,
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '@src/request';
import translate from "@componentes/translate";
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import { connect } from 'react-redux';

const validationSchema = (strings) => {
    return yup.object().shape({
        'motivo': yup
            .string()
            .trim()
            .min(10, strings.motivoInvalido)
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    },
    divExternaImagens: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'hidden'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    botaoMenos: {
        minWidth: 0
    },
    container: {
        marginBottom: 0
    }
}));

const RejeitarAutoCadastro = ({ callback, id, strings, abrirModalRejeitar, callbackSucessoRejeitarCadastro, setOpen, setParametrosAlerta, setIdSolicitacaoCadastro }) => {
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;

    const initialValues = {
        motivo: ''
    };

    const rejeitarSolicitacao = (dados, setSubmitting, resetForm) => {
        setSubmitting(true);

        const formData = new FormData();
        formData.append('id', id);
        formData.append('motivo', dados.motivo);

        axios.post(`${ADM_API_BASE_URL}/rejeitar-solicitacao`, formData, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;

                if (response.status === HttpStatus.OK) {
                    const parametrosAlerta = {
                        titulo: strings.sucesso,
                        mensagem: data.message,
                        tipo: 'success',
                        open: true,
                        opcoes: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setOpen(false);
                                    setIdSolicitacaoCadastro(null);
                                    resetForm({ values: initialValues });
                                    callbackSucessoRejeitarCadastro();
                                }
                            }
                        ],
                        onClose: () => {
                            setOpen(false);
                            setIdSolicitacaoCadastro(null);
                            resetForm({ values: initialValues });
                            callbackSucessoRejeitarCadastro();
                        }
                    };
                    setParametrosAlerta(parametrosAlerta);
                } else {
                    const parametrosAlerta = {
                        titulo: strings.erro,
                        mensagem: strings.erroRejeitarCadastro,
                        tipo: 'error',
                        open: true,
                        opcoes: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setOpen(false);
                                }
                            }
                        ]
                    };
                    setParametrosAlerta(parametrosAlerta);
                }
            })
            .catch(err => {
                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: strings.erroRejeitarCadastro,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <SttModal
            title={strings.titulo}
            open={abrirModalRejeitar}
            maxWidth="lg"
            outModalClose={callback}
            iconClose={callback}
            fullWidth={true}
            children={
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting, resetForm }) => {
                        const parametrosAlerta = {
                            titulo: strings.atencao,
                            mensagem: strings.confirmacaoRejeitarSolicitacao,
                            tipo: 'alert',
                            open: true,
                            opcoes: [
                                {
                                    title: strings.sim,
                                    onClick: () => {
                                        rejeitarSolicitacao(dados, setSubmitting, resetForm);
                                    }

                                },
                                {
                                    title: strings.nao,
                                    onClick: () => {
                                        setOpen(false);
                                        setSubmitting(false);
                                    }

                                }
                            ]
                        };
                        setParametrosAlerta(parametrosAlerta);
                        return true;
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <FastField name='motivo'>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttInput
                                                        multiline
                                                        rows={5}
                                                        required={true}
                                                        {...field}
                                                        onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                                        label={strings.motivo}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </FastField>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />
                                    </SttGrid>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                className={classes.button}
                                                disabled={isSubmitting}
                                            >
                                                {strings.confirmar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues });
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                className={classes.button}
                                                onClick={() => {
                                                    resetForm({ values: initialValues });
                                                    callback();
                                                }}
                                            >
                                                {strings.fechar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            )
                        }
                    }
                </Formik>
            }
        />

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(null, mapDispatchToProps)(translate('RejeitarAutoCadastro')(RejeitarAutoCadastro));