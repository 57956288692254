import React from 'react';
import CadastroCidadeRef from '@src/componentes/interno/cadastro/cidade-referencia/index';

//const CadastroCidadeRef = lazy(() => import('@componentes/interno/cadastro/cidade-referencia/index'));

const CidadeReferencia = () => {
    return (    
        <>
        <CadastroCidadeRef/>
        </>
    );
};

export default CidadeReferencia;