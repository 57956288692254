export const DADOS_GERAIS = 'dadosGerais';
export const ID = 'id';
export const NOME = 'nome';
export const DESCRICAO = 'descricao';
export const DATA = 'data';
export const ATIVO = 'ativo';
export const ANEXOS = 'anexos';
export const URL_ANEXO = 'url_anexo';
export const NOME_ARQUIVO = 'nome_arquivo';
export const URL_MINIO = 'url_minio';



