import {
    DESCRICAO,
    IDENTIFICADOR,
    VINCULO,
    TIMEOUT_TOKEN
} from '@componentes/interno/cadastro/perfil/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};

    formulario[IDENTIFICADOR] = dadosAlteracao.identificador ? dadosAlteracao.identificador : '';
    formulario[DESCRICAO] = dadosAlteracao.descricao ? dadosAlteracao.descricao : '';
    formulario[VINCULO] = dadosAlteracao.vinculo ? dadosAlteracao.vinculo : null;
    formulario[TIMEOUT_TOKEN] = dadosAlteracao.timeout_token ? dadosAlteracao.timeout_token : '';

    return formulario;
}

const formatarCadastro = (dados) => {
    const formData = new FormData();

    formData.append('identificador', dados.identificador.trim());
    formData.append('descricao', dados.descricao.trim());
    formData.append('vinculo', dados.vinculo.identificador);
    formData.append('timeoutToken', dados.timeoutToken);

    return formData;
};


export default { inicializarValoresFormulario, formatarCadastro };