import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttBox,
    SttHeading,
    SttText
} from '@stt-componentes/core';
import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';
import ConfirmacaoMensagem from './confirmacao-mensagem';
import BuscaCpf from './busca-cpf';
import SelecaoUsuario from './selecao-usuario';
import translate from "@componentes/translate";

const { SITUACAO } = ConstantesSolicitacaoCadastro;

const useStyles = makeStyles(theme => ({
    formColumn: {
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0.5, 2.5, 0.5, 2.5),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1.5, 4.5, 1.5, 4.5),
        },
    },
    heading: {
        marginBottom: '30px'
    },
    text: {
        marginBottom: '20px'
    },
    buttons: {
        marginTop: '20px'
    },
    errorText: {
        marginTop: '-2px',
    },
    form: {
        marginTop: '20px',
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    texto2: {
        alignSelf: 'start'
    }
}));

const switchSituacao = (situacaoSolicitacao, classes) => {
    switch (situacaoSolicitacao) {
        case SITUACAO.AVISO_MENSAGEM:
            return (
                <ConfirmacaoMensagem classes={classes} />
            );
        case SITUACAO.BUSCA_CPF:
            return (
                <BuscaCpf classes={classes} />
            );
        case SITUACAO.SELECAO_USUARIO_NOVA_SENHA:
            return (
                <SelecaoUsuario classes={classes} />
            );
        default:
            return null;
    }
}

const Aviso = ({ aviso, strings }) => {
    const classes = useStyles();

    return (
        <SttBox className={classes.formColumn}>
            <SttHeading variant="h2" color="primary" className={classes.heading}>
                {strings.solicitacao}
            </SttHeading>
            <SttText className={classes.text}>
                {strings.texto1(global.gConfig.instancia_nome)}
            </SttText>
            <SttText className={[classes.text, classes.texto2]}>
                {strings.texto2}
            </SttText>
            <SttText className={classes.text}>
                {strings.texto3}
            </SttText>
            <SttText className={classes.text}>
                {strings.texto4(global.gConfig.config_id)}
            </SttText>
            <SttText className={classes.text}>
                {strings.texto5}
            </SttText>
            {
                switchSituacao(aviso.situacaoSolicitacao, classes)
            }
        </SttBox>
    );
}

const mapStateToProps = (state) => {
    return {
        aviso: state.solicitacaoCadastroReducer.aviso
    };
};

export default connect(mapStateToProps, null)(translate('SolicitacaoAviso')(Aviso));