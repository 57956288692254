const estruturarFiltros = (dados) => {
    const filtros = {};

    if (dados.modalidade) {
        filtros.modalidade = dados.modalidade.id;
    }

    if (dados.municipio) {
        filtros['municipio-referencia'] = dados.municipio.id;
    }

    if(dados.cidadesSelecionadas) {
        filtros.cidades = dados.cidadesSelecionadas.map(cidade => `${cidade.id}`)
    }

    return filtros;
};

export default estruturarFiltros;