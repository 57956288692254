import * as yup from 'yup';
import validarCpf from 'validar-cpf';
import { validateBr } from 'js-brasil';
import { CPF, CNS } from './fieldNames';

export default (strings) => {
    return yup.object().shape(
        {
            [CPF]: yup.string().when(CNS, {
                is: (val) => !val || val.length === 0,
                then: yup
                    .string()
                    .nullable()
                    .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                        cpf = (cpf || '').replace(/\D+/g, '');
                        if (cpf.length === 0) {
                            return true;
                        }
                        return validarCpf(cpf);
                    })
                    .required(strings.cpfInvalido),
                otherwise: yup
                    .string()
                    .nullable()
                    .test('cpf-valido', strings.cpfInvalido, (cpf) => {
                        cpf = (cpf || '').replace(/\D+/g, '');
                        if (cpf.length === 0) {
                            return true;
                        }
                        return validarCpf(cpf);
                    }),
            }),
            [CNS]: yup.string().when(CPF, {
                is: (val) => !val || val.length === 0,
                then: yup
                    .string()
                    .nullable()
                    .test('cns-valido', strings.cnsInvalido, (cns) => {
                        cns = cns || '';
                        if (cns.length === 0) {
                            return false;
                        }
                        return validateBr.cns(cns.trim());
                    })
                    .required(strings.cnsInvalido),
                otherwise: yup
                    .string()
                    .nullable()
                    .test('cns-valido', strings.cnsInvalido, (cns) => {
                        cns = cns || '';
                        if (cns.length === 0) {
                            return true;
                        }
                        return validateBr.cns(cns.trim());
                    }),
            }),
        },
        [[CPF, CNS]]
    );
};
