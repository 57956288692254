import React, { useState } from "react";
import { connect } from 'react-redux';
import {
    SttFormGroup,
    SttButton,
    SttSelect,
    SttMenuItem,
    SttAlerta
} from '@stt-componentes/core';
import httpStatus from 'http-status-codes';
import axios from 'axios';
import ConstantesSolicitacao from '@constantes/solicitacao-cadastro';
import translate from "@componentes/translate";

const { INITIAL_STATE_ALERTA } = ConstantesSolicitacao;

const SelecaoUsuario = ({ aviso, classes, strings }) => {
    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');
    const [alertaState, setAlertaState] = useState(INITIAL_STATE_ALERTA);

    const handleChangeSelect = event => {
        setUsuarioSelecionado(event.target.value);
    };

    const setWindowLocation = () => event => {
        window.location = global.gConfig.url_redirect_nova_senha;
    }

    const clickSelecionar = () => {
        const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
        axios.post(`${ADM_API_BASE_URL}/nova-senha`, { login: usuarioSelecionado, instancia: global.gConfig.basename })
            .then(function (response) {
                const { data } = response;
                setAlertaState({
                    onClose: () => onClose(),
                    visivel: true,
                    mensagem: data.message,
                    tipo: 'success',
                    titulo: strings.sucesso,
                    botoes: [
                        {
                            title: strings.ok,
                            onClick: setWindowLocation()
                        }
                    ]
                });
            })
            .catch(function ({ response: { data, status } }) {
                if (status === httpStatus.INTERNAL_SERVER_ERROR) {
                    setAlertaState({
                        visivel: true,
                        mensagem: strings.mensagemErroGeral,
                        tipo: 'error',
                        titulo: 'Erro!',
                        onClose: () => onClose(),
                        botoes: [
                            {
                                title: strings.ok,
                                onClick: onClose()
                            }
                        ]
                    });
                } else {
                    let mensagemErro = '';
                    data.errors.forEach(e => mensagemErro = mensagemErro.concat(e.message));
                    mensagemErro = data.message || mensagemErro;
                    setAlertaState({
                        visivel: true,
                        mensagem: mensagemErro !== '' ? mensagemErro : strings.mensagemErroGeral,
                        tipo: 'error',
                        titulo: strings.erro,
                        botoes: [
                            {
                                title: strings.ok,
                                onClick: onClose()
                            }
                        ]
                    });
                }
            });
    };

    const handleOnClose = () => {
        setAlertaState({ ...alertaState, visivel: false });
    };

    const onClose = () => handleOnClose;

    return (
        <SttFormGroup row className={classes.form}>
            <SttAlerta open={alertaState.visivel}
                title={alertaState.titulo}
                message={alertaState.mensagem}
                type={alertaState.tipo}
                options={alertaState.botoes}
                onClose={onClose()} />
            <SttSelect label={strings.usuario}
                required
                value={usuarioSelecionado}
                name="seletor-usuario"
                id="seletor-usuario"
                onChange={handleChangeSelect}>
                {
                    aviso.usuarios.map(option => (
                        <SttMenuItem key={option.id} value={option.login}>
                            {option.login}
                        </SttMenuItem>
                    ))
                }
            </SttSelect>
            <SttButton variant="contained"
                color="primary"
                nomarginleft="true"
                name="btn-selecionar-usuario"
                className={classes.buttons}
                onClick={clickSelecionar}>
                {strings.selecionar}
            </SttButton>
        </SttFormGroup>
    );
}

const mapStateToProps = (state) => {
    return {
        aviso: state.solicitacaoCadastroReducer.aviso
    };
};

export default connect(mapStateToProps, null)(translate('SolicitacaoSelecaoUsuario')(SelecaoUsuario));