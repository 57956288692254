import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import FormPesquisa from './form';
import translate from '@componentes/translate';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {
    SttHeading,
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
} from '@stt-componentes/core';
import { useLocation } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        FUNCIONARIO: 'nome_funcionario',
        DATA: 'data',
        URL: 'url',
        EVENTO: 'evento', 
    }
}

const TruncatedText = ({ text, maxLength }) => {
    const isTruncated = text?.length > maxLength;

    const renderText = () => {
        if (isTruncated) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    return (
        <Tooltip title={isTruncated ? text : ''} placement="top">
            <span>{renderText()}</span>
        </Tooltip>
    );
};

const Pesquisa = (props) => {
    const {strings} = props;
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.DESC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);
    const [resetGrid, setResetGrid] = useState(false);
    
    const [logFuncionario, setLogFuncionario] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);


    const location = useLocation();


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    };

    const resetPageOrder = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.DESC);
        setOrderBy(null);
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setLogFuncionario(dados.itens);
        setBuscar(false);
    }

    const callbackLimpar = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.DESC);
        setOrderBy(null);
        setLogFuncionario([]);
        setTotalRegistros(0);
    }

    useEffect(() => {
        if (resetGrid) {
            setLogFuncionario([]);
            setTotalRegistros(0);
            setResetGrid(false);
        }
    }, [resetGrid]);

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>

            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
                resetPageOrder={resetPageOrder}
                resetGrid={resetGrid}
            />
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO}
                                        direction={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.FUNCIONARIO)}
                                    >
                                        {strings.funcionario}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}
                                    >
                                        {strings.data}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.URL ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.URL}
                                        direction={orderBy === ORDENACAO.CAMPOS.URL ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.URL)}
                                    >
                                        {strings.url}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="40%" sortDirection={orderBy === ORDENACAO.CAMPOS.EVENTO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.EVENTO}
                                        direction={orderBy === ORDENACAO.CAMPOS.EVENTO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.EVENTO)}
                                    >
                                        {strings.evento}
                                    </SttTableSortLabel>
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Pesquisa de Log Funcionario</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={2}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    logFuncionario.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={2}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        logFuncionario.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.nome_funcionario}</SttTableCell>
                                                    <SttTableCell><Moment format="DD/MM/YYYY HH:mm:ss">{row.data}</Moment></SttTableCell>
                                                    <SttTableCell><TruncatedText text={row.url} maxLength={40}/></SttTableCell>
                                                    <SttTableCell>{row.evento}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.funcionario}:</b> {row.funcionario}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.data}:</b> {row.data}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.url}:</b> {row.url}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.evento}:</b> {row.evento}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                logFuncionario.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }
        </>
    )
}

Pesquisa.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(translate('PesquisaLogFuncionario')(Pesquisa));