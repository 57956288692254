/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup'

import dadosGeraisSchema from '@componentes/interno/cadastro/pontos/dadosGerais/form/validationSchema';
import dadosTipoSchema from '@componentes/interno/cadastro/pontos/dadosTipo/form/validationSchema';
import dadosVinculoSchema from '@componentes/interno/cadastro/pontos/dadosVinculo/form/validationSchema';

export default (strings) => {
    const schema = yup.object().shape({});
    const dadosGerais = dadosGeraisSchema(strings);
    const dadosTipo = dadosTipoSchema(strings);
    const dadosVinculo = dadosVinculoSchema(strings);

    return schema
        .concat(dadosGerais)
        .concat(dadosTipo)
        .concat(dadosVinculo)
}