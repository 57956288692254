import {
    ID,
    NOME,
    DESCRICAO,
    DATA,
    ATIVO,
    ANEXOS,
    URL_ANEXO,
    NOME_ARQUIVO,
    URL_MINIO
} from '@componentes/interno/cadastro/materiais-suporte/dadosGerais/form/fieldNames';

import {
    LISTAS2,
    LISTA2

} from '@componentes/interno/cadastro/materiais-suporte/perfis/form/fieldNames';
import moment from 'moment';

import {
    LISTA,
    LISTAS

} from '@componentes/interno/cadastro/materiais-suporte/modulos/form/fieldNames';

const inicializarValoresFormulario = (dadosAlteracao) => {
    let formulario = {};
    dadosAlteracao.anexos = {
        id: dadosAlteracao.id ? dadosAlteracao.id : null,
        nome_arquivo: dadosAlteracao.nome_arquivo ? dadosAlteracao.nome_arquivo : '',
        url_anexo: dadosAlteracao.url_anexo ? dadosAlteracao.url_anexo : null,
        url_minio: dadosAlteracao.url_minio ? dadosAlteracao.url_minio : ''
    }

    formulario[ID] = dadosAlteracao.id ? dadosAlteracao.id : null;
    formulario[NOME] = dadosAlteracao.nome ? dadosAlteracao.nome : '';
    formulario[DESCRICAO] = dadosAlteracao.descricao ? dadosAlteracao.descricao : '';
    formulario[DATA] = dadosAlteracao.data ? dadosAlteracao.data : '';
    formulario[ATIVO] = dadosAlteracao.ativo === 'Ativo';
    formulario[ANEXOS] = dadosAlteracao.anexos ? dadosAlteracao.anexos : null;
    formulario[URL_ANEXO] = dadosAlteracao.url_anexo ? dadosAlteracao.url_anexo : null;
    formulario[NOME_ARQUIVO] = dadosAlteracao.url_anexo ? dadosAlteracao.nome_arquivo : null;
    formulario[URL_MINIO] = dadosAlteracao.url_minio ? dadosAlteracao.url_minio : '';
    formulario[LISTAS2] = [];
    formulario[LISTA2] = null;
    formulario[LISTAS] = [];
    formulario[LISTA] = null;


    if (dadosAlteracao.lista2 && Array.isArray(dadosAlteracao.lista2)) {
        formulario[LISTAS2] = dadosAlteracao.lista2
            .filter(item => item !== null)
            .map(item => ({ lista2: item }));
    }
    if (dadosAlteracao.lista && Array.isArray(dadosAlteracao.lista)) {
        formulario[LISTAS] = dadosAlteracao.lista
            .filter(item => item !== null)
            .map(item => ({ lista: item }));
    }

    return formulario;
}

const formatarCadastro = (dados) => {
    const formData = new FormData();

    formData.append('id', dados.id);
    formData.append('nome', dados.nome.trim());
    formData.append('descricao', dados.descricao.trim());
    formData.append('ativo', dados.ativo);
    formData.append('anexos', dados.anexos);
    formData.append('url_minio', dados.url_minio);

     if (dados.listas2 && dados.listas2.length > 0) {
         for (const lista of dados.listas2) {
             if (lista.lista2) {
                 formData.append('listas2', lista.lista2);
             }
         }
     }    

     if (dados.listas && dados.listas.length > 0) {
        for (const lista of dados.listas) {
            if (lista.lista) {
                formData.append('listas', lista.lista);
            }
        }
    }    

    if (dados.data) {
        let dataMoment = moment(dados.data);
        if (dataMoment.isValid()) {
            formData.append('data', dataMoment.format('YYYY-MM-DD'));
        }
    }

    return formData;
};


export default { inicializarValoresFormulario, formatarCadastro};