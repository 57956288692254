import React, { useEffect, useState } from "react";
import {
    SttFormGroup,
    SttButton,
    SttNumberInput,
    SttLoading,
    SttText,
    SttAlerta
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import axios from 'axios';
import translate from "@componentes/translate";
import { KEY_RETURN } from 'keycode-js';
import { setAccessToken as setAccessTokenAction } from '../../../../redux/actions/paciente';

const INITIAL_STATE_ALERTA = {
    titulo: '',
    mensagem: '',
    tipo: 'alert',
    visivel: false,
    botoes: [],
    onClose: () => null
};

const ESCONDER_DIA = 0;
const ESCONDER_MES = 1;
const ESCONDER_ANO = 2;

const ContraSenha = ({ classes, strings, paciente, setAccessToken, setSituacao }) => {
    const [state, setState] = useState({
        ano: '',
        mes: '',
        dia: ''
    });
    const [campoEsconder] = useState(Math.floor(Math.random() * 3));
    const [carregando, setCarregando] = useState(false);
    const [alertaState, setAlertaState] = useState(INITIAL_STATE_ALERTA);

    const handleChangeCampo = event => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleOnClose = () => {
        setCarregando(false);
        setAlertaState({ ...alertaState, visivel: false });
    };

    const onClose = () => handleOnClose;

    useEffect(() => {
        if (carregando) {
            const { ano, mes, dia } = state;
            const dados = {
                cpf: paciente.cpf
            };
            if (ano && ano != '') {
                dados.ano = ano;
            }
            if (mes && mes != '') {
                dados.mes = mes;
            }
            if (dia && dia != '') {
                dados.dia = dia;
            }
            axios.post(`${global.gConfig.url_base_administrativo}/validar-contra-senha`, dados)
                .then((response) => {
                    if (response.data) {
                        const { errors, data } = response.data;
                        setCarregando(false)
                        if (errors.length > 0) {
                            // Algum dos campos digitados na validação está incorreto.
                            setAlertaState({
                                ...alertaState,
                                visivel: true,
                                mensagem: strings.dadosDigitadosInvalidos,
                                tipo: 'error',
                                titulo: strings.erroValidacao,
                                onClose: () => onClose(),
                                botoes: [
                                    {
                                        title: strings.ok,
                                        onClick: onClose()
                                    }
                                ]
                            });
                            return;
                        }

                        setAlertaState({
                            ...alertaState,
                            visivel: true,
                            mensagem: strings.sucesso,
                            tipo: 'success',
                            titulo: strings.sucesso,
                            onClose: () => {
                                setAccessToken(data.access_token);
                                setSituacao();
                            },
                            botoes: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setAccessToken(data.access_token);
                                        setSituacao();
                                    }
                                }
                            ]
                        });
                    }
                })
                .catch(function () {
                    setAlertaState({
                        ...alertaState,
                        visivel: true,
                        mensagem: strings.erroGenericoValidacao,
                        tipo: 'error',
                        titulo: strings.erro,
                        onClose: () => onClose(),
                        botoes: [
                            {
                                title: strings.ok,
                                onClick: onClose()
                            }
                        ]
                    });
                });
        }
    }, [carregando]);

    const clickValidarContraSenha = () => {
        if (carregando) {
            return;
        }

        const { ano, mes, dia } = state;
        let contParams = 0;
        if (ano && ano != '') {
            contParams++;
        }
        if (mes && mes != '') {
            contParams++;
        }
        if (dia && dia != '') {
            contParams++;
        }
        if (contParams < 2) {
            return;
        }

        setCarregando(true);
    };

    const handleChangeKeyUp = (event) => {
        if (event.keyCode === KEY_RETURN) {
            clickValidarContraSenha();
        }
    }

    return (
        <>
            <SttText className={classes.text}>
                {strings.validarDados}
            </SttText>
            <SttFormGroup row className={classes.form}>
                {
                    campoEsconder !== ESCONDER_DIA &&
                    <SttNumberInput
                        inputProps={{
                            maxLength: 2
                        }}
                        name="dia"
                        value={state.dia}
                        required={true}
                        onKeyUp={handleChangeKeyUp}
                        onChange={handleChangeCampo}
                        label={strings.dia}
                    />
                }
                {
                    campoEsconder !== ESCONDER_MES &&
                    <SttNumberInput
                        inputProps={{
                            maxLength: 2
                        }}
                        name="mes"
                        value={state.mes}
                        onKeyUp={handleChangeKeyUp}
                        onChange={handleChangeCampo}
                        required={true}
                        label={strings.mes}
                    />
                }
                {
                    campoEsconder !== ESCONDER_ANO &&
                    <SttNumberInput
                        inputProps={{
                            maxLength: 4
                        }}
                        name="ano"
                        value={state.ano}
                        onKeyUp={handleChangeKeyUp}
                        onChange={handleChangeCampo}
                        required={true}
                        label={strings.ano}
                    />
                }
                <SttButton variant="contained"
                    color="primary"
                    nomarginleft="true"
                    className={classes.buttons}
                    onClick={clickValidarContraSenha}>
                    {strings.validar}
                </SttButton>
            </SttFormGroup>
            <SttLoading
                open={carregando}
                text={strings.carregando}
            />
            <SttAlerta open={alertaState.visivel}
                title={alertaState.titulo}
                message={alertaState.mensagem}
                type={alertaState.tipo}
                options={alertaState.botoes}
                onClose={alertaState.onClose()}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        paciente: state.pacienteReducer.paciente
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAccessToken: (token) => dispatch(setAccessTokenAction(token)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(translate('Checkin')(ContraSenha));