import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttPaper,
    SttHeading,
    SttBox,
    SttGrid
} from '@stt-componentes/core';
import Aviso from '../aviso/aviso';
import AvisoPaciente from '../avisoPaciente/aviso';
import Solicitacao from '../solicitacao';
import SolicitacaoPaciente from '../solicitacaoPaciente';
import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';
import LayoutUfpa from './layoutsAlternativos/layoutOntdUfpa';
const { SITUACAO } = ConstantesSolicitacaoCadastro;

const useStyles = makeStyles(theme => ({
    boxAviso: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: 'column',
        width: "100%",
        maxWidth: "900px"
    },
    boxSolicitarCadastro: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: 'column',
        width: "100%",
        maxWidth: "1200px"
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    forms: {
        alignItems: "stretch",
        justifyContent: "center",
        flexDirection: 'row',
        display: "flex",
        paddingBottom: theme.spacing(3),
        flexWrap: "wrap"
    },
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: "2px solid",
        borderColor: theme.palette.background.border,
        display: "flex",
        marginTop: theme.spacing(1),
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1.5, 1.5, 0.5, 1.5),
        },
    }
}));

const Conteudo = ({ aviso, layout, paciente = false }) => {
    const classes = useStyles();

    const box = (aviso.situacaoSolicitacao === SITUACAO.SOLICITACAO_CADASTRO) ? classes.boxSolicitarCadastro : classes.boxAviso;

    const [layoutAlternativo, setLayoutAlternativo] = useState(false);

    useEffect(() => {
        if (global.gConfig.config_id === 'ufsc' && layout && global.gConfig.layouts_alternativos[layout]) {
            setLayoutAlternativo(true);
        }
    }, []);

    const switchLayout = () => {
        switch (layout) {
            case 'layout-ontd-ufpa':
                return <LayoutUfpa aviso={aviso} />
            default:
                return ''
        }
    }

    return (
        layoutAlternativo
            ?
            switchLayout()
            :
            <SttBox className={box}>
                <SttHeading variant="h1" color="primary" className={classes.heading}>
                    {global.gConfig.instancia_nome}
                </SttHeading>
                <SttGrid container className={classes.forms}>
                    {
                        (aviso.situacaoSolicitacao === SITUACAO.SOLICITACAO_CADASTRO) ? (
                            <SttGrid item xs={12}>
                                <SttPaper className={classes.formColumn}>
                                    {
                                        paciente
                                            ?
                                            <SolicitacaoPaciente layout={layout} />
                                            :
                                            <Solicitacao />
                                    }
                                </SttPaper>
                            </SttGrid>
                        ) : (
                            <SttGrid item md={8} sm={9} xs={12}>
                                <SttPaper className={classes.formColumn}>
                                    {
                                        paciente
                                            ?
                                            <AvisoPaciente layout={layout} />
                                            :
                                            <Aviso />
                                    }
                                </SttPaper>
                            </SttGrid>
                        )
                    }
                </SttGrid>
            </SttBox>
    );
}

export default connect(state => ({
    aviso: state.solicitacaoCadastroReducer.aviso
}))(Conteudo);