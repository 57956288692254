import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttExpansionPanel,
    SttContainer,
    SttDivider,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton,
    SttGrid
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik
} from 'formik';
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setMateriaisAlteracao as setMateriaisAlteracaoAction
} from '@redux/actions/cadastro/materiais-suporte/index';

import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';

import UtilMateriaisSuporte from '@util/materiais-suporte';

const DadosGerais = lazy(() => import('@componentes/interno/cadastro/materiais-suporte/dadosGerais'));
const Perfis = lazy(() => import('@componentes/interno/cadastro/materiais-suporte/perfis'));
const Modulos = lazy(() => import('@componentes/interno/cadastro/materiais-suporte/modulos'));


const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const Carregando = memo((props) => {
    return (
        <SttLoading {...props} />
    )
});

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const CadastroMateriaisSuporte = (params) => {
    const { strings, user, setParametrosAlerta, setOpen, materiaisAlteracao, setMateriaisAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [carregando, setCarregando] = useState(false);
    const secaoDadosGerais = useRef(null);
    const secaoPerfis = useRef(null);
    const secaoModulos = useRef(null);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'dadosGerais': true,
        'perfis': false,
        'modulos': false,
    });

    useEffect(() => {
        if (materiaisAlteracao) {
            axios.get(`${ADM_API_BASE_URL}/materiais-suporte/${materiaisAlteracao.id}`, { headers: getHeaders() })
                .then((response) => {
                    setInitialValues(UtilMateriaisSuporte.inicializarValoresFormulario(response.data.data));
                    setCarregado(true);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            setInitialValues(UtilMateriaisSuporte.inicializarValoresFormulario({}));
            setCarregado(true);
        }
    }, [materiaisAlteracao]);

    const redirectPesquisaMateriaisSuporte= () => {
        setOpen(false);
        setMateriaisAlteracao(null);
        navigate("/pesquisa/materiais-apoio", { state: { pesquisar: true } });
    }

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);
        axios
            .post(`${ADM_API_BASE_URL}/materiais-suporte`, dados, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {

                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisaMateriaisSuporte
                        }
                    ],
                    onClose: redirectPesquisaMateriaisSuporte
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        mensagem = data.message;
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };
                setParametrosAlerta(parametrosAlerta);
            });
    };

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }

        novoSecoesAbertas[secao] = estado;

        setSecoesAbertas(novoSecoesAbertas);
    }

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting, resetForm }) => {
                            let mensagem = strings.confirmacaoCadastroMateriaisSuporte;
                            if (materiaisAlteracao) {
                                mensagem = strings.confirmacaoAlteracaoMateriaisSuporte;
                            }
                            
                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            let dadosFormatados = UtilMateriaisSuporte.formatarCadastro(dados);
                                            if (materiaisAlteracao) {
                                                dadosFormatados.append('alteracao', true);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };

                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                                setStatus,
                                isValid

                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        
                                                        opcoes: [
                                                            {isValid,
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setMateriaisAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/materiais-apoio", { state: { pesquisar: true } });
                                                                }

                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>

                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{materiaisAlteracao? strings.alteracaoMateriaisSuporte : strings.cadastroMateriaisSuporte}</SttHeading>
                                            {/* Dados gerais */}
                                            <SttExpansionPanel
                                                title={strings.dadosGerais}
                                                opened={secoesAbertas['dadosGerais']}
                                                callback={estadoInterno => abrirSecao('dadosGerais', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosGerais}></div>
                                                        <DadosGerais
                                                            carregando={carregando}
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.perfis}
                                                opened={secoesAbertas['perfis']}
                                                callback={estadoInterno => abrirSecao('perfis', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoPerfis}></div>
                                                        <Perfis
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                            
                                            <SttExpansionPanel
                                                title={strings.modulos}
                                                opened={secoesAbertas['modulos']}
                                                callback={estadoInterno => abrirSecao('modulos', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoModulos}></div>
                                                        <Modulos
                                                            user={user}
                                                            setCarregando={setCarregando}
                                                        />
                                                    </Suspense>
                                                }
                                            />

                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12} >
                                            <SttLoading
                                                open={isSubmitting}
                                                text={strings.carregando}
                                            />
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                nomarginleft="true"
                                                disabled={isSubmitting}
                                            >
                                                {strings.salvar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => {
                                                    resetForm();
                                                    setStatus('RESET');
                                                }}
                                                style={{ display: materiaisAlteracao ? 'none' : '' }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :
                    (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }
            <Carregando
                open={carregando}
                text={strings.carregando}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        materiaisAlteracao: state.cadastroMateriaisSuporteReducer.materiaisAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMateriaisAlteracao: material  => dispatch(setMateriaisAlteracaoAction(material)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroMateriaisSuporte')(CadastroMateriaisSuporte));