import ConstantesTriagemAcessoInstituicao from '@constantes/pesquisa/acesso-instituicao';

const { REDUCER_TYPE } = ConstantesTriagemAcessoInstituicao

const INITIAL_STATE = {
    parametrosPesquisa: {
        funcionario_solicitante: '',
        instituicao: null,
        situacao: null
    }
};

function triagemAcessoInstituicaoReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if (type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }
    return state;
};

export default triagemAcessoInstituicaoReducer;