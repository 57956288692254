import ConstantesCadastroMateriaisSuporte from '@constantes/cadastro/materiais-suporte';

const { REDUCER_TYPE } = ConstantesCadastroMateriaisSuporte;

const INITIAL_STATE = {
    materiaisAlteracao: null
};

function cadastroMateriaisSuporteReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.SETAR_ID_MATERIAIS_SUPORTE_ALTERACAO) {
        return {
            ...state,
            materiaisAlteracao: payload.materiaisAlteracao
        };
    }

    return state;
}

export default cadastroMateriaisSuporteReducer;