import ConstantesModal from '@constantes/modal';

const { REDUCER_TYPE } = ConstantesModal;

const PARAMETROS_INICIAL_BUSCA_INSTITUICAO = {
    nome: '',
    cnes: '',
    estado: '',
    municipio: ''
};

const INITIAL_STATE = {
    buscaInstituicao: {
        parametrosBusca: { ...PARAMETROS_INICIAL_BUSCA_INSTITUICAO },
        executarBusca: false,
        instituicaoSelecionada: null
    },
    buscaEquipe: {
        executarBusca: false,
        equipeSelecionada: null
    },
    buscarSetor: {
        executarBusca: false,
        setorSelecionado: null
    },
    participacao: {
        participacaoSelecionada: null,
        participacaoEdicao: null,
    }
};

function modalReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type } = action;

    //===================BUSCA INSTITUIÇÃO===================
    if (type === REDUCER_TYPE.ALTERAR_PARAMETROS_PESQUISA_INSTITUICAO) {
        const { key, value } = action;
        state.buscaInstituicao.parametrosBusca[key] = value;
        state.buscaInstituicao.parametrosBusca = Object.assign({}, state.buscaInstituicao.parametrosBusca);
        return {
            ...state
        };
    }
    if (type === REDUCER_TYPE.LIMPAR_FILTROS_PESQUISA_INSTITUICAO) {
        state.buscaInstituicao.parametrosBusca = { ...PARAMETROS_INICIAL_BUSCA_INSTITUICAO };
        const buscaInstituicao = Object.assign({}, state.buscaInstituicao);
        return {
            ...state,
            buscaInstituicao
        };
    }
    if (type === REDUCER_TYPE.PESQUISAR_INSTITUICAO) {
        state.buscaInstituicao.executarBusca = true;
        const buscaInstituicao = Object.assign({}, state.buscaInstituicao);
        return {
            ...state,
            buscaInstituicao
        };
    }
    if (type === REDUCER_TYPE.BUSCA_INSTITUICAO_FINALIZADA) {
        state.buscaInstituicao.executarBusca = false;
        const buscaInstituicao = Object.assign({}, state.buscaInstituicao);
        return {
            ...state,
            buscaInstituicao
        };
    }
    if (type === REDUCER_TYPE.INSTITUICAO_SELECIONADA) {
        const { instituicaoSelecionada } = action;
        state.buscaInstituicao.instituicaoSelecionada = instituicaoSelecionada;
        state.buscaInstituicao.parametrosBusca = { ...PARAMETROS_INICIAL_BUSCA_INSTITUICAO };
        const buscaInstituicao = Object.assign({}, state.buscaInstituicao);
        const buscaEquipe = Object.assign({}, state.buscaEquipe);
        return {
            ...state,
            buscaEquipe,
            buscaInstituicao
        };
    }
    //===================BUSCA INSTITUIÇÃO===================

    //===================PARTICIPAÇÃO PROVAB/MAIS MEDICOS/RESIDÊNCIA===================
    if (type === REDUCER_TYPE.PARTICIPACAO_SELECIONADA) {
        const { participacaoSelecionada } = action;
        state.participacao.participacaoSelecionada = participacaoSelecionada ? Object.assign({}, participacaoSelecionada) : null;
        state.participacao.participacaoEdicao = null;
        const participacao = Object.assign({}, state.participacao);
        return {
            ...state,
            participacao
        };
    }
    //===================PARTICIPAÇÃO PROVAB/MAIS MEDICOS/RESIDÊNCIA===================

    //===================BUSCA EQUIPE DE SAÚDE===================
    if (type === REDUCER_TYPE.EQUIPE_SELECIONADA) {
        const { equipeSelecionada } = action;
        state.buscaEquipe.equipeSelecionada = equipeSelecionada;
        const buscaEquipe = Object.assign({}, state.buscaEquipe);
        return {
            ...state,
            buscaEquipe
        };
    }
    //===================BUSCA EQUIPE DE SAÚDE===================

    return state;
}

export default modalReducer;