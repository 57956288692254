/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup'

import dadosGeraisSchema from '@componentes/interno/cadastro/materiais-suporte/dadosGerais/form/validationSchema';

export default (strings) => {
    const schema = yup.object().shape({});
    const dadosGerais = dadosGeraisSchema(strings);

    return schema
        .concat(dadosGerais);
}