import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import validarCpf from 'validar-cpf';
import {
    SttFormGroup,
    SttButton,
    SttMaskedInput,
    SttFormHelperText,
    SttLoading,
    SttAlerta
} from '@stt-componentes/core';
import { KEY_RETURN } from 'keycode-js';
import translate from "@componentes/translate";
import axios from 'axios';
import { setUsuarioPaciente as setUsuarioPacienteAction } from '../../../../redux/actions/paciente';
import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';

const { REDUCER_TYPE, SITUACAO } = ConstantesSolicitacaoCadastro;

const INITIAL_STATE_ALERTA = {
    titulo: '',
    mensagem: '',
    tipo: 'alert',
    visivel: false,
    botoes: [],
    onClose: () => null
};

const BuscaCpf = ({ classes, strings, setSituacaoContraSenha, setPaciente, setIrFormularioSolicitacao, cpfPacientePadrao }) => {
    const [cpf, setCpf] = useState('');
    const [carregando, setCarregando] = useState(false);
    const [alertaState, setAlertaState] = useState(INITIAL_STATE_ALERTA);

    const handleChangeCpf = event => {
        setCpf(event.target.value);
    };

    useEffect(() => {
        if (cpfPacientePadrao) {
            setCpf(cpfPacientePadrao);
            clickPesquisar();
        }
    }, []);

    useEffect(() => {
        if (carregando) {
            const cpfSemMascara = cpf.replace(/\D+/g, '');
            setCarregando(true);
            axios.post(`${global.gConfig.url_base_administrativo}/usuario-paciente`, { cpf: cpfSemMascara })
                .then(({ data }) => {
                    setCarregando(false);
                    if (data.data.semPaciente) {
                        setAlertaState({
                            ...alertaState,
                            visivel: true,
                            mensagem: strings.mensagemNaoEncontrouPaciente,
                            tipo: 'alert',
                            titulo: strings.atencao,
                            onClose: () => {
                                setIrFormularioSolicitacao(cpfSemMascara);
                            },
                            botoes: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setIrFormularioSolicitacao(cpfSemMascara);
                                    }
                                }
                            ]
                        });
                        return;
                    }
                    setPaciente(data.data);
                    setSituacaoContraSenha();
                })
                .catch((error) => {
                    setCarregando(false);
                    const { response } = error;
                    console.log(error)
                });
        }
    }, [carregando]);

    const clickPesquisar = () => {
        if (carregando) {
            return;
        }
        setCarregando(true);
    };

    const handleChangeKeyUp = (event) => {
        if (cpf.length < 14 || verificarCpf()) {
            return;
        }
        if (event.keyCode === KEY_RETURN) {
            clickPesquisar();
        }
    }

    const verificarCpf = () => {
        const cpfTeste = cpf.replace(/\D+/g, '');
        if (cpfTeste.length < 11)
            return false;

        return !validarCpf(cpfTeste);
    };

    return (
        <>
            <SttFormGroup row className={classes.form}>
                <SttMaskedInput label={strings.cpf}
                    name="input-cpf"
                    mask="cpf"
                    required
                    value={cpf}
                    onKeyUp={handleChangeKeyUp}
                    error={verificarCpf()}
                    onChange={handleChangeCpf}
                />
                {
                    verificarCpf() ? (
                        <SttFormHelperText error={true}
                            name="helper-cpf-invalido"
                            className={classes.errorText}>
                            {strings.cpfInvalido}
                        </SttFormHelperText>
                    ) : null
                }
                <SttButton variant="contained"
                    name="btn-confirmar-cpf"
                    color="primary"
                    nomarginleft="true"
                    disabled={cpf.length < 14 || verificarCpf()}
                    className={classes.buttons}
                    onClick={clickPesquisar}>
                    {strings.pesquisar}
                </SttButton>
            </SttFormGroup>
            <SttLoading
                open={carregando}
                text={strings.buscandoDadosCpf}
            />
            <SttAlerta open={alertaState.visivel}
                title={alertaState.titulo}
                message={alertaState.mensagem}
                type={alertaState.tipo}
                options={alertaState.botoes}
                onClose={alertaState.onClose}
            />
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPaciente: (paciente) => dispatch(setUsuarioPacienteAction(paciente)),
        setIrFormularioSolicitacao: (cpf) => dispatch({
            type: REDUCER_TYPE.ABRIR_SOLICITACAO_CADASTRO,
            situacaoSolicitacao: SITUACAO.SOLICITACAO_CADASTRO,
            cpf: cpf,
            dadosSolicitacao: {},
            dadosCadsusCnes: {}
        })
    };
};

export default connect(null, mapDispatchToProps)(translate('SolicitacaoBuscaCpf')(BuscaCpf));