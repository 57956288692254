/* eslint-disable no-unused-expressions */
import React, { useRef, useState, useEffect } from "react";
import {
    SttGrid,
    SttModal,
    SttButton,
    SttInput,
    SttNotification
} from '@stt-componentes/core';
import { Field, Formik } from 'formik';
import translate from '@componentes/translate';
import {
    NOVA_SENHA,
    CONFIRMACAO_SENHA,
} from './fieldNames';
import validationSchema from './validationSchema';
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    notification: {
        display: 'flex',
        flexDirection: 'column'
    },
    tituloCheck: {
        display: 'flex',
        gap: theme.spacing(1)
    },
    check: {
        color: 'green',
        fontSize: '1.5em'
    },
    senha: {
        backgroundColor: '#FBFBFB',
        margin: '1px solid #D9D9D9'
    }
}));

const styles = (theme) => {
    return {
        input: {
            lineHeight: 1,
            backgroundColor: '#ffffff',
            color: theme.palette.text.secondary,
            '& .MuiInputBase-root': {
                lineHeight: 1
            },
            '&$disabled $notchedOutline': {
                borderWidth: '2px',
                color: '#c2c2c2',
                borderColor: '#c2c2c2',
            },
            '&$cssfocused $notchedOutline': {
                borderWidth: '1px',
                borderColor: `${theme.palette.text.secondary}`,
            },
            '&$error $notchedOutline': {
                borderWidth: '2px',
                borderColor: `${theme.palette.error.main}`,
            },
            '&protocol': {
                fontFamily: '"Noto Sans"'
            }
        },
        cssfocused: {
            color: `${theme.palette.text.secondary}`
        },
        notchedOutline: {
            borderWidth: '1px',
            borderColor: `${theme.palette.text.secondary}`,
        },
        disabled: {
            backgroundColor: '#f2f2f2'
        },
        error: {}
    }
};

const useStylesInputSenha = makeStyles(theme => styles(theme));

const ModalEscolhaUsuarioSenha = ({ strings, mostrarModalEscolhaUsuarioSenha, setMostrarModalEscolhaUsuarioSenha, callbackEscolhaUsuarioSenha }) => {
    const schema = validationSchema(strings);
    const ref = useRef();
    const classes = useStyles();
    const classesInputSenha = useStylesInputSenha();

    const [mostrarPassword, setMostrarPassword] = useState(false);

    const handleMostrarPassword = () => {
        setMostrarPassword(!mostrarPassword);
    }

    const [regrasSenha, setRegrasSenha] = useState(
        {
            oitoDigitos: false,
            letraMaiuscula: false,
            numero: false,
            caracterEspecial: false,
            senhaConfirmacaoIguais: false
        }
    )
    const verificarSenha = (senha, confirmacaoSenha, setFieldValue) => {
        const novaRegra = {
            oitoDigitos: true,
            letraMaiuscula: true,
            numero: true,
            caracterEspecial: true,
            senhaConfirmacaoIguais: true
        };
        if (senha.length < 8) {
            novaRegra.oitoDigitos = false;
        }
        if (!/[A-Z]/.test(senha)) {
            novaRegra.letraMaiuscula = false;
        }
        if (!/\d/.test(senha)) {
            novaRegra.numero = false;
        }
        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(senha)) {
            novaRegra.caracterEspecial = false;
        }
        setFieldValue(NOVA_SENHA, senha);
        setFieldValue(CONFIRMACAO_SENHA, confirmacaoSenha);
        setRegrasSenha(novaRegra);
    }

    const resetarRegrasSenha = () => {
        setRegrasSenha({
            oitoDigitos: false,
            letraMaiuscula: false,
            numero: false,
            caracterEspecial: false
        });
    }

    const inputPropsSenha = {
        maxLength: 20,
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    tabIndex={-1}
                    onClick={handleMostrarPassword}
                >
                    {mostrarPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
            </InputAdornment>
        ),
        classes: {
            root: classesInputSenha.input,
            focused: classesInputSenha.cssfocused,
            notchedOutline: classesInputSenha.notchedOutline,
            disabled: classesInputSenha.disabled,
            error: classesInputSenha.error
        }
    };

    useEffect(() => {
        resetarRegrasSenha();
    }, [mostrarModalEscolhaUsuarioSenha]);

    return (
        <SttModal
            title={strings.escolhaSenhaConfirmacaoSenha}
            open={mostrarModalEscolhaUsuarioSenha}
            maxWidth="sm"
            outModalClose={() => {
                setMostrarModalEscolhaUsuarioSenha(false);
                resetarRegrasSenha();
            }}
            iconClose={() => {
                setMostrarModalEscolhaUsuarioSenha(false);
                resetarRegrasSenha();
            }}
            fullWidth={true}
            children={
                <Formik
                    innerRef={ref}
                    enableReinitialize
                    initialValues={
                        {
                            [NOVA_SENHA]: '',
                            [CONFIRMACAO_SENHA]: ''
                        }
                    }
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        callbackEscolhaUsuarioSenha.callback(
                            dados.novaSenha,
                            dados.confirmacaoSenha
                        );
                    }}
                >
                    {
                        ({
                            handleSubmit,
                            setFieldValue,
                            values
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttNotification severity="info" className={classes.alert}>
                                        <div className={classes.notification}>
                                            <span>{strings.instrucaoSenhaTitulo}</span>
                                            <div className={classes.tituloCheck}>
                                                <span>{strings.instrucaoSenhaMinimo8Digitos}</span>
                                                {
                                                    regrasSenha.oitoDigitos &&
                                                    <CheckCircleOutlineIcon className={classes.check} />
                                                }
                                            </div>
                                            <div className={classes.tituloCheck}>
                                                <span>{strings.instrucaoSenhaLetraMaiuscula}</span>
                                                {
                                                    regrasSenha.letraMaiuscula &&
                                                    <CheckCircleOutlineIcon className={classes.check} />
                                                }
                                            </div>
                                            <div className={classes.tituloCheck}>
                                                <span>{strings.instrucaoSenhaNumero}</span>
                                                {
                                                    regrasSenha.numero &&
                                                    <CheckCircleOutlineIcon className={classes.check} />
                                                }
                                            </div>
                                            <div className={classes.tituloCheck}>
                                                <span>{strings.instrucaoSenhaCaracterEspecial}</span>
                                                {
                                                    regrasSenha.caracterEspecial &&
                                                    <CheckCircleOutlineIcon className={classes.check} />
                                                }
                                            </div>
                                        </div>
                                    </SttNotification>
                                    <SttGrid container spacing={1}>
                                        <SttGrid item xs={12}>
                                            <Field name={NOVA_SENHA}>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttInput
                                                        InputProps={{
                                                            ...inputPropsSenha
                                                        }}
                                                        {...field}
                                                        type={mostrarPassword ? 'text' : 'password'}
                                                        autoComplete="new-password"
                                                        required={true}
                                                        onInput={e => e.target.value = ('' + e.target.value).replace(' ', '')}
                                                        label={strings.novaSenha}
                                                        onChange={(e) => verificarSenha(e.target.value, values.confirmacaoSenha, setFieldValue)}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>

                                        <SttGrid item xs={12}>
                                            <Field name={CONFIRMACAO_SENHA}>
                                                {({
                                                    field,
                                                    meta
                                                }) => (
                                                    <SttInput
                                                        InputProps={{
                                                            ...inputPropsSenha
                                                        }}
                                                        {...field}
                                                        type={mostrarPassword ? 'text' : 'password'}
                                                        autoComplete="new-password"
                                                        required={true}
                                                        onInput={e => e.target.value = ('' + e.target.value).replace(' ', '')}
                                                        label={strings.confirmacaoSenha}
                                                        onChange={(e) => verificarSenha(values.novaSenha, e.target.value, setFieldValue)}
                                                        error={meta.touched && meta.error ? true : false}
                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            )
                        }
                    }
                </Formik>
            }
            footer={
                <div>
                    <SttButton variant="contained"
                        color="primary"
                        onClick={() => {
                            if (ref.current) {
                                ref.current.handleSubmit();
                            }
                        }}>
                        {strings.confirmar}
                    </SttButton>
                    <SttButton variant="contained"
                        color="secondary"
                        onClick={() => {
                            setMostrarModalEscolhaUsuarioSenha(false);
                            resetarRegrasSenha();
                        }}>
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
}

export default translate('SolicitacaoIndex')(ModalEscolhaUsuarioSenha);