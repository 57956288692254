import React, { useState, useEffect, Suspense } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttHeading,
    SttTable,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttTableCell,
    SttTableSortLabel,
    SttTableBody,
    SttCircularProgress,
    SttText,
    SttSeeButton,
    SttTablePagination
} from '@stt-componentes/core';
import Paper from '@material-ui/core/Paper';
import FormPesquisa from './form';
import translate from '@componentes/translate';
import { useLocation } from "react-router-dom";
import { Chip } from '@material-ui/core';
import ModalTriagemAcessoInstituicao from './moda-instituicao-acesso';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        color: 'black',
        fontWeight: 'bold',
        height: 'auto'
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        FUNCIONARIO_SOLICITANTE: 'funcionario_solicitante',
        INSTITUICAO_SOLICITADA: 'instituicao_solicitada',
        DATA_HORA_SOLICITACAO: 'data_hora_solicitacao',
        SITUACAO: 'avaliacao'
    }
};

const TriagemAcessoInstituicao = (props) => {
    const { strings, filtros } = props;
    const classes = useStyles();
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [count, setCount] = useState(filtros.limit || global.gConfig.pagination.count);
    const [page, setPage] = useState(filtros.page || global.gConfig.pagination.start);
    const [order, setOrder] = useState(filtros.direction || ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(filtros.sort || null);
    const [buscar, setBuscar] = useState(false);
    const [resetGrid, setResetGrid] = useState(false);
    const [acessoInstituicao, setAcessoInstituicao] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [visualizarAcesso, setVisualizarAcesso] = useState(null);
    const location = useLocation();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    };

    const resetPageOrder = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setAcessoInstituicao(dados.itens);
        setBuscar(false);
    };

    const callbackLimpar = () => {
        setResetGrid(true);
    };

    useEffect(() => {
        if (resetGrid) {
            setAcessoInstituicao([]);
            setTotalRegistros(0);
            setResetGrid(false);
        }
    }, [resetGrid]);

    useEffect(() => {
        if (location.state && location.state.pesquisar) {
            setBuscar(true);
        }
    }, []);

    return (
        <>
            <SttHeading variant="h1" color="primary" className={classes.heading}>
                {strings.tituloPagina}
            </SttHeading>

            <FormPesquisa
                callbackAndamento={setBuscaEmAndamento}
                callbackBusca={callbackBusca}
                callbackLimpar={callbackLimpar}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                buscar={buscar}
                resetPageOrder={resetPageOrder}
                resetGrid={resetGrid}
            />

            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="30%" sortDirection={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_SOLICITANTE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_SOLICITANTE}
                                        direction={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_SOLICITANTE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.FUNCIONARIO_SOLICITANTE)}
                                    >
                                        {strings.nomeSolicitante}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="30%" sortDirection={orderBy === ORDENACAO.CAMPOS.INSTITUICAO_SOLICITADA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.INSTITUICAO_SOLICITADA}
                                        direction={orderBy === ORDENACAO.CAMPOS.INSTITUICAO_SOLICITADA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.INSTITUICAO_SOLICITADA)}
                                    >
                                        {strings.instituicaoSolicitada}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="15%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_HORA_SOLICITACAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA_HORA_SOLICITACAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA_HORA_SOLICITACAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_HORA_SOLICITACAO)}
                                    >
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}
                                    >
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Triagem de acesso a instituições</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={4}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    acessoInstituicao.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={13}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        acessoInstituicao.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden smDown>
                                                    <SttTableCell>{row.funcionario_solicitante ? row.funcionario_solicitante : ''}</SttTableCell>
                                                    <SttTableCell>{row.instituicao ? row.instituicao : ''}</SttTableCell>
                                                    <SttTableCell>{row.data_hora_solicitacao_formatado ? row.data_hora_solicitacao_formatado : ''}</SttTableCell>
                                                    <SttTableCell>
                                                        <Chip
                                                            className={classes.chip}
                                                            style={{ backgroundColor: row.avaliacao == 'P' ? '#EAF0FF' : row.avaliacao == 'A' ? '#8AC58B' : '#D88080' }}
                                                            label={row.avaliacao === 'P' ? strings.pendente : row.avaliacao === 'A' ? strings.aprovado : strings.rejeitado}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.nomeSolicitante}:</b> {row.funcionario_solicitante ? row.funcionario_solicitante : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.instituicaoSolicitada}:</b> {row.instituicao ? row.instituicao : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.dataSolicitacao}:</b> {row.data_hora_solicitacao_formatado ? row.data_hora_solicitacao_formatado : ''}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.solicitacao}:</b> {row.avaliacao === 'P' ? strings.pendente : row.avaliacao === 'A' ? strings.aprovado : strings.rejeitado}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            setVisualizarAcesso(row);
                                                            setModalOpen(true);
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                acessoInstituicao.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={count}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }
            {
                visualizarAcesso ?
                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                        <ModalTriagemAcessoInstituicao
                            open={modalOpen}
                            setModalOpen={setModalOpen}
                            visualizarAcesso={visualizarAcesso}
                            callbackFechar={() => {
                                setModalOpen(false);
                                setVisualizarAcesso(null);
                            }}
                            callback={() => {
                                setVisualizarAcesso(null);
                                setModalOpen(false);
                                resetPageOrder();
                                setBuscar(true);
                            }}
                        />
                    </Suspense> :
                    null
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.triagemAcessoInstituicaoReducer.parametrosPesquisa
    };
};

export default connect(mapStateToProps)(translate('TriagemAcessoInstituicao')(TriagemAcessoInstituicao));

