/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DESCRICAO
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [DESCRICAO]: yup.string()
            .nullable()
            .min(1)
            .max(100)
            .required(strings.campoObrigatorio)
    });
}
