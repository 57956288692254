/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    UF,
    MUNICIPIO,
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [UF]: yup.object().shape({
            id: yup.number(),
            nome: yup.string(),
            sigla: yup.string(),
            id_pais: yup.number(),
            codigo_ibge: yup.string()
        }).nullable().required(strings.campoObrigatorio),
        [MUNICIPIO]: yup.object().shape({
            id: yup.number(),
            nome: yup.string(),
            id_estado: yup.number(),
            codigo_ibge: yup.string(),
            nome_estado: yup.string(),
            latitude: yup.number(),
            longitude: yup.number()
        }).nullable().required(strings.campoObrigatorio)
    });
}
