/* eslint-disable import/no-anonymous-default-export */
import * as yup from 'yup';
import {
    DESCRICAO
} from './fieldNames';

export default () => {
    return yup.object().shape({
        [DESCRICAO]: yup
            .string()
            .nullable()
            .max(100)
    });
}
