import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ComponentePesquisa from '@componentes/interno/pesquisa/instituicao';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    }
}));

const PesquisaInstituicao = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <ComponentePesquisa />
        </div>
    );
};
export default PesquisaInstituicao;