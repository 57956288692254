import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '@src/request';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import translate from '@componentes/translate';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '@redux/actions/pesquisa/perfil';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttAutocomplete
} from '@stt-componentes/core';

import {
    DESCRICAO,
    VINCULO
} from './fieldNames';

import CONSTANTES_PERFIS from '@constantes/pesquisa/perfil';

import validationSchema from './validationSchema';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const FormPesquisa = (props) => {
    const {
        setParametrosPesquisa,
        limparParametrosPesquisa,
        setParametrosAlerta,
        setOpen,
        callbackAndamento,
        callbackLimpar,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
        strings
    } = props;

    const classes = useStyles();
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();
    const schema = validationSchema(strings);
    const navigate = useNavigate();

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;


        if (dados.descricao) {
            filtros.descricao = dados.descricao.trim();
        }

        if (dados.vinculo) {
            filtros.vinculo = dados.vinculo.identificador;
        }

        setParametrosPesquisa(dados);
        return filtros;
    }

    const tratarPerfis = (perfis) => {
        for (const perfil of perfis) {
            perfil.vinculo = CONSTANTES_PERFIS.VINCULOS.find(element => element.identificador === perfil.vinculo);
        }

        return perfis
    };

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={estadoInicial}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${ADM_API_BASE_URL}/perfil`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                dados.itens = tratarPerfis(dados.itens);
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                callbackBusca({
                                    totalRegistros: 0,
                                    itens: []
                                });

                                //TODO: PALEATIVO, DEVE-SE MUDAR AQUI O TRATAMENTO DA REQUISIÇÃO PARA NÃO VIR MAIS 404 QUANDO NÃO ACHAR NENHUM FUNCIONÁRIO.
                                if (!params.outrosFiltros && params.cpf && params.cpf !== '') {
                                    const parametrosAlerta = {
                                        titulo: strings.atencao,
                                        mensagem: strings.pesquisaFuncionarioNovoCadastro,
                                        tipo: 'alert',
                                        open: true,
                                        opcoes: [
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    navigate("/cadastro/funcionario", { state: { cpf: params.cpf } });
                                                    setOpen(false);
                                                }
                                            },
                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    setOpen(false);
                                                }
                                            }
                                        ]
                                    };

                                    setParametrosAlerta(parametrosAlerta);
                                }
                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}>
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <FastField name={DESCRICAO}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    label={strings.descricao}
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    value={field.value ? field.value : ''}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={2}>
                                                        <Field name={VINCULO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.vinculo
                                                                    }}
                                                                    getOptionLabel={option => option && option.descricao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.identificador === valueOptionSelected.identificador}
                                                                    options={CONSTANTES_PERFIS.VINCULOS}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(VINCULO, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        callbackLimpar();
                                                    }}
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        limparParametrosPesquisa();
                                                        callbackLimpar();
                                                        resetForm({
                                                            values: estadoInicial
                                                        });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaPerfilReducer.parametrosPesquisa,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('FormPesquisaPerfis')(FormPesquisa));