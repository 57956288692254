import {
    DADOS_GERAIS,
    CPF,
    NOME,
    DATA_NASCIMENTO,
    NOME_MAE,
    GENERO,
    UF,
    MUNICIPIO,
    EMAIL, 
    ID,
    SITUACAO,
    MOTIVO_RECUSA,
    LOGIN
} from '@componentes/interno/pesquisa/triagem/solicitacao/dadosGerais/form/fieldNames';

import {
    DADOS_CONTATO,
    TELEFONE_CELULAR,
    TELEFONE_COMERCIAL
} from '@componentes/interno/pesquisa/triagem/solicitacao/dadosContato/form/fieldNames';

import { pegarInstituicoesVinculoPerfio, temPerfil } from '@src/security/acl';
import { CONSTANTES_PERFIS } from '@src/common/Constants';
import {
    CBO_FAMILIA,
    CBO_OCUPACAO,
    DADOS_PROFISSIONAIS,
    TIPO_OCUPACAO,
    OCUPACAO_NAO_CLASSIFICADA,
    POSSUI_CONSELHO_TRABALHO,
    NUMERO_CONSELHO_TRABALHO,
    UF_CONSELHO_TRABALHO,
    TIPO_CONSELHO_TRABALHO,
    PROFISSAO_INFORMADA
} from '@componentes/interno/pesquisa/triagem/solicitacao/dadosProfissionais/form/fieldNames';

import {
    PARTICIPACOES,
} from '@componentes/interno/pesquisa/triagem/solicitacao/dadosParticipacao/form/fieldNames';

import {
    VINCULO,
    INSTITUICOES,
    EQUIPES,
} from '@componentes/interno/pesquisa/triagem/solicitacao/dadosVinculo/fieldNames';

import {
    CONSTANTES_TIPO_OCUPACAO,
    CONTANTES_POSSUI_CONSELHO_TRABALHO
} from '@common/Constants';

import moment from 'moment';
import TIPOS_PARTICIPACOES from '@src/constantes/participacao';

const inicializarValoresFormulario = (dadosTriagem) => {
    let formulario = {};

    if (dadosTriagem.data_nascimento) {
        dadosTriagem.data_nascimento = moment.utc(dadosTriagem.data_nascimento);
        dadosTriagem.data_nascimento = dadosTriagem.data_nascimento.set({ h: 12 }).toISOString();
    }

    formulario[DADOS_GERAIS] = {
        [ID]: dadosTriagem.id || null,
        [CPF]: dadosTriagem.cpf ? ('00000000000' + dadosTriagem.cpf.replace(/[\. ,:-]+/g, "")).slice(-11) : '', // eslint-disable-line
        [NOME]: dadosTriagem.nome || '',
        [DATA_NASCIMENTO]: dadosTriagem.data_nascimento || null,
        [NOME_MAE]: dadosTriagem.nome_mae || '',
        [GENERO]: dadosTriagem.id_sexo || null,
        [UF]: dadosTriagem.id_estado || null,
        [MUNICIPIO]: dadosTriagem.id_cidade || null,
        [EMAIL]: dadosTriagem.email_pessoal || null,
        [SITUACAO]: dadosTriagem.situacao,
        [MOTIVO_RECUSA]: dadosTriagem.motivo_recusa,
        [LOGIN]: null,
        receber_divulgacao_autorizada: dadosTriagem.receber_divulgacao_autorizada,
        receber_divulgacao_email: dadosTriagem.receber_divulgacao_email,
        termo_uso_aceito: dadosTriagem.termo_uso_aceito,   
    };

    formulario[DADOS_CONTATO] = {};
    formulario[DADOS_CONTATO][TELEFONE_CELULAR] = "";
    formulario[DADOS_CONTATO][TELEFONE_COMERCIAL] = "";
    if (dadosTriagem.telefone_comercial) {
        formulario[DADOS_CONTATO][TELEFONE_COMERCIAL] = dadosTriagem.telefone_comercial.replace(/[^a-zA-Z0-9]/g, '').substring(dadosTriagem.telefone_comercial.replace(/[^a-zA-Z0-9]/g, '').length - 10);
    }

    if (dadosTriagem.telefone_celular) {
        formulario[DADOS_CONTATO][TELEFONE_CELULAR] = dadosTriagem.telefone_celular.replace(/[^a-zA-Z0-9]/g, '').substring(dadosTriagem.telefone_celular.replace(/[^a-zA-Z0-9]/g, '').length - 11);
    }

    formulario[DADOS_PROFISSIONAIS] = {};
    formulario[DADOS_PROFISSIONAIS][PROFISSAO_INFORMADA] = dadosTriagem.profissao || '';
    formulario[DADOS_PROFISSIONAIS][TIPO_OCUPACAO] = CONSTANTES_TIPO_OCUPACAO.CLASSIFICADA
    formulario[DADOS_PROFISSIONAIS][CBO_FAMILIA] = null;
    formulario[DADOS_PROFISSIONAIS][CBO_OCUPACAO] = null;
    formulario[DADOS_PROFISSIONAIS][OCUPACAO_NAO_CLASSIFICADA] = null;

    formulario[DADOS_PROFISSIONAIS][POSSUI_CONSELHO_TRABALHO] = CONTANTES_POSSUI_CONSELHO_TRABALHO.NAO;
    formulario[DADOS_PROFISSIONAIS][NUMERO_CONSELHO_TRABALHO] = '';
    formulario[DADOS_PROFISSIONAIS][UF_CONSELHO_TRABALHO] = null;
    formulario[DADOS_PROFISSIONAIS][TIPO_CONSELHO_TRABALHO] = null;


    if (dadosTriagem.numero_conselho && dadosTriagem.id_uf_conselho_trabalho && dadosTriagem.id_conselho_trabalho) {
        formulario[DADOS_PROFISSIONAIS][POSSUI_CONSELHO_TRABALHO] = CONTANTES_POSSUI_CONSELHO_TRABALHO.SIM;
        formulario[DADOS_PROFISSIONAIS][NUMERO_CONSELHO_TRABALHO] = dadosTriagem.numero_conselho;
        formulario[DADOS_PROFISSIONAIS][UF_CONSELHO_TRABALHO] = dadosTriagem.id_uf_conselho_trabalho;
        formulario[DADOS_PROFISSIONAIS][TIPO_CONSELHO_TRABALHO] = dadosTriagem.id_conselho_trabalho;
    }

    formulario[PARTICIPACOES] = [];

    if (dadosTriagem.participacao_mais_medicos) {
        formulario[PARTICIPACOES].push(
            {
                tipoParticipacao: TIPOS_PARTICIPACOES[0],
                dataInicial: dadosTriagem.data_inicio_participacao_mais_medicos,
                dataFinal: null
            }
        );
    }

    if (dadosTriagem.participacao_provab) {
        formulario[PARTICIPACOES].push(
            {
                tipoParticipacao: TIPOS_PARTICIPACOES[1],
                dataInicial: dadosTriagem.data_inicio_participacao_provab,
                dataFinal: null
            }
        );
    }

    if (dadosTriagem.residencia) {
        formulario[PARTICIPACOES].push(
            {
                tipoParticipacao: TIPOS_PARTICIPACOES[2],
                dataInicial: dadosTriagem.data_inicio_residencia,
                dataFinal: null
            }
        );
    }

    formulario[VINCULO] = {};
    formulario[VINCULO][INSTITUICOES] = [];
    formulario[VINCULO][EQUIPES] = [];

    if (dadosTriagem.vinculos) {
        //Adicionar instituições diferentes
        dadosTriagem.vinculos.forEach(vinculo => {
            const instituicaoFormatada = {
                id_instituicao: vinculo.id_instituicao,
                nome: vinculo.nome_instituicao,
                cnes: vinculo.cnes_instituicao,
                municipio: vinculo.municipio,
                estado: vinculo.estado,
                origem_instituicao: vinculo.origem_instituicao,
                ativo: true
            }

            if (formulario[VINCULO][INSTITUICOES].findIndex(instAdicionada => instAdicionada.id_instituicao === instituicaoFormatada.id_instituicao) === -1) {
                //Não foi adicionada esta instituição ainda
                formulario[VINCULO][INSTITUICOES].push(instituicaoFormatada);
            }

            let equipeFormatada = {};
            if (vinculo.nome_equipe_saude_familia) {
                equipeFormatada.nome = vinculo.nome_equipe_saude_familia;
                equipeFormatada.id_equipe_saude_familia = vinculo.id_equipe_saude_familia;
                equipeFormatada.id_instituicao = vinculo.id_instituicao;
                equipeFormatada.nome_instituicao = vinculo.nome_instituicao;
                equipeFormatada.municipio = vinculo.municipio;
                equipeFormatada.estado = vinculo.estado;
                equipeFormatada.ativo = true;
                equipeFormatada.codigo_ine = vinculo.codigo_ine_equipe_saude_familia;

                formulario[VINCULO][EQUIPES].push(equipeFormatada);
            }
        });
    }

    return formulario;
}

const formatarSolicitacao = (dados) => {
    const formData = new FormData();

    const { dadosGerais, dadosProfissionais, dadosContato, participacoes, vinculo } = dados;

    formData.append("idSolicitacaoCadastro", dadosGerais.id);

    formData.append('receber_divulgacao_autorizada', dadosGerais.receber_divulgacao_autorizada);
    formData.append('receber_divulgacao_email', dadosGerais.receber_divulgacao_email);
    formData.append('termo_uso_aceito', dadosGerais.termo_uso_aceito);
    
    if(dadosGerais.nome && dadosGerais.nome !== '') {
        formData.append('nome', dadosGerais.nome.trim().toUpperCase());
    }

    if(dadosGerais.login && dadosGerais.login !== '') {
        formData.append('login', dadosGerais.login.trim().toLowerCase());
    }

    if(dadosGerais.email && dadosGerais.email !== '') {
        formData.append('email', dadosGerais.email.trim().toLowerCase());
    }
    
    formData.append('possui_conselho_trabalho', dadosProfissionais.possuiConselhoTrabalho);

    if (dadosGerais.cpf) {
        const cpfFormatado = dadosGerais.cpf.replace(/\D+/g, '');
        formData.append('cpf', cpfFormatado);
    }
 
    if (dadosProfissionais?.tipoConselhoTrabalho) {
        formData.append('id_conselho_trabalho', dadosProfissionais.tipoConselhoTrabalho.id);
    }

    if (dadosProfissionais?.ufConselhoTrabalho) {
        formData.append('id_uf_conselho_trabalho', dadosProfissionais.ufConselhoTrabalho.id);
    }

    if (dadosProfissionais?.numeroConselhoTrabalho) {
        formData.append('numero_conselho', dadosProfissionais.numeroConselhoTrabalho.trim().toUpperCase());
    }

    if (dadosGerais.genero) {
        formData.append('id_sexo', dadosGerais.genero.id);
    }

    if (dadosGerais.uf) {
        formData.append('id_estado', dadosGerais.uf.id);
    }

    if (dadosGerais.municipio) {
        formData.append('id_cidade', dadosGerais.municipio.id);
    }

    formData.append('tipo_ocupacao', dadosProfissionais.tipoOcupacao);

    if (dadosProfissionais?.tipoOcupacao === CONSTANTES_TIPO_OCUPACAO.CLASSIFICADA) {
        formData.append('id_cbo_familia', dadosProfissionais.cboFamilia.id);
        formData.append('id_cbo_ocupacao', dadosProfissionais.cboOcupacao.id);
    }

    if (dadosProfissionais?.tipoOcupacao === CONSTANTES_TIPO_OCUPACAO.NAO_CLASSIFICADA) {
        formData.append('id_ocupacao_nao_classificada', dadosProfissionais.ocupacaoNaoClassificada.id);
    }

    if (dadosGerais.dataNascimento) {
        let dataMoment = moment(dadosGerais.dataNascimento);
        if (dataMoment.isValid()) {
            formData.append('data_nascimento', dataMoment.format('YYYY-MM-DD'));
        }
    }

    if(dadosGerais?.nomeMae !== '') {
        formData.append('nome_mae', dadosGerais.nomeMae.trim().toUpperCase());
    }

    //Dados de contato
    _formatarDadosContato(dadosContato, formData);

    //Dados de participação
    _formatarDadosParticipacao(participacoes, formData);

    //Dados de vínculo
    _formatarDadosVinculo(vinculo, formData);

    // Dados da instância
    formData.append('instancia', global.gConfig.basename);

    return formData;
};

const _formatarDadosVinculo = (dadosVinculo, formData) => {
    let instituicoes = [];
    let equipes = [];

    if (dadosVinculo.instituicoes) {
        dadosVinculo.instituicoes.forEach(instituicao => {
            instituicoes.push(
                {
                    id_instituicao: instituicao.id_instituicao,
                    ativo: instituicao.ativo,
                    origem: instituicao.origem_instituicao
                }
            );
        });
    }

    if (dadosVinculo.equipes) {
        dadosVinculo.equipes.forEach(equipe => {
            equipes.push(
                {
                    id_equipe: equipe.id_equipe_saude_familia,
                    id_instituicao: equipe.id_instituicao,
                    ativo: equipe.ativo,
                    nome: equipe.nome.trim(),
                    codigo_ine: equipe.codigo_ine ? equipe.codigo_ine.trim() : null
                }
            );
        });
    }

    let vinculoFormatado = {
        instituicoes,
        equipes,
    };
    formData.append('vinculo', JSON.stringify(vinculoFormatado));
};

const _formatarDadosParticipacao = (dadosParticipacoes, formData) => {
    if (dadosParticipacoes?.length) {
        let participacoes = [];
        dadosParticipacoes.forEach(participacao => {
            let umaParticipacao = {};

            umaParticipacao.tipo = participacao.tipoParticipacao.acronimo;
            if (participacao.dataInicial) {
                let dataInicialMoment = moment(participacao.dataInicial);
                if (dataInicialMoment.isValid()) {
                    umaParticipacao.data_inicial = dataInicialMoment.format('YYYY-MM-DD');
                }
            }

            participacoes.push(umaParticipacao);
        });

        //Adiciona as participações somente se alguma participação for adicionada
        if (participacoes.length) {
            formData.append('participacoes', JSON.stringify(participacoes));
        }
    }
};

const _formatarDadosContato = (dadosContato, formData) => {
    let contatosFormatados = [];

    if (dadosContato.telefoneCelular && dadosContato.telefoneCelular !== '') {
        contatosFormatados.push(
            {
                id_tipo_contato: 2,
                contato: dadosContato.telefoneCelular.replace(/\D/g, '')
            }
        );
    }

    if (dadosContato.telefoneComercial && dadosContato.telefoneComercial !== '') {
        contatosFormatados.push(
            {
                id_tipo_contato: 3,
                contato: dadosContato.telefoneComercial.replace(/\D/g, '')
            }
        );
    }

    //Adiciona os contatos somente se algum contato for adicionado
    if (contatosFormatados.length) {
        formData.append('contatos', JSON.stringify(contatosFormatados));
    }
};

const verificarErrosOrdemPrecedencia = (erros, secao) => {
    const ordemPrecedencia = [
        DADOS_GERAIS,
        DADOS_PROFISSIONAIS
    ];
    for (const ordem of ordemPrecedencia) {
        if (secao === ordem) {
            return false;
        }

        if (erros[ordem]) {
            return true;
        }
    }
}

const verificarPodeAprovarTriagem =  (usuario, vinculosSolicitacao) => {
    if(
        temPerfil(usuario, CONSTANTES_PERFIS.ADMINISTRADOR) ||
        temPerfil(usuario, CONSTANTES_PERFIS.ADMINISTRADOR_TELEMEDICINA) ||
        temPerfil(usuario, CONSTANTES_PERFIS.ADMINISTRADOR_TELESSAUDE) ||
        temPerfil(usuario, CONSTANTES_PERFIS.ADMINISTRADOR_ESTADUAL) ||
        temPerfil(usuario, CONSTANTES_PERFIS.SUPORTE_USUARIO)
    ) {
        return true;
    }

    if(
        temPerfil(usuario, CONSTANTES_PERFIS.ADMINISTRADOR_LOCAL)
    ) {
        const instituicoesAdminLocal = pegarInstituicoesVinculoPerfio(usuario);
        if(
            !vinculosSolicitacao.every(vinculoSolicitacao => instituicoesAdminLocal.includes(vinculoSolicitacao.id_instituicao))
        ) {
            return false;
        }

        return true;
    }

    return false;
}

export default { inicializarValoresFormulario, verificarErrosOrdemPrecedencia, formatarSolicitacao, verificarPodeAprovarTriagem };