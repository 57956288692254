export const estadoInicial = {
    cnes: '',
    instituicao: '',
    uf: null,
    cidade: null,
    setor: '',
    nome: '',
    tipo: null,
    situacao: null,
    'data-de': null,
    'data-ate': null
};