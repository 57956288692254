import React, { useEffect, useState, useRef, Suspense, lazy, memo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translate from '@src/componentes/translate';
import axios from 'axios';
import {
    SttButton,
    SttExpansionPanel,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttDivider,
    SttLoading,
} from '@stt-componentes/core';
import { getHeaders } from '@src/request';
import { Formik } from 'formik';
import validationSchema from './validationSchemaTriagem';
import HttpStatus from 'http-status-codes';

import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';

import Functions from '@util/triagem';
import { DADOS_GERAIS, ID } from './dadosGerais/form/fieldNames';
import UtilTriagem from '@util/triagem';
import ModalRejeitarSolicitacao from './rejeitar';

const DadosGerais = lazy(() => import('@componentes/interno/pesquisa/triagem/solicitacao/dadosGerais'));
const DadosContato = lazy(() => import('@componentes/interno/pesquisa/triagem/solicitacao/dadosContato'));
const DadosProfissionais = lazy(() => import('@componentes/interno/pesquisa/triagem/solicitacao/dadosProfissionais'));
const DadosParticipacao = lazy(() => import('@componentes/interno/pesquisa/triagem/solicitacao/dadosParticipacao'));
const DadosVinculo = lazy(() => import('@componentes/interno/pesquisa/triagem/solicitacao/dadosVinculo'));

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    textoQuebraLinha: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-all'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    divWrapperMovimentacao: {
        marginBottom: theme.spacing(1)
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    semResultado: {
        fontWeight: 'bold'
    },
    textItem: {
        textTransform: 'uppercase'
    },
    circulo: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        display: 'inline-block',
        border: '1px solid black'
    },
    label: {
        alignSelf: 'center',
        marginLeft: '5px'
    }
}));

const FormularioTriagemAutoCadastro = (props) => {
    const {
        id,
        strings,
        setParametrosAlerta,
        setOpen,
        setIdSolicitacaoCadastro,
        setBuscar,
        dados,
        voltar,
        user
    } = props;

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const formRef = useRef();
    const schema = validationSchema(strings);
    const [carregado, setCarregado] = useState(false);

    //Rejeitar cadastro
    const [abrirModalRejeitar, setAbrirModalRejeitar] = useState(false);

    useEffect(() => {
        if (dados && id) {
            setInitialValues(Functions.inicializarValoresFormulario(dados));
            setCarregado(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados, id]);

    const Divider = memo((props) => {
        return (
            <SttDivider {...props} />
        )
    });

    const callbackRejeitarCadastro = () => {
        setAbrirModalRejeitar(false);
    }

    const callbackSucessoRejeitarCadastro = () => {
        setBuscar(true);
    }

    const redirectPesquisaSolicitacoes = () => {
        setIdSolicitacaoCadastro(null);
        setOpen(false);
        setBuscar(true);
    }

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting }) => {
                            let dadosFormatados = Functions.formatarSolicitacao(dados);

                            setSubmitting(true);

                            axios
                                .post(`${ADM_API_BASE_URL}/triagem`, dadosFormatados, { headers: getHeaders() })
                                .then((response) => {
                                    setSubmitting(false);
                                    const { data } = response;

                                    const parametrosAlerta = {
                                        titulo: strings.sucesso,
                                        mensagem: data.message,
                                        tipo: 'success',
                                        open: true,
                                        opcoes: [
                                            {
                                                title: strings.ok,
                                                onClick: redirectPesquisaSolicitacoes
                                            }
                                        ],
                                        onClose: redirectPesquisaSolicitacoes
                                    };

                                    setParametrosAlerta(parametrosAlerta);
                                })
                                .catch(err => {
                                    setSubmitting(false);

                                    const { response } = err;
                                    let mensagem = strings.mensagemErroGeral;
                                    if (response) {
                                        if (response.status === HttpStatus.BAD_REQUEST) {
                                            const { data } = response;
                                            let arrMensagem = [];
                                            data.errors.forEach(error => {
                                                arrMensagem.push(`- ${error.message}`);
                                            });
                                            if (arrMensagem.length > 0) {
                                                mensagem = arrMensagem.join('\n');
                                            }
                                        }
                                    }

                                    const parametrosAlerta = {
                                        titulo: strings.erro,
                                        mensagem: mensagem,
                                        tipo: 'error',
                                        open: true,
                                        opcoes: [
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    setOpen(false);
                                                }
                                            }
                                        ]
                                    };

                                    setParametrosAlerta(parametrosAlerta);
                                });
                        }}
                    >
                        {
                            ({
                                values,
                                isSubmitting,
                                handleSubmit
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    voltar();
                                                                    setOpen(false);
                                                                }

                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.triagemAutoCadastro}</SttHeading>

                                            <SttExpansionPanel
                                                title={strings.dadosGerais}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosGerais />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosContato}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosContato />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.dadosProfissionais}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosProfissionais />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.maisMedicosResidencia}
                                                classegriditem={classes.expansionPanel}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosParticipacao />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />

                                            <SttExpansionPanel
                                                title={strings.vinculos}
                                                classegriditem={classes.expansionPanel}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosVinculo />
                                                    </Suspense>
                                                }
                                            />

                                            <Divider />

                                            <div className={classes.buttonWrapper}>
                                                <>
                                                    {
                                                        UtilTriagem.verificarPodeAprovarTriagem(user, dados.vinculo) &&
                                                        <SttButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={isSubmitting}
                                                        >
                                                            {strings.aprovar}
                                                        </SttButton>
                                                    }

                                                    <SttButton variant="outlined"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setAbrirModalRejeitar(true);
                                                        }}>
                                                        {strings.rejeitar}
                                                    </SttButton>
                                                </>
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                        <ModalRejeitarSolicitacao
                                            abrirModalRejeitar={abrirModalRejeitar}
                                            id={values[DADOS_GERAIS][ID]}
                                            callback={callbackRejeitarCadastro}
                                            setIdSolicitacaoCadastro={setIdSolicitacaoCadastro}
                                            callbackSucessoRejeitarCadastro={callbackSucessoRejeitarCadastro}
                                        />
                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> : <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
            }
        </>
    )
}

FormularioTriagemAutoCadastro.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        funcionario: state.index.funcionario
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('FormularioTriagemAutoCadastro')(FormularioTriagemAutoCadastro));