import React, { useState } from "react";
import {
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttButton
} from '@stt-componentes/core';
import translate from "@componentes/translate";

const ConfirmacaoMensagem = ({ classes, strings, setSituacao }) => {
    const [entendi, setEntendi] = useState(false);

    const handleCheckChange = (event) => {
        setEntendi(c => !c);
    };

    return (
        <SttFormGroup row className={classes.form}>
            <SttFormControlLabel
                control={
                    <SttCheckbox
                        onChange={handleCheckChange}
                        value={entendi}
                        color="primary"
                    />
                }
                label={strings.okEntendi}
            />
            <SttButton variant="contained"
                color="primary"
                nomarginleft="true"
                className={classes.buttons}
                disabled={!entendi}
                onClick={setSituacao}>
                {strings.confirmar}
            </SttButton>
        </SttFormGroup>
    );
}

export default translate('SolicitacaoConfirmacaoMensagem')(ConfirmacaoMensagem);